import { Component } from 'react';
import { connect } from 'react-redux';

class ChallengesComponent extends Component {

    render() {
        return (
            <div>
                <p>{this.props.authenticatedUser.name} {this.props.authenticatedUser.surname} create and start interactive online challenges formerly named Team Challenges here.</p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(ChallengesComponent);