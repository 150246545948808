import { createStore } from 'redux';
import { mainpages } from '../common/common';

const initialState = {
    authenticatedUser: null,
    showToastNotificationCallback: (type, message) => {},
    mainPageCode: mainpages.dashboard.code,
    isSidenavOpened: true,
    all_grades_with_units: [],
    all_resource_available_for: []
};

const teamLmsReducer = (state = initialState, action) => {

    if (action.type === 'storeAuthenticatedUser') {
        return { ...state,
            authenticatedUser: action.authenticatedUser
        }
    }

    if (action.type === 'setMainPageCode') {
        return { ...state,
            mainPageCode: action.mainPageCode
        }
    }
    
    if (action.type === 'setIsSidenavOpened') {
        return { ...state,
            isSidenavOpened: action.isSidenavOpened
        }
    }

    if (action.type === 'logout') {
        return { ...state,
            authenticatedUser: null
        }
    }

    if (action.type === 'storeShowToastNotificationCallback') {
        return { ...state,
            showToastNotificationCallback: action.showToastNotificationCallback
        }
    }

    if (action.type === 'storeAllResourceAvailableFor') {
        return { ...state,
            all_resource_available_for: action.all_resource_available_for
        }
    }
    
    if (action.type === 'storeAllGradesWithUnits') {
        return { ...state,
            all_grades_with_units: action.all_grades_with_units
        }
    }


    return state;
};


const store = createStore(teamLmsReducer);

export default store;
