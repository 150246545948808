import { Component } from 'react';
import { connect } from 'react-redux';

class AdminDashboardComponent extends Component {

    render() {
        return (
            <div>
                <h3>Gösterge Paneli</h3>
                <p>
                    Merhaba {this.props.authenticatedUser.name} {this.props.authenticatedUser.surname},
                </p>
                <p>
                    TEAM LMS platformunun yönetimini buradan yapabilirsiniz.
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(AdminDashboardComponent);