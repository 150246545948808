import { Component } from 'react';
import { connect } from 'react-redux';

class TeacherDashboardComponent extends Component {

    render() {
        return (
            <div>
                <h3>TEAM LMS Platformuna Hoşgeldiniz!</h3>
                <p>
                    Merhaba {this.props.authenticatedUser.name} {this.props.authenticatedUser.surname},
                </p>
                <p>
                    TEAM LMS platformunda yüz yüze ve uzaktan eğitimde ihtiyacınız olabilecek tüm öğretmen ders kaynakları bulunmaktadır.
                </p>
                <ul>
                    <li>
                        İndirilebilir ders kaynaklarına soldaki menüde yer alan <strong>İndirme</strong> menüsü altından ulaşabilirsiniz.
                    </li>
                    <li>
                        Çevrimiçi olarak herhangi bir aygıttan (akıllı tahta, bilgisayar, tablet) çalışabilen i-TEAM uygulamamıza soldaki <strong>i-TEAM Online</strong> menü öğesinden ulaşabilirsiniz.
                    i-TEAM uygulamasını çevrimdışı kullanmayı tercih eden öğretmenlerimiz soldaki <strong>İndirme</strong> menüsü altından kullandıkları platforma uygun
                    sürümü indirebilirler.
                    </li>
                    <li><strong>Sınav Üreteci</strong> ile dilediğiniz sınıftan ve üniteden sınav üretip PDF dosyası olarak indirebilir ve sınıfınıza sınav olarak uygulayabilirsiniz.</li>
                </ul>
                <h5>Yakın gelecekte başka neler olacak?</h5>
                <ul>
                    <li>
                        Dersleri daha eğlenceli hale getirmek için hem çevrimiçi hem çevrimdışı çalışan yepyeni oyunlar geliştirdik. Son kontrolleri biter bitmez sizinle TEAM LMS platformu üzerinden paylaşacağız.
                    </li>
                    <li>
                        Yakın gelecekte öğrencileriniz de sisteme dahil edilecek ve aşağıdaki özellikler sırayla kullanıma açılacak.
                        <ul>
                            <li>Sanal sınıfların oluşturulması ve öğrencilerin bu sınıflara dahil edilmesi</li>
                            <li>Öğrencilerle indirilebilir dosya paylaşımı</li>
                            <li>Çevrimiçi ödev iletimi ile öğrencilerle etkileşimli etkinlik paylaşımı</li>
                            <li>Çevrimiçi canlı sınavlar</li>
                            <li>Çevrimiçi canlı yarışma ve mücadeleler</li>
                            <li>Öğrencilerin kazandıkları puan ve ilerleme durumu izleme ekranları</li>
                            <li>Çevrimiçi denemeler</li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(TeacherDashboardComponent);