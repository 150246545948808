import { Component } from 'react';
import { MDBAlert } from 'mdb-react-ui-kit';


class AlertComponent extends Component {

    render() {
        let type = 'danger';
        if (this.props.type !== undefined) {
            type = this.props.type;
        }

        let alert_messages = [];
        if (this.props.alert_messages !== undefined && Array.isArray(this.props.alert_messages)) {
            alert_messages = this.props.alert_messages;
        } else if (this.props.alert_messages !== undefined) {
            alert_messages.push(this.props.alert_messages);
        }

        return (      
                <MDBAlert open color={type} className='w-100' >
                    <h6>{this.props.alert_header}</h6>
                    {alert_messages.map((msg, index) => {
                        return <p key={index} className='lh-sm fw-light'>{msg}</p>;
                    })}
                </MDBAlert>
            );
    }
}

export default AlertComponent;