import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MDBBtn, 
    MDBCol, 
    MDBInput, 
    MDBRow, 
    MDBCard, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBCardHeader,
    MDBValidation,
    MDBSelect,
    MDBCardTitle,
    MDBValidationItem
} from 'mdb-react-ui-kit';

import Moment from 'moment';
import DOMPurify from 'dompurify';

import ModalInformationBoxComponent from '../ModalInformationBoxComponent';
import User from '../../apis/User';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import AlertComponent from '../AlertComponent';
import Province from '../../apis/Province';
import { cloneConfigForFormControl, formatPhoneNumber } from '../../common/common';
import axios from 'axios';


class EditProfileComponent extends Component {
    constructor(props) {
        super(props);



        let province_id = this.props.authenticatedUser.province_id || -1;
        let town_id = this.props.authenticatedUser.town_id || -1;

        this.state = {  isGDPRDisclaimerModalShown: false,
                        isSpinnerWaitShown: false,
                        gdprDisclaimerHTML: '',
                        provinces: [{ 
                            value: '-1',
                            text: 'İl Seçiniz', 
                            defaultSelected: false
                         }],
                        towns: [{ 
                            value: '-1',
                            text: 'İlçe Seçiniz', 
                            defaultSelected: false
                         }],
                        form_controls: {
                            username: { value: this.props.authenticatedUser.username, is_invalid: false, message: '', instruction: ''},
                            password: { value: '', is_invalid: false, message: '', instruction: ''},
                            name: { value: this.props.authenticatedUser.name, is_invalid: false, message: '', instruction: ''},
                            surname: { value: this.props.authenticatedUser.surname, is_invalid: false, message: '', instruction: ''},
                            school_name: { value: this.props.authenticatedUser.school_name || '', is_invalid: false, message: '', instruction: ''},
                            province_id: { value: province_id, is_invalid: false, message: '', instruction: ''},
                            town_id: { value: town_id, is_invalid: false, message: '', instruction: ''}
                        },
                        submit_success: false,
                        submit_error: false,
                        alert_header: '',
                        alert_messages: []
                    };

                    

        this.gdprDisclaimerLinkClickHandler = this.gdprDisclaimerLinkClickHandler.bind(this);
        this.gdprDisclaimerModalToggleHandler = this.gdprDisclaimerModalToggleHandler.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onSaveButtonClickHandler = this.onSaveButtonClickHandler.bind(this);
        this.onProvinceComboboxSelectHandler = this.onProvinceComboboxSelectHandler.bind(this);
        this.onTownComboboxSelectHandler = this.onTownComboboxSelectHandler.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    }


    componentDidMount() {
        
        this.fetchProvinces();
        if (this.props.authenticatedUser.province_id !== null) {
            this.fetchTowns(this.props.authenticatedUser.province_id);
        }
    }

    gdprDisclaimerLinkClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.gdprDisclaimerHTML.length === 0) {
            axios.get('resources/texts/gdpr_disclaimer.html')
            .then(response => {
                this.setState({ gdprDisclaimerHTML: DOMPurify.sanitize(response.data, {
                    ALLOWED_TAGS: ["h1", "h2", "h3", "h4", "h5", "p", "span", "b", "strong", "a"],
                    ALLOWED_ATTR: ["style"],
                }) });
            });    
        }
        this.setState({ isGDPRDisclaimerModalShown: true });
    }

    gdprDisclaimerModalToggleHandler() {
        if (!this.state.isGDPRDisclaimerModalShown) {
            return;
        }

        this.setState({ isGDPRDisclaimerModalShown: false });
    }


    async fetchProvinces() {
        if (this.state.provinces.length > 1) {
            return;
        }

        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Province.fetchAll();
            this.setState({ isSpinnerWaitShown: false });
            let provinces = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let selected = this.props.authenticatedUser.province_id === item.id;
                let option = { 
                               value: strId,
                               text: item.province_name, 
                               defaultSelected: selected
                            };
                provinces.push(option);
            });
            this.setState( { provinces: provinces } );
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response !== undefined && error.response.status === 401) {
                this.props.storeAuthenticatedUser(null); 
                return;
            }
        }

        
    }

    onProvinceComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }
        let selectedItemNumeric;
        let form_control = { ...this.state.form_controls.province_id };
        selectedItemNumeric = parseInt(selectedItem.value);
        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.province_id = form_control;
        this.setState({ form_controls: form_controls });

        this.fetchTowns(selectedItemNumeric);
        
    }

    async fetchTowns(province_id) {

        try {
            this.setState({ isSpinnerWaitShown: true });
            let request = { id: province_id };
            let response = await Province.fetchTownByProvinceId(request);
            let towns = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let selected = this.props.authenticatedUser.town_id === item.id ? true : false;
                let option = { text: item.town_name, 
                               value: strId,
                               defaultSelected: selected
                             };
                towns.push(option);
            });
            this.setState( { towns: towns } );
            this.setState({ isSpinnerWaitShown: false });
        } catch(error) {
            if (error.response !== undefined && error.response.status === 401) {
                this.setState({ isSpinnerWaitShown: false });
                this.props.storeAuthenticatedUser(null); 
                return;
            }
        }
    }

    onTownComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let selectedItemNumeric;
        let form_control = { ...this.state.form_controls.town_id };
        selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.town_id = form_control;
        this.setState({ form_controls: form_controls });
    }
    /*
     * formItemOnChangeHandler - responsible to copy up to date changed values of form items to component state
     */
    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;
        
        let formControlCopy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference

        formControlCopy.value = value;
        let formControlsCopy = { ...this.state.form_controls};
        formControlsCopy[key] = formControlCopy;
        this.setState({ form_controls: formControlsCopy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }

   

    onSaveButtonClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        this.submitProfileInfo();
    }


    async submitProfileInfo() {

        this.setState({ submit_success: false });
        this.setState({ submit_error: false });
        this.setState({ alert_header: '' });
        this.setState({ alert_messages: '' });
        this.setState({ isSpinnerWaitShown: true });
        

        let form_control_names = Object.keys(this.state.form_controls);

        let form_items = {};
        form_control_names.forEach(key => {
            form_items[key] = this.state.form_controls[key].value;
        });


        if (form_items['province_id'] === -1) {
            form_items['province_id'] = null;
        }

        if (form_items['town_id'] === -1) {
            form_items['town_id'] = null;
        }

        try {
            let response = await User.updateUserProfile(form_items);

            let form_controls_copy = { ...this.state.form_controls };

            form_control_names.forEach(key => {
                form_controls_copy[key] = cloneConfigForFormControl(key, [], form_controls_copy);
            });

            this.props.showToastNotification('success', response.data.message);
            this.setState({ alert_header: 'Profil bilgileriniz kaydedildi' });
            this.setState({ form_controls: form_controls_copy });
            this.setState({ submit_success: true });
            this.setState({ submit_error: false });
            this.setState({ isSpinnerWaitShown: false });
            this.props.storeAuthenticatedUser(response.data.user);
        } catch (error) {
            console.log('axios thrown an error while submitting user profile information');
            console.log(error);
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_controls_copy['name'] = cloneConfigForFormControl('name', errors, form_controls_copy);
                form_controls_copy['surname'] = cloneConfigForFormControl('surname', errors, form_controls_copy);
                form_controls_copy['password'] = cloneConfigForFormControl('password', errors, form_controls_copy);
                form_controls_copy['school_name'] = cloneConfigForFormControl('school_name', errors, form_controls_copy);
                form_controls_copy['province_id'] = cloneConfigForFormControl('province_id', errors, form_controls_copy);
                form_controls_copy['town_id'] = cloneConfigForFormControl('town_id', errors, form_controls_copy);
                this.setState({ form_controls: form_controls_copy });

                if (errors.error !== undefined) { // handle global algoritmical errors
                    this.setState({ alert_header: error.response.data.message });
                    this.setState({ alert_messages: errors.error });
                    this.setState({ submit_success: false });
                    this.setState({ submit_error: true });
                }
                this.setState({ isSpinnerWaitShown: false });
            } else {
                // handle catastrophic errors
                this.setState({ alert_header: 'Beklenmedik Hata Oluştu' });
                this.setState({ alert_messages: error.toString() });
                this.setState({ submit_success: false });
                this.setState({ submit_error: true });
                this.setState({ isSpinnerWaitShown: false });
            }
        }


    };

    formatPhoneNumber(number) {
        return formatPhoneNumber(number);
    }

    render() {


        let modalGDPRDisclaimer = (
            <ModalInformationBoxComponent
                title='KVKK Aydınlatma Metni'
                showModal={this.state.isGDPRDisclaimerModalShown}
                toggleHandler={this.gdprDisclaimerModalToggleHandler} >
                    <div dangerouslySetInnerHTML={{__html: this.state.gdprDisclaimerHTML}}></div>
            </ModalInformationBoxComponent>
        );


        let errorMessageComponent = '';
        if ((this.state.submit_success || this.state.submit_error) && this.state.alert_header !== '') {
            errorMessageComponent = (<AlertComponent 
                                        type={this.state.submit_success ? 'warning' : 'danger'}
                                        open={true}
                                        alert_header={this.state.alert_header} 
                                        alert_messages={this.state.alert_messages} />
                                    );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let dateTimeStr = Moment(this.props.authenticatedUser.gdpr_accepted_at).format('YYYY-MM-DD HH:mm');

        return (
            <div className='col-12 col-lg-11 col-xl-10 col-xxl-9'>
                <MDBCard>
                    <MDBValidation noValidate>
                    <MDBCardHeader>
                        <MDBCardTitle>Profili Güncelle</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                        {errorMessageComponent}
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput type='text' id='email' label='E-posta adresiniz' autoComplete='off'  
                                    readOnly 
                                    value={this.props.authenticatedUser.email}
                                />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput type='text' id='mobile_phone' label='Mobil telefon numaranız' autoComplete='off'  
                                    readOnly
                                    value={this.formatPhoneNumber(this.props.authenticatedUser.mobile_phone)}
                                    />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBValidationItem feedback={this.state.form_controls.username.message} invalid>
                                    <MDBInput type='text' id='username' label='Kullanıcı adınız' autoComplete='off' required 
                                        readOnly
                                        value={this.state.form_controls.username.value} 
                                        className={this.state.form_controls.username.is_invalid ? 'is-invalid':''}                                        
                                    />
                                </MDBValidationItem>
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBValidationItem feedback={this.state.form_controls.password.message} invalid>
                                    <MDBInput type='password' id='password' label='Şifrenizi doğrulayınız' autoComplete='off' required 
                                        onChange={this.formItemOnChangeHandler}
                                        className={this.state.form_controls.password.is_invalid ? 'is-invalid':''}                                        
                                    />
                                </MDBValidationItem>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBValidationItem feedback={this.state.form_controls.name.message} invalid>
                                    <MDBInput type='text' id='name' label='Adınız' autoComplete='off' required 
                                        onChange={this.formItemOnChangeHandler}
                                        value={this.state.form_controls.name.value} 
                                        className={this.state.form_controls.name.is_invalid ? 'is-invalid':''} 
                                    />
                                </MDBValidationItem>
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBValidationItem feedback={this.state.form_controls.surname.message} invalid>
                                    <MDBInput type='text' id='surname' label='Soyadınız' autoComplete='off' required 
                                        onChange={this.formItemOnChangeHandler}
                                        value={this.state.form_controls.surname.value} 
                                        className={this.state.form_controls.surname.is_invalid ? 'is-invalid':''} 
                                    />
                                </MDBValidationItem>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBValidationItem feedback={this.state.form_controls.school_name.message} invalid>
                                    <MDBInput type='text' id='school_name' label='Okul adı' autoComplete='off' required 
                                        onChange={this.formItemOnChangeHandler} 
                                        value={this.state.form_controls.school_name.value} 
                                        className={this.state.form_controls.school_name.is_invalid ? 'is-invalid':''} 
                                    />
                                </MDBValidationItem>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3 form-outline'>
                                <MDBSelect
                                        id='province_id'
                                        label='İl'
                                        search
                                        searchLabel='İl ara'
                                        onChange={this.onProvinceComboboxSelectHandler}
                                        data={this.state.provinces}
                                />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBSelect
                                        id='town_id'
                                        label='İlçe'
                                        search
                                        searchLabel='İlçe ara'
                                        onChange={this.onTownComboboxSelectHandler}
                                        data={this.state.towns}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBBtn color='link' onClick={this.gdprDisclaimerLinkClickHandler}>KVKK AYDINLATMA METNİNİ OKU</MDBBtn>
                                <div>{dateTimeStr} anında kabul edildi</div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBBtn color='primary' className='me-3' onClick={this.onSaveButtonClickHandler}>PROFİL BİLGİLERİMİ KAYDET</MDBBtn>
                    </MDBCardFooter>
                    </MDBValidation>
                </MDBCard>
                {modalGDPRDisclaimer}
                {spinnerWaitComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAuthenticatedUser: (authenticatedUser) => dispatch({ type: 'storeAuthenticatedUser', authenticatedUser: authenticatedUser })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileComponent);
