import { Component } from 'react';
import { connect } from 'react-redux';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

class QuizMakerPreviewQuizColumnComponent extends Component {

    render() {

        let rightBorder = '';
        if (this.props.rightBorder !== undefined) {
            rightBorder = 'border-end border-dark';
        }

        return (
            <div className={`col-6 p-4 ${rightBorder} align-items-center`}>
                {this.props.column.map((resource, index) => {
                    return (
                    <div key={index} className='row pb-5'>
                        <div className='col-auto'>
                            <Zoom zoomMargin={20}>
                                <img src={`${window.config.downloadserver}/${resource.uuid}/${resource.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: '150px', height: 'auto'}}/>
                            </Zoom>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-* small'>
                                    <strong>{resource.question_order}. SORU</strong>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-* small'>
                                    {resource.grade.name}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-*'>
                                    {resource.units.map((unit, key) => {
                                        return (<div key={key} className='block small'>{unit.name} - {unit.title}</div>);
                                    })}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-* small'>
                                    CEVAP: {resource.answers}
                                </div>
                            </div>
                        </div>
                    </div>);
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(QuizMakerPreviewQuizColumnComponent);