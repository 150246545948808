import { MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDatatable, MDBIcon, MDBModalTitle, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import Grade from '../../../apis/Grade';
import Resource from '../../../apis/Resource';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { mainpages } from '../../../common/common';
import ResourceLinkEditorComponent from './ResourceLinkEditorComponent';
import ResourceLinkViewerComponent from './ResourceLinkViewerComponent';

class ResourcesLinksComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmDeleteShown: false,
            deleteCandidateResource: null,
            isResourceEditorComponentTriggered: false,
            isResourceViewerComponentTriggered: false,
            resourceBeingEdited: null,
            resourceBeingViewed: null,
            rows: [],
            published_value: '',
            available_for_value: '',
            units_value: []
        };

        this.all_grades_with_units = [];
        this.all_resource_available_for = [];

        this.columns = [
            {
                label: 'Sınıf',
                field: 'grade',
                sort: true,
                searchable: true,
            },
            {
                label: 'Üniteler',
                field: 'units',
                sort: true,
                searchable: true,
            },
            {
                label: 'Ön İzleme',
                field: 'preview',
                sort: false,
                searchable: false,
            },            
            {
                label: 'Kaynak Adı',
                field: 'name',
                sort: true,
                searchable: true,
            },
            {
                label: 'Güncelleme Zamanı',
                field: 'updated_at',
                sort: true,
                searchable: true,
            },
            {
                label: 'Yayın',
                field: 'published',
                sort: true,
                searchable: true,
            },
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false,
                width: 230
            }
        ];

        this.addNewResourceButtonClickHandler = this.addNewResourceButtonClickHandler.bind(this);
        this.resourceEditorComponentCloseCallback = this.resourceEditorComponentCloseCallback.bind(this);
        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);
        this.onDeleteButtonClickHandler = this.onDeleteButtonClickHandler.bind(this);
        this.editResourceButtonClickHandler = this.editResourceButtonClickHandler.bind(this);

        this.resourceViewerComponentCloseCallback = this.resourceViewerComponentCloseCallback.bind(this);

    }

    componentDidMount() {
        this.fetchGradesWithUnits();
        this.fetchResourceAvailableFors();
        this.fetchAllResources();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.menucode !== this.props.menucode) {
            this.fetchAllResources();
            this.setState({ isResourceEditorComponentTriggered: false });
            this.setState({ isResourceViewerComponentTriggered: false });
            this.setState({ resourceBeingEdited: null });
            this.setState({ resourceBeingViewed: null });
        }
    }

    async fetchResourceAvailableFors() {
        if (this.props.all_resource_available_for.length > 0) {
            this.all_resource_available_for = this.props.all_resource_available_for;
            return;
        }
        if (this.all_resource_available_for.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Resource.fetchAllResourcesFor();
            this.all_resource_available_for = response.data;
            this.props.storeAllResourceAvailableFor(this.all_resource_available_for);
            this.loadFilterComboBoxes();
        } catch (error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    async fetchGradesWithUnits() {
        if (this.props.all_grades_with_units.length > 0) {
            this.all_grades_with_units = this.props.all_grades_with_units;
            return;
        }
        if (this.all_grades_with_units.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Grade.fetchGradesWithUnits();
            this.all_grades_with_units = response.data;
            this.props.storeAllGradesWithUnits(this.all_grades_with_units);
        } catch (error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }


    async fetchAllResources(filter) {

        let mainpageitem = mainpages[this.props.menucode];

        let type = mainpageitem.type;
        let subtype = mainpageitem.subtype;

        let filterLocal = {};
        if (filter === undefined) {
            filterLocal = { type: type, subtype: subtype };
        } else {
            filterLocal = { ...filter, type: type, subtype: subtype };
        }


        this.setState({ isSpinnerWaitShown: true });
        try {



            let response = await Resource.fetchAllResources(filterLocal);
            let rows = [];
            response.data.forEach(resource => {
                
                let item = {
                    grade: resource.grade.name,
                    units: (resource.units.map((unit, index) => {
                                                return <div key={index}>{unit.name}</div>;
                                            })),
                    preview: ( <div>
                        <Zoom zoomMargin={20}>
                            <img src={`${window.config.downloadserver}/${resource.uuid}/${resource.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: 'auto', height: '30px'}}/>
                        </Zoom>
                    </div>),
                    name: resource.name,
                    updated_at: Moment(resource.updated_at).format('YYYY-MM-DD HH:mm'),
                    published: resource.published ? 'Yayında' : 'Y. Değil',
                    actions: (<>
                                <MDBBtn outline rounded color='primary' size='sm' className='px-2 mx-1' onClick={() => this.viewResourceButtonClickHandler(resource)}><MDBIcon fas icon='eye' /><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                                <MDBBtn outline rounded color='success' size='sm' className='px-2 mx-1' onClick={() => this.editResourceButtonClickHandler(resource)}><MDBIcon fas icon='pen' /><span className='d-none d-lg-inline ms-1'>DÜZENLE</span></MDBBtn>
                                <MDBBtn outline rounded color='danger' size='sm' className='px-2 mx-1' onClick={() => this.onDeleteButtonClickHandler(resource)}><MDBIcon fas icon='trash' /><span className='d-none d-lg-inline ms-1'>SİL</span></MDBBtn>
                            </>)
                };
                rows.push(item);
            });
            this.setState({ rows: rows });
        } catch(error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    editResourceButtonClickHandler(resource) {
        this.setState({ isResourceViewerComponentTriggered: false });
        this.setState({ resourceBeingEdited: resource });
        this.setState({ isResourceEditorComponentTriggered: true });
    }

    resourceEditorComponentCloseCallback() {
        this.setState({ isResourceEditorComponentTriggered: false });
        this.setState({ resourceBeingEdited: null });
        this.fetchAllResources();
    }

    viewResourceButtonClickHandler(resource) {
        this.setState({ resourceBeingViewed: resource });
        this.setState({ isResourceViewerComponentTriggered: true });
    }

    resourceViewerComponentCloseCallback() {
        this.setState({ isResourceViewerComponentTriggered: false });
        this.setState({ resourceBeingViewed: null });
        this.fetchAllResources(); // may be deleted? Need refresh
    }

    addNewResourceButtonClickHandler() {
        this.setState({ isResourceEditorComponentTriggered: true });
    }


    async deleteResource() {
        if (this.state.deleteCandidateResource === null) {
            return;
        }

        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = { id: this.state.deleteCandidateResource.id };
            await Resource.deleteResource(request);
            this.props.showToastNotification('success', 'Kaynak silindi');
            this.setState({ confirmDeleteShown: false });
            this.setState({ deleteCandidateResource: null });
            this.fetchAllResources();
        } catch(error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    onDeleteButtonClickHandler(item) {
        this.setState({ deleteCandidateResource: item });
        this.setState({ confirmDeleteShown: true });
    }

    onDeleteConfirmationButtonClickHandler() {
        this.deleteResource();
    }


    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }



    render() {


        let unitnamesstr = '';
        let resource_name = '';
        let grade_name = '';
        if (this.state.confirmDeleteShown) {
            let units = this.state.deleteCandidateResource.units;
            let unitnamesarr = [];
            units.forEach(unit => {
                unitnamesarr.push(`${unit.name} - ${unit.title}`);
            });
            unitnamesstr = unitnamesarr.join(', ');
            resource_name = this.state.deleteCandidateResource.name;
            grade_name = this.state.deleteCandidateResource.grade.name;
        }

        let confirmDeleteComponent = (
        <MDBModal size='md' open={this.state.confirmDeleteShown} staticBackdrop>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Harici Link Silme Onayı</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p><strong>Silinecek kaynak adı:</strong> {resource_name}</p>
                        <p><strong>Kademesi:</strong> {grade_name}</p>
                        <p><strong>Üniteleri:</strong> {unitnamesstr}</p>
                        <p>Silme işlemini onaylıyor musunuz?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>);


        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.isResourceEditorComponentTriggered) {
            return (<ResourceLinkEditorComponent menucode={this.props.menucode} closeCallback={this.resourceEditorComponentCloseCallback} resourceBeingEdited={this.state.resourceBeingEdited} />);
        }

        if (this.state.isResourceViewerComponentTriggered) {
            return (<ResourceLinkViewerComponent menucode={this.props.menucode} closeCallback={this.resourceViewerComponentCloseCallback} editorCallBack={this.editResourceButtonClickHandler} resourceBeingViewed={this.state.resourceBeingViewed} />);
        }

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title + ' Harici Linkler';

        return (
            <div>
                <h3>{title}</h3>
                <p>Öğretmen kaynaklarını ekleme, düzenleme, silme işlemlerini bu bölümde ele alabilirsiniz.</p>
                
                <MDBBtn color='primary' onClick={this.addNewResourceButtonClickHandler}>YENİ KAYNAK EKLE</MDBBtn>

                <div className='mt-4 p-4 border rounded'>
                    <MDBDatatable 
                            search
                            sm
                            hover
                            fullPagination
                            rowsText='Sayfadaki satır sayısı'
                            data={{columns: this.columns, rows: this.state.rows}} />
                </div>
                {spinnerWaitComponent}
                {confirmDeleteComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAllResourceAvailableFor: (all_resource_available_for) => dispatch({ type: 'storeAllResourceAvailableFor', all_resource_available_for: all_resource_available_for }),
        storeAllGradesWithUnits: (all_grades_with_units) => dispatch({ type: 'storeAllGradesWithUnits', all_grades_with_units: all_grades_with_units })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesLinksComponent);