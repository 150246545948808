function parse2DErrorArrayTo1DArray(errors) {
    let errorList = [];

    let keys = Object.keys(errors);
    keys.forEach(key => {
        let messages = errors[key];
        messages.forEach(msg => {
            errorList.push(msg);
        });
    });

    return errorList;
}

export { parse2DErrorArrayTo1DArray };