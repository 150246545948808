import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBBtn, 
    MDBCol, 
    MDBInput, 
    MDBRow, 
    MDBCard, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBCardHeader,
    MDBValidation,
    MDBAlert,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import SpinnerWaitComponent from '../components/SpinnerWaitComponent';
import { cloneConfigForFormControl, sanitizeUserName } from '../common/common';
import User from '../apis/User';
import AlertComponent from '../components/AlertComponent';


class ForgotPasswordFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = { isForgotUsernameLinkClicked: false,
                       isReturnToLoginPageButtonClicked: false,
                       isSpinnerWaitShown: false,
                       isUsernameSent: false,
                       responseMessageHeader: '',
                       responseMessages: [],
                       form_controls: {
                            username: { value: '', is_invalid: false, message: '', instruction: ''}
                       }
                    };

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.forgotUsernameClickHandler = this.forgotUsernameClickHandler.bind(this);
        this.returnToLoginPageButtonClickHandler = this.returnToLoginPageButtonClickHandler.bind(this);
        this.onPasswordResetInstructionSendButtonClicked = this.onPasswordResetInstructionSendButtonClicked.bind(this);
    }

    forgotUsernameClickHandler(event) {
        this.setState({isForgotUsernameLinkClicked: true});
    }

    returnToLoginPageButtonClickHandler(event) {
        this.setState({isReturnToLoginPageButtonClicked: true});
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let form_controls_copy = { ...this.state.form_controls };        
        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        if (key === 'username') {
            value = sanitizeUserName(value);
        }
        
        form_control_copy.value = value;
        form_control_copy.is_invalid = false;
        form_control_copy.message = '';
        form_controls_copy[key] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
    }

    onPasswordResetInstructionSendButtonClicked(event) {
        event.stopPropagation();
        event.preventDefault();
        this.passwordResetInstructionsSend();
    }

    async passwordResetInstructionsSend() {
        this.setState({ responseMessageHeader : '' });
        this.setState({ responseMessages : [] });

        let form_control_names = Object.keys(this.state.form_controls);

            
        try {

            let form_controls_copy = { ...this.state.form_controls };

            form_control_names.forEach(key => {
                form_controls_copy[key] = cloneConfigForFormControl(key, [], form_controls_copy);
            });
            this.setState({ form_controls: form_controls_copy });

            let form_items = {
                username: this.state.form_controls.username.value
            };
            this.setState({ isSpinnerWaitShown: true });
            await User.reset_password_initiate_request(form_items);
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ isUsernameSent: true });
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.status === 500) {
                this.setState({ responseMessageHeader : 'Beklenmeyen Hata' });
                let messages = [];
                messages.push('Beklenmedik bir hata oluştu, daha sonra tekrar deneyiniz.');
                this.setState({ responseMessages : messages });
                this.setState({ isUsernameSent: false });
                return;    
            } else if (error.response.data.errors !== undefined) {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_control_names.forEach(key => {
                    form_controls_copy[key] = cloneConfigForFormControl(key, errors, form_controls_copy);
                });

                this.setState({ form_controls: form_controls_copy });
                this.setState({ submit_success: false });
                this.setState({ submit_error: false });
            }
        }
        
    }
    

    render() {
        if (this.props.authenticatedUser !== null) {
            return (<Navigate to='/' />);
        }

        if (this.state.isForgotUsernameLinkClicked) {
            return (<Navigate to='/forgotusername' />);
        }
        
        if (this.state.isReturnToLoginPageButtonClicked) {
            return (<Navigate to='/login' />);
        }

        let alertComponent = '';
        if (this.state.responseMessageHeader.length > 0) {
            alertComponent = (<div className='form-group'>
                <AlertComponent alert_messages={this.state.responseMessages}
                                alert_header={this.state.responseMessageHeader} />
                </div>
            );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.isUsernameSent) {
            return (
                <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                    <div className='col-12 col-sm-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5'>
                        <MDBCard>
                            <MDBCardHeader>
                                <h5>TEAM LMS - Yeniden Şifre Oluşturma Talimatı Gönderildi</h5>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBAlert open color='success' className='w-100'>
                                    <h6>Şifre Belirleme E-Postası Başarıyla Gönderildi</h6>
                                    <p className='lh-sm fw-light'>Belirtmiş olduğunuz <strong>{this.state.form_controls.username.value}</strong> kullanıcı adı LMS sistemimizde tanımlıysa e-posta adresinize bir şifre yeniden belirleme yönergesi ve linki gönderildi.</p>
                                </MDBAlert>
                                <MDBRow>
                                    <MDBCol>
                                        <p>E-posta hesabınıza erişip LMS sistemimiz tarafından iletilen yönergeyi takip ederek yeni şifrenizi belirleyiniz.</p>
                                        <p>5 dakika içinde e-posta gelmemiş olursa Gelen, İstenmeyen E-Postalar, Junk, Spam e-posta kutularını kontrol etmeyi unutmayınız.</p>
                                        <p>E-posta buna rağmen ulaşmamışsa muhtemelen LMS sistemimizde bulunmayan bir kullanıcı adı belirtmişsinizdir. Aşağıdaki <strong>KULLANICI ADIMI UNUTTUM</strong> linkine tıklayarak kullanıcı adınızı öğrenebilirsiniz.</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBBtn color='primary' onClick={this.returnToLoginPageButtonClickHandler}>OTURUM AÇMA EKRANINA GİT</MDBBtn>
                            </MDBCardFooter>
                        </MDBCard>
                        <div className='d-flex justify-content-center mt-3'>
                            <MDBBtn color='link' onClick={this.forgotUsernameClickHandler}>KULLANICI ADIMI UNUTTUM</MDBBtn>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                <div className='col-12 col-sm-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5'>
                    <MDBCard>
                        <MDBValidation noValidate>
                        <MDBCardHeader>
                            <h5>TEAM LMS - Yeniden Şifre Oluştur</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBAlert open color='warning' className='w-100'>
                                <p className='lh-sm fw-light'>Şifrenizi yeniden belirleme talimatlarını e-posta ile almak için sistemde kayıtlı <strong>kullanıcı adınızı</strong> giriniz.</p>
                            </MDBAlert>
                            {alertComponent}
                            <MDBRow>
                                <MDBCol>
                                    <MDBValidationItem feedback={this.state.form_controls.username.message} invalid>
                                        <MDBInput type='text' 
                                            id='username' label='Kullanıcı adı' autoComplete='off' 
                                            onChange={this.formItemOnChangeHandler} value={this.state.form_controls.username.value}
                                            className={this.state.form_controls.username.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn type='submit' color='primary' className='me-3' onClick={this.onPasswordResetInstructionSendButtonClicked}>ŞİFRE TALİMATI GÖNDER</MDBBtn>
                            <MDBBtn type='button' color='secondary' onClick={this.returnToLoginPageButtonClickHandler}>OTURUM AÇMA EKRANINA GİT</MDBBtn>
                        </MDBCardFooter>
                        </MDBValidation>
                        {spinnerWaitComponent}
                    </MDBCard>
                    <div className='d-flex justify-content-center mt-3'>
                        <MDBBtn color='link' onClick={this.forgotUsernameClickHandler}>KULLANICI ADIMI UNUTTUM</MDBBtn>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};


export default connect(mapStateToProps)(ForgotPasswordFormComponent);
