import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalTitle, MDBModalContent, MDBIcon, MDBDatatable, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBCol, MDBInput, MDBRow, MDBSelect, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import ExamMaker from '../../apis/ExamMaker';
import Attributes from '../../apis/Attributes';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import ExamMakerExercisesChangeComponent from './ExamMakerExercisesChangeComponent';
import Moment from 'moment';
import { USER_IS_ADMIN } from '../../common/common';

class ExamMakerGradeUnitsSelectComponent extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                label: 'Sınıf, Ünite, Kazanım, Bilişsel Düzey',
                field: 'class_unit_function',
                sort: false,
                searchable: true
            },
            {
                label: 'Soru Sayısı',
                field: 'number_of_exercises',
                sort: true,
                searchable: true
            },
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false,
            }
        ];


        this.state = {
            is_fetch_meb_functions_clicked: false,
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            is_grade_selected: false,
            selected_grade_id: null,
            is_unit_selected: false,
            selected_unit_id: null,
            is_meb_function_selected: false,
            selected_meb_function_id: null,
            exam_count_per_week: 1,
            min_exercise_count: 1,
            exammaker_max_questions_per_exam: 5,
            exammaker_max_questions_per_listeningexam: 2,
            current_exercise_counter: 0,
            grade_items: [],
            unit_items: [],
            meb_function_items: [],
            exam_items: [],
            exam_items_table_rows: [],
            delete_candidate: {},
            fetched_exercises: [],
            gotoPreviewExercises: false,
            quota_check_result_code: 'exam_create_allowed',
            quota_check_result_message: '',


            attributes: [],
            this_week_generated_exam_count: 0,
            form_controls: {
                grade_ids: { value: '', is_invalid: false, message: '', instruction: '' },
                unit_ids: { value: '', is_invalid: false, message: '', instruction: '' },
                meb_function_ids: { value: '', is_invalid: false, message: '', instruction: '' },
                unit_question_number: { value: '1', is_invalid: false, message: '', instruction: '' }
            },
            message: '',
            submit_success: false,
            submit_error: false
        };

        this.onGradesComboboxSelectHandler = this.onGradesComboboxSelectHandler.bind(this);
        this.onUnitsComboboxSelectHandler = this.onUnitsComboboxSelectHandler.bind(this);
        this.onMebFunctionsComboboxSelectHandler = this.onMebFunctionsComboboxSelectHandler.bind(this);
        this.onChangeQuestionNumbersHandler = this.onChangeQuestionNumbersHandler.bind(this);
        this.onClickAddMebFunctionToTheExam = this.onClickAddMebFunctionToTheExam.bind(this);
        this.onClickDeleteItemFromTheExam = this.onClickDeleteItemFromTheExam.bind(this);
        this.onClickDeleteConfirmationButton = this.onClickDeleteConfirmationButton.bind(this);
        this.fetchNewExercisesForExam = this.fetchNewExercisesForExam.bind(this);


        this.setGotoPreviewToFalse = this.setGotoPreviewToFalse.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);

        this.fetchUserAttributes = this.fetchUserAttributes.bind(this);
        this.fetchExamExercisesButtonClickHandler = this.fetchExamExercisesButtonClickHandler.bind(this);

        this.generateTableRowsFromRawList = this.generateTableRowsFromRawList.bind(this);
        this.checkExamCreationQuota = this.checkExamCreationQuota.bind(this);
        

    }

    componentDidMount() {
        this.loadGradesFromReduxStore();
        this.fetchUserAttributes();
    }

    async checkExamCreationQuota(selected_grade_id) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request_params = {
                grade_id: selected_grade_id,
                exam_type: this.props.examType
            };
            let response = await ExamMaker.checkExamCreationQuota(request_params);
            this.setState({ isSpinnerWaitShown: false });
            let result = response.data;
            this.setState({ quota_check_result_code: result.response_code });
            this.setState({ quota_check_result_message: result.message });
            if (result.response_code  === 'exam_create_allowed') {
                this.setState({ is_grade_selected: true });
                this.setState({ selected_grade_id: selected_grade_id });
            } else {
                this.setState({ is_grade_selected: false });
                this.setState({ selected_grade_id: -1 });
            }

        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ is_grade_selected: false });
            this.setState({ selected_grade_id: -1 });

            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Yazılı oluşturma kota biligisi sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Yazılı oluşturma kota biligisi sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }

    }


    async fetchUserAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_items = {
                user_id: this.props.authenticatedUser.id
            };
            let response = await Attributes.fetchAllUserAttributes(form_items);
            let attributes = response.data;
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ attributes: attributes });
            attributes.forEach(attribute => {
                if (attribute.code === 'exammaker_max_questions_per_exam') {
                    let value = parseInt(attribute.user_attribute_value, 10);
                    if (this.props.authenticatedUser.role_id === USER_IS_ADMIN) {
                        value = 100;
                    }
                    this.setState( { exammaker_max_questions_per_exam: value } );
                } else if (attribute.code === 'exammaker_max_questions_per_listeningexam') {
                        let value = parseInt(attribute.user_attribute_value, 10);
                        if (this.props.authenticatedUser.role_id === USER_IS_ADMIN) {
                            value = 100;
                        }
                        this.setState( { exammaker_max_questions_per_listeningexam: value } );
                } else if (attribute.code === 'exammaker_startdate') {
                    let value = attribute.user_attribute_value;
                    let isValidDate = Moment(attribute.user_attribute_value, "DD.MM.YYYY", true).isValid();
                    if (isValidDate) {
                        this.setState( { exammaker_startdate: value } );
                    } else {
                        this.setState( { exammaker_startdate: '01.01.2050' } );
                    }
                } else if (attribute.code === 'exammaker_enddate') {
                    let value = attribute.user_attribute_value;
                    let isValidDate = Moment(attribute.user_attribute_value, "DD.MM.YYYY", true).isValid();
                    if (isValidDate) {
                        this.setState( { exammaker_enddate: value } );
                    } else {
                        this.setState( { exammaker_enddate: '01.01.1970' } );
                    }
                } else if (attribute.code === 'exammaker_exam_count_per_class') {
                    let value = parseInt(attribute.user_attribute_value, 10);
                    this.setState( { exammaker_exam_count_per_class: value } );
                }
            });
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Kullanıcı Tercihleri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Kullanıcı Tercihleri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }

    }

    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }

    loadGradesFromReduxStore() {
        if (this.state.grade_items.length > 0) {
            return;
        }

        let grade_items = [];

        let option_grade = {
            text: 'SELECT A GRADE',
            disabled: true,
            value: '-1',
            defaultSelected: true
        };

        grade_items.push(option_grade);

        let checked_grade_ids = [];
        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            checked_grade_ids.push(this.props.resourceBeingEdited.grade.id);
            
        }

        let grades = this.props.all_grades_with_units;

        grades.forEach(grade => {

            let checked_grade = false;

            if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                checked_grade = checked_grade_ids.includes(grade.id);
            }   

            if (checked_grade) {
                this.setState({ selected_grade_id: grade.id });
            }

            option_grade = {
                text: grade.name,
                value: grade.id + '',
                defaultSelected: checked_grade
            };

            let regex = /^[5678]\. SINIF$/g;
            let name = grade.name;
            let found = name.match(regex);

            if (found) {
                grade_items.push(option_grade);
            }

        });

        this.setState({ grade_items: grade_items });
    }

    loadUnitsFromReduxStore(selected_grade_id) {
        let unit_items = [];

        let option_unit = {
            text: 'SELECT A UNIT',
            value: '-1',
            disabled: true,
            defaultSelected: true
        };

        unit_items.push(option_unit);


        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {

            if (selected_grade_id === null) {
                return;
            }

            if (grade.id !== selected_grade_id) {
                return;
            }

            let units = grade.units;
            units.forEach(unit => {

                option_unit = {
                    text: unit.name + ' - ' + unit.title,
                    value: unit.id + '',
                    disabled: false,
                    defaultSelected: false
                };

                unit_items.push(option_unit);
            });

        });

        this.setState({ unit_items: unit_items });
        
    }

    loadMebFunctionsFromReduxStore(selected_unit_id) {
        let meb_function_items = [];

        let option_meb_function = {
            text: 'SELECT A MEB FUNCTION',
            value: '-1',
            disabled: true,
            defaultSelected: true
        };

        meb_function_items.push(option_meb_function);

        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {

            if (!this.state.is_grade_selected) {
                return;
            }

            let units = grade.units;

            units.forEach(unit => {

                if (selected_unit_id === null) {
                    return;
                }

                if (unit.id !== selected_unit_id) {
                    return;
                }


                let meb_functions = unit.meb_functions;

                meb_functions.forEach(meb_function => {

                    if (!((this.props.examType === "downloadable_exam" && meb_function.type === 'reading & writing') 
                            ||
                        (this.props.examType === "downloadable_listening_exam" && meb_function.type === 'listening'))) {
                        return;
                    }

                    option_meb_function = {
                        text: meb_function.name,
                        value: meb_function.id + '',
                        disabled: false,
                        defaultSelected: false
                    };

                    meb_function_items.push(option_meb_function);
                });

            });

        });


        this.setState({ meb_function_items: meb_function_items });
    }


    async onGradesComboboxSelectHandler(selectedItem) {

        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.grade_ids };

        let selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.grade_ids = form_control;

        // reset the rest to defaults in the form controls
        form_controls.unit_ids.value = '';
        form_controls.meb_function_ids.value = '';


        // set new values
        this.setState({ form_controls: form_controls });
        this.setState({ selected_grade_id: selectedItemNumeric });

        
        // reset the rest to defaults
        this.setState({ is_unit_selected: false });
        this.setState({ selected_unit_id: null });
        this.setState({ unit_items: [] });

        this.setState({ is_meb_function_selected: false });
        this.setState({ selected_meb_function_id: null });
        this.setState({ meb_function_items: [] });

        await this.checkExamCreationQuota(selectedItemNumeric);

        this.loadUnitsFromReduxStore(selectedItemNumeric);

    }


    onUnitsComboboxSelectHandler(selectedItem) {


        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.unit_ids };

        let selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.unit_ids = form_control;

        // reset the rest to defaults in the form controls
        form_controls.meb_function_ids.value = '';

        // set new values
        this.setState({ form_controls: form_controls });
        this.setState({ is_unit_selected: true });
        this.setState({ selected_unit_id: selectedItemNumeric });
                
        // reset the rest to defaults
        this.setState({ is_meb_function_selected: false });
        this.setState({ selected_meb_function_id: null });
        this.setState({ meb_function_items: [] });
        

        this.loadMebFunctionsFromReduxStore(selectedItemNumeric);
    }

    onMebFunctionsComboboxSelectHandler(selectedItem) {

        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.meb_function_ids };

        let selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.meb_function_ids = form_control;

        // set new values
        this.setState({ form_controls: form_controls });
        this.setState({ is_meb_function_selected: true });
        this.setState({ selected_meb_function_id: selectedItemNumeric });




    }



    onChangeQuestionNumbersHandler(event) {

        let value = event.target.value;
        let regex = /[^0-9]/g;
        let replacement = '';
        let newValue = value.replace(regex, replacement).substring(0, 2);
        
        let form_control = { ...this.state.form_controls.unit_question_number };

        form_control.value = newValue;
        let form_controls = { ...this.state.form_controls };
        form_controls.unit_question_number = form_control;
        

        // set new values
        this.setState({ form_controls: form_controls });
    }


    onClickAddMebFunctionToTheExam() {
        let new_exam_item = {
            grade_id: +this.state.selected_grade_id,
            unit_id: +this.state.selected_unit_id,
            meb_function_id: +this.state.selected_meb_function_id,
            number_of_exercises: +this.state.form_controls.unit_question_number.value
        }


        let is_accumulated_to_the_previous = false;

        let exam_items = this.state.exam_items;
        exam_items.forEach(exam_item => {
            if (exam_item.grade_id === new_exam_item.grade_id && exam_item.unit_id === new_exam_item.unit_id && exam_item.meb_function_id === new_exam_item.meb_function_id) {
                exam_item.number_of_exercises += +new_exam_item.number_of_exercises;
                is_accumulated_to_the_previous = true;
            }
        });

        if (!is_accumulated_to_the_previous) {
            exam_items = [ ...this.state.exam_items ];
            exam_items.push(new_exam_item);
            this.setState({exam_items: exam_items});
        }



        // reset selected unit_id
        let form_control = { ...this.state.form_controls.unit_ids };
        form_control.value = '';
        let form_controls = { ...this.state.form_controls };
        form_controls.unit_ids = form_control;

        this.setState({ form_controls: form_controls });
        this.setState({ is_unit_selected: false });



        // reset the rest to defaults in the form controls
        form_controls.meb_function_ids.value = '';



        // reset all selections except grade
        let selectedGradeItem = {...this.state.form_controls.grade_ids}; 
        this.generateTableRowsFromRawList(exam_items);
        this.onGradesComboboxSelectHandler(selectedGradeItem);

        let current_exercise_counter = 0;
        exam_items.forEach(exam_item => {
            current_exercise_counter += exam_item.number_of_exercises;
        })

        this.setState({ current_exercise_counter: current_exercise_counter });
        this.setState({ is_fetch_meb_functions_clicked : false })
    }

    onClickDeleteItemFromTheExam(grade_id, unit_id, meb_function_id) {
        
        let delete_candidate = {
            grade_id: grade_id,
            grade_name: this.findGradeNameFromId(grade_id),
            unit_id: unit_id,
            unit_name: this.findUnitNameFromId(unit_id),
            meb_function_id: meb_function_id,
            meb_function_name: this.findMebFunctionNameFromId(meb_function_id),
        }

        this.setState({ delete_candidate: delete_candidate });
        this.toggleDeleteConfirmationModal();
    }

    onClickDeleteConfirmationButton() {
        let exam_items = [ ...this.state.exam_items ];

        exam_items = exam_items.filter((exam_item) => {
            return !(exam_item.grade_id === this.state.delete_candidate.grade_id 
                    && exam_item.unit_id === this.state.delete_candidate.unit_id 
                    && exam_item.meb_function_id === this.state.delete_candidate.meb_function_id);
        });

        this.setState({ exam_items: exam_items });
        this.generateTableRowsFromRawList(exam_items);
        
        let current_exercise_counter = 0;
        exam_items.forEach(exam_item => {
            current_exercise_counter += exam_item.number_of_exercises;
        })

        this.setState({ current_exercise_counter: current_exercise_counter });

        this.toggleDeleteConfirmationModal();
    }


    generateTableRowsFromRawList(exam_items) {

        let exam_items_table_rows = [];
        exam_items.forEach(exam_item => {
            let item = {
                class_unit_function: (<>
                <div className='text-primary'>
                    {this.findGradeNameFromId(exam_item.grade_id)} : {this.findUnitNameFromId(exam_item.unit_id)}
                </div>
                <div className='text-secondary'>
                    {this.findMebFunctionNameFromId(exam_item.meb_function_id)}
                </div>
                </>),
                number_of_exercises: exam_item.number_of_exercises,
                actions: (<div>
                    <MDBBtn outline rounded color='danger' size='sm' className='px-2 mx-1' onClick={() => this.onClickDeleteItemFromTheExam(exam_item.grade_id, exam_item.unit_id, exam_item.meb_function_id)}><MDBIcon fas icon='trash' /><span className='d-none d-lg-inline ms-1'>SİL</span></MDBBtn>
            </div>)
            }

            exam_items_table_rows.push(item);
        });

        this.setState({ exam_items_table_rows: exam_items_table_rows });
    }

    findGradeNameFromId(id) {
        let grade_name = '';
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {
            if (grade.id === id) {
                grade_name = grade.name;
            }
        });

        return grade_name;
    }


    findUnitNameFromId(id) {
        let unit_name = '';
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {
            grade.units.forEach(unit => {
                if (unit.id === id) {
                    unit_name = unit.name + " - " + unit.title;
                }
            })
        });

        return unit_name;
    }


    findMebFunctionNameFromId(id) {
        let meb_function_name = '';
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {
            grade.units.forEach(unit => {
                unit.meb_functions.forEach(meb_function => {
                    if (meb_function.id === id) {
                        meb_function_name = meb_function.name;
                    }
                });
            })
        });

        return meb_function_name;
    }

    cancelButtonClickHandler(event) {
        this.props.closeCallback();
    }

    async fetchNewExercisesForExam(request) {
        try {
            let response = await ExamMaker.fetchExamNewExercises(request)
            if (response.data.length !== undefined && response.data.length > 0) {
                this.setState({ fetched_exercises: response.data });
                this.setState({ gotoPreviewExercises: true });
            }
        } catch (error) {
            console.log(error);
        }
    }


    setGotoPreviewToFalse() {
        this.setState({ gotoPreviewExercises: false });


        let grade_items = [...this.state.grade_items];
        grade_items.forEach(item => {
            if (item.id !== this.state.selected_grade_id) {
                item.defaultSelected = false;
            } else {
                item.defaultSelected = true;
            }
        });

        this.setState({ grade_items: grade_items });

    }

    fetchExamExercisesButtonClickHandler(event) {
        let request = {
            exam_items: this.state.exam_items,
            exam_type: this.props.examType,
            grade_id: this.state.selected_grade_id
        };

        this.fetchNewExercisesForExam(request);
    }



    render() {

        if (this.state.fetched_exercises.length > 0 && this.state.gotoPreviewExercises) {
            return (<ExamMakerExercisesChangeComponent 
                        exercises={this.state.fetched_exercises} 
                        grade_id={this.state.selected_grade_id}
                        examType={this.props.examType}
                        gotoBackButtonClickCallback={this.setGotoPreviewToFalse} 
                        cancelButtonClickCallback={this.cancelButtonClickHandler}
                        reloadContentAndResetToInitial={this.props.reloadContentAndResetToInitial}
                        />);
        }




        let confirmDeleteComponent = (
            <MDBModal size='md' open={this.state.confirmDeleteShown} staticBackdrop>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Yazılı Sorusu Arama Kriteri Silme Onayı</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='text-primary'>
                                {this.state.delete_candidate.grade_name} : {this.state.delete_candidate.unit_name}
                            </div>
                            <div className='text-secondary'>
                                {this.state.delete_candidate.meb_function_name}
                            </div>
                            <p></p>
                            <p>Silme işlemini onaylıyor musunuz?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="danger" onClick={this.onClickDeleteConfirmationButton}>SİL</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>);
    



        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let get_new_exercises_btn_disabled = true;
        let current_exercise_count_color = 'text-danger';  
        
        if (this.props.examType === "downloadable_exam") {
            if (this.state.current_exercise_counter < this.state.min_exercise_count || this.state.current_exercise_counter > this.state.exammaker_max_questions_per_exam) {
                get_new_exercises_btn_disabled = true;
                current_exercise_count_color = 'text-danger';
            } else {
                get_new_exercises_btn_disabled = false;
                current_exercise_count_color = 'text-success';
            }
        } else if (this.props.examType === "downloadable_listening_exam") {
            if (this.state.current_exercise_counter < this.state.min_exercise_count || this.state.current_exercise_counter > this.state.exammaker_max_questions_per_listeningexam) {
                get_new_exercises_btn_disabled = true;
                current_exercise_count_color = 'text-danger';
            } else {
                get_new_exercises_btn_disabled = false;
                current_exercise_count_color = 'text-success';
            }
        }


        let title_half = "Yazılı Üreteci";
        if (this.props.examType === "downloadable_exam") {
            title_half = "Okuma Yazma Yazılısı Üreteci";
        } else if (this.props.examType === "downloadable_listening_exam") {
            title_half = "Dinleme Yazılısı Üreteci";
        }

        return (

            <>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>{title_half} - Sınıf ve Ünite Seçimi</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.grade_ids.message} invalid>
                                        <MDBSelect
                                            id='grade_ids'
                                            label='Sınıf Kademesi'
                                            search
                                            disabled={this.state.is_grade_selected}
                                            searchLabel='Sınıf Kademesi Ara'
                                            onChange={this.onGradesComboboxSelectHandler}
                                            data={this.state.grade_items}
                                            className={this.state.form_controls.grade_ids.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            {(this.state.quota_check_result_code !== 'exam_create_allowed') && <>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3 fs-6 fw-bolder'>
                                    {this.state.quota_check_result_message}
                                </MDBCol>
                            </MDBRow>
                            </>}
                            {(this.state.is_grade_selected && (this.state.quota_check_result_code === 'exam_create_allowed')) && <>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3 fs-6 fw-bolder'>
                                    Yazılıya Eklenecek Olan Kazanım
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.unit_ids.message} invalid>
                                        <MDBSelect
                                            id='unit_ids'
                                            label='Ünite'
                                            search
                                            searchLabel='Ünite Ara'
                                            onChange={this.onUnitsComboboxSelectHandler}
                                            data={this.state.unit_items}
                                            className={this.state.form_controls.unit_ids.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow></>}
                            {(this.state.is_unit_selected) &&
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.meb_function_ids.message} invalid>
                                        <MDBSelect
                                            id='meb_function_ids'
                                            label='Kazanımlar (MEB)'
                                            onChange={this.onMebFunctionsComboboxSelectHandler}
                                            data={this.state.meb_function_items}
                                            className={this.state.form_controls.meb_function_ids.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            }
                            {(this.state.is_meb_function_selected) && <>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.unit_question_number.message} invalid>
                                        <MDBInput 
                                            id='unit_question_number'
                                            label='Soru Sayısı' 
                                            onChange={(event) => this.onChangeQuestionNumbersHandler(event)} 
                                            value={this.state.form_controls.unit_question_number.value} 
                                            className={this.state.form_controls.unit_question_number.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3 text-center'>
                                    <MDBBtn color='primary' onClick={this.onClickAddMebFunctionToTheExam}>YAZILIYA YUKARIDAKİ KAZANIMI EKLE</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            </>}
                        </MDBCardBody>
                        {this.state.exam_items_table_rows.length > 0 && 
                        <MDBCardBody>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBDatatable 
                                        sm
                                        hover
                                        pagination={false}
                                        data={{columns: this.columns, rows: this.state.exam_items_table_rows}} />
                                </MDBCol>
                            </MDBRow>
                            <div className='form-outline row'>
                                {this.state.current_exercise_counter > 0 ? 
                                (<div className='col'>
                                    Seçtiğiniz soru sayısı: <strong className={current_exercise_count_color}>{this.state.current_exercise_counter}</strong>
                                    <div className="small secondary">
                                        En az {this.state.min_exercise_count} ve en çok <strong className='text-danger'>{this.props.examType === "downloadable_exam" ? this.state.exammaker_max_questions_per_exam : this.state.exammaker_max_questions_per_listeningexam}</strong> adet soru seçebilirsiniz.
                                    </div>
                                    <div className="small secondary">
                                        Bu hafta ilgili sınıftan <strong className='text-danger'>{this.state.quiz_count_per_week}</strong> yazılı üretme hakkınızdan <strong className='text-danger'>{this.state.this_week_generated_exam_count}</strong> adedini kullandınız.
                                    </div>
                                </div>) : ''}
                                {this.state.message !== '' ?
                                (<div className='col'><span className='text-danger'><strong>UYARI: </strong>{this.state.message}</span></div>) : ''}
                            </div>
                        </MDBCardBody>
                        }
                        <MDBCardFooter>
                            <MDBBtn color='primary' disabled={get_new_exercises_btn_disabled} className='me-2' onClick={this.fetchExamExercisesButtonClickHandler}>YAZILI SORULARINI GETİR - {this.state.current_exercise_counter}</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                    {confirmDeleteComponent}
                </MDBCard>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ExamMakerGradeUnitsSelectComponent);
