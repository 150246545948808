import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBValidationItem } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import User from '../../../apis/User';

class UserSetPasswordComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSpinnerWaitShown: false,
            submitButtonIsDisabled: true,
            form_controls: {
                new_password: { value: '', is_invalid: false, message: '', instruction: 'En az 8 karakter uzunluğunda'},
                new_password_confirmation: { value: '', is_invalid: false, message: '', instruction: 'Yeni şifre ile aynı'}
            },
        };

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.formSaveButtonClickHandler = this.formSaveButtonClickHandler.bind(this);
        this.saveUserPassword = this.saveUserPassword.bind(this);
    }

    formSaveButtonClickHandler() {
        this.saveUserPassword();
        this.props.toggleHandler();
    }


    async saveUserPassword() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_items = {
                user_id: this.props.user.id,
                new_password: this.state.form_controls.new_password.value,
                new_password_confirmation: this.state.form_controls.new_password_confirmation.value
            };
            await User.setPassword(form_items);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Kullanıcı şifresi başarıyla kaydedildi.');
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Kullanıcı şifresi kaydedilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Kullanıcı şifresi kaydedilemedi. Bilinmeyen hata!');
            }
        }
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        form_control_copy.value = value;
        let form_controls_copy = { ...this.state.form_controls};

        this.setState({ submitButtonIsDisabled: true });


        if (key === 'new_password_confirmation' && value !== this.state.form_controls['new_password'].value) {
            form_control_copy.is_invalid = true;
            form_control_copy.message = 'Yeni şifre tekrarı üstteki ile aynı olmalıdır';
            form_controls_copy[key] = form_control_copy;
        } else if (key === 'new_password' && value.length < 8) { 
            form_control_copy.is_invalid = true;
            form_control_copy.message = 'Yeni şifre en az 8 karakter uzunluğunda olmalıdır';
            form_controls_copy[key] = form_control_copy;
        } else if (key === 'new_password' && value.toLowerCase().includes(this.props.user.username.toLowerCase())) {
            form_control_copy.is_invalid = true;
            form_control_copy.message = 'Yeni şifre kullanıcı adını içeremez.';
            form_controls_copy[key] = form_control_copy;

        } else {
            form_control_copy.is_invalid = false;
            form_control_copy.message = '';
            form_controls_copy[key] = form_control_copy;
            this.setState({ submitButtonIsDisabled: false });
        }

        if (key === 'new_password' && value !== this.state.form_controls['new_password_confirmation'].value) {
            let form_control_copy2 =  { ...this.state.form_controls['new_password_confirmation'] }
            form_control_copy2.is_invalid = true;
            form_control_copy2.message = 'Yeni şifre tekrarı üstteki ile aynı olmalıdır';
            form_controls_copy['new_password_confirmation'] = form_control_copy2;
            this.setState({ submitButtonIsDisabled: true });
        }

        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }



    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        return (
            <MDBModal open={this.props.showModal} staticBackdrop>
                <MDBModalDialog size='md' centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><strong>"{this.props.user.username}"</strong> Kullanıcısı İçin Şifre Ata</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.props.toggleHandler}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol sm='3'>
                                    <strong>Kullanıcı Adı</strong>
                                </MDBCol>
                                <MDBCol sm='auto'>
                                    {this.props.user.username}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm='3'>
                                    <strong>Ad Soyad</strong>
                                </MDBCol>
                                <MDBCol sm='auto'>
                                    {this.props.user.name} {this.props.user.surname}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm='3'>
                                    <strong>E-posta</strong>
                                </MDBCol>
                                <MDBCol sm='auto'>
                                    {this.props.user.email}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-4'>
                                <MDBCol sm='3'>
                                    <strong>Telefon</strong>
                                </MDBCol>
                                <MDBCol sm='auto'>
                                    {this.props.user.mobile_phone}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.new_password.message} invalid>
                                        <MDBInput id='new_password'
                                                type='password'
                                                label='Yeni Şifre'
                                                onChange={this.formItemOnChangeHandler}
                                                className={this.state.form_controls.new_password.is_invalid ? 'is-invalid':''}
                                            />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.new_password_confirmation.message} invalid>
                                        <MDBInput id='new_password_confirmation'
                                                type='password'
                                                label='Yeni Şifre Tekrar'
                                                onChange={this.formItemOnChangeHandler}
                                                className={this.state.form_controls.new_password_confirmation.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            {spinnerWaitComponent}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.formSaveButtonClickHandler} disabled={this.state.submitButtonIsDisabled}>ŞİFREYİ KAYDET</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.props.toggleHandler}>KAPAT</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(UserSetPasswordComponent);
