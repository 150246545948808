import { MDBBtn, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBSelect, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalTitle, MDBModalContent, MDBRow, MDBCol, MDBCheckbox, MDBValidation, MDBFile, MDBTextArea, MDBValidationItem } from 'mdb-react-ui-kit';
import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { connect } from 'react-redux';
import Resource from '../../../apis/Resource';
import { mainpages } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import { fromFile } from "getbase64data";



const uploadAdapter = (loader) => {
    return {
        upload: async () => {
            const file = await loader.file;
            const base64 = await fromFile(file);
            return {
                default: base64,
            };
        },
    };
  };


function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}


class ResourceActivityEditorComponent extends Component {
    constructor(props) {
        super(props);

        this.selected_grade_id = null;

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            selectedFile: null,
            selectedAudio: null,
            grade_items: [{ 
                value: '-1',
                text: 'Kademe Seçiniz', 
                defaultSelected: false
             }],
            unit_items: [{ 
                value: '-1',
                text: 'Üniteleri Seçiniz', 
                defaultSelected: false
             }],
            all_resource_available_for: [{ 
                value: '-1',
                text: 'Kimin İçin Seçiniz', 
                defaultSelected: false
             }],
            meb_function_items: [{ 
                value: '-1',
                text: 'Kazanım Seçiniz', 
                defaultSelected: false
            }],
            form_controls: {
                grade_id: { value: '', is_invalid: false, message: '', instruction: '' },
                unit_ids: { value: '', is_invalid: false, message: '', instruction: '' },
                meb_function_id: { value: '', is_invalid: false, message: '', instruction: '' },
                subtype: { value: '', is_invalid: false, message: '', instruction: '' },
                resource_available_for: { value: '', is_invalid: false, message: '', instruction: '' },
                name: { value: '', is_invalid: false, message: '', instruction: '' },
                activity_xml: { value: '', is_invalid: false, message: '', instruction: '' },
                answers: { value: '', is_invalid: false, message: '', instruction: '' },
                column_spread: { value: '', is_invalid: false, message: '', instruction: '' },
                cognitive_level: { value: '', is_invalid: false, message: '', instruction: '' },
                usage_areas: { value: '', is_invalid: false, message: '', instruction: '' },
                published: { value: 0, is_invalid: false, message: '', instruction: '' },
                activity_text: { value: '', is_invalid: false, message: '', instruction: '' },
            },
            subtypes: [
                {   
                    text: 'Test Sorusu', 
                    value: 'multiple_choice',
                    defaultSelected: false
                },
                { 
                    text: 'Alıştırma', 
                    value: 'exercise',
                    defaultSelected: false
                },
                { 
                    text: 'Yazılı Alıştırması', 
                    value: 'html_exercise',
                    defaultSelected: false
                },
                { 
                    text: 'Dinleme Alıştırması', 
                    value: 'listening_exercise',
                    defaultSelected: false
                },
            ],
            usage_areas: [
                {   
                    text: 'Sınav Üreteci (PDF)', 
                    value: 'online_quiz_maker',
                    defaultSelected: false
                },
                {   
                    text: 'Reading & Writing Yazılı Üreteci (Word)', 
                    value: 'online_reading_writing_exam_maker',
                    defaultSelected: false
                },
                {   
                    text: 'Listening Yazılı Üreteci (Word)', 
                    value: 'online_listening_exam_maker',
                    defaultSelected: false
                },
                {   
                    text: 'Speaking Yazılı Üreteci (Word)', 
                    value: 'online_speaking_exam_maker',
                    defaultSelected: false
                },
                {   
                    text: 'Etkileşimli Ödev', 
                    value: 'interactive_homework',
                    defaultSelected: false
                },
                {   
                    text: 'Canlı Etkileşimli Sınav', 
                    value: 'live_interactive_exam',
                    defaultSelected: false
                },
                {   
                    text: 'Canlı Etkileşimli Mücadele', 
                    value: 'live_interactive_challenge',
                    defaultSelected: false
                },
    
            ],
            column_spreads: [
                {   
                    text: 'Tek kolon (Dar)', 
                    value: 'single',
                    defaultSelected: false
                },
                { 
                    text: 'Çift Kolon (Geniş)', 
                    value: 'double',
                    defaultSelected: false
                },
            ],
            cognitive_levels: [
                {   
                    text: 'Basit Bilişsel Süreçleri Ölçme Sorusu', 
                    value: 'simple',
                    defaultSelected: false
                },
                { 
                    text: 'Karmaşık Bilişsel Süreçleri Ölçme Sorusu', 
                    value: 'complex',
                    defaultSelected: false
                },
            ],
            submit_success: false,
            submit_error: false
        };


        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            this.state.form_controls.published.value = this.props.resourceBeingEdited.published;
            this.state.form_controls.name.value = this.props.resourceBeingEdited.name;
            this.state.form_controls.activity_xml.value = this.props.resourceBeingEdited.activity_xml || '';
            this.state.form_controls.answers.value = this.props.resourceBeingEdited.answers || '';
            this.state.form_controls.subtype.value = this.props.resourceBeingEdited.subtype;
            this.state.form_controls.column_spread.value = this.props.resourceBeingEdited.column_spread;
            this.state.form_controls.cognitive_level.value = this.props.resourceBeingEdited.cognitive_level || '';
            this.state.form_controls.resource_available_for.value = this.props.resourceBeingEdited.available_for;
            this.state.form_controls.grade_id.value = this.props.resourceBeingEdited.grade_id;
            this.state.form_controls.activity_text.value = this.props.resourceBeingEdited.activity_text;


            let unit_ids = [];
            this.props.resourceBeingEdited.units.forEach(unit => {
                unit_ids.push(unit.id);
            });
            this.state.form_controls.unit_ids.value = unit_ids;


            this.props.resourceBeingEdited.meb_functions.forEach(meb_function => {
                this.state.form_controls.meb_function_id.value = meb_function.id;
            });



            // mark selected one of column_spread
            this.state.column_spreads.forEach(column_spread => {
                if (this.props.resourceBeingEdited.column_spread !== undefined && this.props.resourceBeingEdited.column_spread !== null) {
                    column_spread.defaultSelected = (this.props.resourceBeingEdited.column_spread === column_spread.value);
                }
            });


            // mark selected one of cognitive_level
            this.state.cognitive_levels.forEach(cognitive_level => {
                if (this.props.resourceBeingEdited.cognitive_level !== undefined && this.props.resourceBeingEdited.cognitive_level !== null) {
                    cognitive_level.defaultSelected = (this.props.resourceBeingEdited.cognitive_level === cognitive_level.value);
                }
            });


            // mark selected one of subtype
            this.state.subtypes.forEach(subtype => {
                if (this.props.resourceBeingEdited.subtype !== undefined && this.props.resourceBeingEdited.subtype !== null) {
                    subtype.defaultSelected = (this.props.resourceBeingEdited.subtype === subtype.value);
                }
            });

            // mark selected ones of usage_area
            if (this.props.resourceBeingEdited.usage_areas !== undefined && this.props.resourceBeingEdited.usage_areas !== null) {
                let usage_areas = [];
                this.props.resourceBeingEdited.usage_areas.split(',').forEach(usage_area => {
                    usage_areas.push(usage_area);
                });
                this.state.form_controls.usage_areas.value = usage_areas.join(',');
                this.state.usage_areas.forEach(usage_area => {
                    usage_area.defaultSelected = usage_areas.includes(usage_area.value);
                });
            }

        }




        
        this.fileRef = React.createRef();


        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onUnitsComboboxSelectHandler = this.onUnitsComboboxSelectHandler.bind(this);
        this.onGradesComboboxSelectHandler = this.onGradesComboboxSelectHandler.bind(this);
        this.onAvailableForComboboxSelectHandler = this.onAvailableForComboboxSelectHandler.bind(this);
        this.onSubtypeComboboxSelectHandler = this.onSubtypeComboboxSelectHandler.bind(this);
        this.onColumSpreadComboboxSelectHandler = this.onColumSpreadComboboxSelectHandler.bind(this);
        this.onUsageAreasComboboxSelectHandler = this.onUsageAreasComboboxSelectHandler.bind(this);

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleAudioChange = this.handleAudioChange.bind(this);

        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);
        this.ckEditorOnChangeHandler = this.ckEditorOnChangeHandler.bind(this);
        this.onMebFunctionsComboboxSelectHandler = this.onMebFunctionsComboboxSelectHandler.bind(this);
        this.onCognitiveLevelComboboxSelectHandler = this.onCognitiveLevelComboboxSelectHandler.bind(this);
    }

    componentDidMount() {


        this.loadGradesFromReduxStore();
        this.loadResourceAvailableForsFromReduxStore();

        


    }


    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        if (key === 'published' && event.target.checked) {
            value = 1;
        } else if (key === 'published') {
            value = 0;
        }

        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        form_control_copy.value = value;
        let form_controls_copy = { ...this.state.form_controls};
        form_controls_copy[key] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }

    ckEditorOnChangeHandler(data) {
        let form_control_copy = { ...this.state.form_controls["activity_text"] }; // it is important to copy contents intead of using reference
        
        form_control_copy.value = data;
        let form_controls_copy = { ...this.state.form_controls};
        form_controls_copy["activity_text"] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }


    loadGradesFromReduxStore() {
        if (this.state.grade_items.length > 1) {
            return;
        }

        let grade_items = [];

        let hasCheckedResource = false;

        let grades = this.props.all_grades_with_units;

        grades.forEach(grade => {

            let selected_grade = false;

            if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                selected_grade = grade.id === this.props.resourceBeingEdited.grade_id;
            }   

            if (selected_grade) {
                this.selected_grade_id = grade.id;
                hasCheckedResource = true;
            }

            let option_grade = {
                text: grade.name,
                value: grade.id + '',
                defaultSelected: selected_grade
            };

            grade_items.push(option_grade);

        });


        if (grade_items.length === 0) {
            grade_items = [{
                value: '-1',
                text: 'Kademe Seçiniz', 
                selected: false
            }];
        }

        this.setState({ grade_items: grade_items });
        if (hasCheckedResource) {
            this.loadUnitsFromReduxStore();
        }
    }

    loadUnitsFromReduxStore() {

        let unit_items = [];

        let checked_unit_ids = [];
        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            this.props.resourceBeingEdited.units.forEach(edited_unit => {
                checked_unit_ids.push(edited_unit.id);
            });
        }

       
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {

            if (this.selected_grade_id === null) {
                return;
            }

            if (grade.id !== this.selected_grade_id) {
                return;
            }

            let units = grade.units;

            units.forEach(unit => {
                let selected_units = false;
                
                if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                    selected_units = checked_unit_ids.includes(unit.id);
                }                   

                let option = {
                    text: unit.name + ' - ' + unit.title, 
                    value: unit.id + '',
                    defaultSelected: selected_units
                };

                unit_items.push(option);
            });

        });

        if (unit_items.length === 0) {
            unit_items = [{
                value: '-1',
                text: 'Üniteleri Seçiniz', 
                defaultSelected: false
            }];
        }

        this.setState({ unit_items: unit_items });

        let firstSelectedOption = null;

        unit_items.forEach(option => {
            if (firstSelectedOption === null && option.defaultSelected) {
                firstSelectedOption = option;
            }
        });


        
        if (firstSelectedOption !== null) {
            this.loadFunctionsFromReduxStore([firstSelectedOption]);
        }
    }


    loadFunctionsFromReduxStore(selectedItems) {

        let meb_function_items = [];

        if (selectedItems === undefined || selectedItems.length === 0) {
            meb_function_items = [{
                value: '-1',
                text: 'Kazanım Seçmek için önce ÜNİTE seçiniz', 
                defaultSelected: false
            }];

            this.setState({ meb_function_items: meb_function_items });
            return;
        }


        let the_first_selected_unit_object = selectedItems[0];
        let the_first_selected_unit_id = the_first_selected_unit_object.value;

        let grades = this.props.all_grades_with_units;

        grades.forEach(grade => {
            if (this.selected_grade_id === null) {
                return;
            }

            if (grade.id !== this.selected_grade_id) {
                return;
            }

            let units = grade.units;

            units.forEach(unit => {
                if (Number(unit.id) !== Number(the_first_selected_unit_id)) {
                    return;
                }

                // the the first selected unit object is found 
                // let's gather the functions of it

                let meb_functions = unit.meb_functions;

                meb_functions.forEach(meb_function => {

                    let is_selected = this.props.resourceBeingEdited && this.props.resourceBeingEdited.meb_functions.length > 0 && meb_function.id === this.props.resourceBeingEdited.meb_functions[0].id;

                    
                    let option = {
                        text: meb_function.name, 
                        value: meb_function.id + '',
                        defaultSelected: is_selected
                    };

                    meb_function_items.push(option);
                });
            });

        });


        if (meb_function_items.length === 0) {
            meb_function_items = [{
                value: '-1',
                text: 'Kazanım Seçiniz', 
                defaultSelected: false
            }];
        }


        this.setState({ meb_function_items: meb_function_items });

    }



    loadResourceAvailableForsFromReduxStore() {
        if (this.state.all_resource_available_for.length > 1) {
            return;
        }
        let all_resource_available_for = [];

        let loaded_resource_for = this.props.all_resource_available_for;
        loaded_resource_for.forEach(available_for => {
            let selected = false;
            if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                selected = this.props.resourceBeingEdited.available_for === available_for.id ? true : false;
            }
            let option = {
                text: available_for.name, 
                value: available_for.id + '',
                defaultSelected: selected
            };

            all_resource_available_for.push(option);
        });

        if (all_resource_available_for.length === 0) {
            all_resource_available_for = [{
                value: '-1',
                text: 'Kimin İçin Seçiniz', 
                defaultSelected: false
            }];
        }

        this.setState({ all_resource_available_for: all_resource_available_for });
    }


    onGradesComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.grade_id };
        let selectedNumericItem = -1;
        selectedNumericItem = parseInt(selectedItem.value);
        form_control.value = selectedNumericItem;
        let form_controls = { ...this.state.form_controls };
        form_controls.grade_id = form_control;

        this.setState({ form_controls: form_controls });
        this.selected_grade_id = selectedNumericItem
        this.setState({ unit_items: [{ 
                value: '-1',
                text: 'Üniteleri Seçiniz', 
                defaultSelected: false
             }] });
        this.loadUnitsFromReduxStore();
    }


    onUnitsComboboxSelectHandler(selectedItems) {
        if (selectedItems === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.unit_ids };

        let selectedNumericItems = [];
        if (selectedItems.length > 0) {
            selectedItems.forEach(item => {
                selectedNumericItems.push(parseInt(item.value));
            });
        }

        form_control.value = selectedNumericItems;
        let form_controls = { ...this.state.form_controls };
        form_controls.unit_ids = form_control;
        this.setState({ form_controls: form_controls });
        this.loadFunctionsFromReduxStore(selectedItems);
    }


    onMebFunctionsComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.meb_function_id };
        let selectedNumericItem = -1;
        
        
        selectedNumericItem = parseInt(selectedItem.value);
        form_control.value = selectedNumericItem;
        let form_controls = { ...this.state.form_controls };
        form_controls.meb_function_id = form_control;

        this.setState({ form_controls: form_controls });
    }

    onAvailableForComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.resource_available_for };
        let selectedItemNumeric = selectedItem.value;
        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.resource_available_for = form_control;
        this.setState({ form_controls: form_controls });
    }

    onSubtypeComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }
        let form_control = { ...this.state.form_controls.subtype };
        form_control.value = selectedItem.value;
        let form_controls = { ...this.state.form_controls };
        form_controls.subtype = form_control;
        this.setState({ form_controls: form_controls });
    }

    onColumSpreadComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }
        let form_control = { ...this.state.form_controls.column_spread };
        form_control.value = selectedItem.value;
        let form_controls = { ...this.state.form_controls };
        form_controls.column_spread = form_control;
        this.setState({ form_controls: form_controls });
    }

    
    onCognitiveLevelComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }
        let form_control = { ...this.state.form_controls.cognitive_level };
        form_control.value = selectedItem.value;
        let form_controls = { ...this.state.form_controls };
        form_controls.cognitive_level = form_control;
        this.setState({ form_controls: form_controls });
    }

    onUsageAreasComboboxSelectHandler(selectedItems) {
        if (selectedItems === undefined) {
            return;
        }
        let selectedItem;
        if (selectedItems.length === 0) {
            selectedItem = null;
        } else {
            let item_values = [];
            selectedItems.forEach(item => {
                item_values.push(item.value);
            });
            selectedItem = item_values.join(',');
        }
        let form_control = { ...this.state.form_controls.usage_areas };
        form_control.value = selectedItem;
        let form_controls = { ...this.state.form_controls };
        form_controls.usage_areas = form_control;
        this.setState({ form_controls: form_controls });
    }


    onSubmitHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData();
        
        if (this.state.selectedFile !== null) {
            formData.append('file', this.state.selectedFile);
        }

        if (this.state.selectedAudio !== null) {
            formData.append('audio', this.state.selectedAudio);
        }

        let unitids = this.state.form_controls.unit_ids.value;
        for (let i = 0; i < unitids.length; i++) {
            formData.append('unit_ids[]', unitids[i]); // submit it as an array
        }

        let mainpageitem = mainpages[this.props.menucode];
        

        let meb_function_id = this.state.form_controls.meb_function_id.value;

        if (meb_function_id && !isNaN(meb_function_id)) {
            formData.append('meb_function_ids[]', meb_function_id); // submit it as an array for future planning
        }

        formData.append('grade_id', this.state.form_controls.grade_id.value);
        formData.append('type', mainpageitem.type);
        formData.append('subtype', this.state.form_controls.subtype.value);
        formData.append('available_for', this.state.form_controls.resource_available_for.value);
        formData.append('name', this.state.form_controls.name.value);
        formData.append('activity_xml', this.state.form_controls.activity_xml.value);
        formData.append('answers', this.state.form_controls.answers.value);
        formData.append('published', this.state.form_controls.published.value);
        formData.append('column_spread', this.state.form_controls.column_spread.value);
        formData.append('usage_areas', this.state.form_controls.usage_areas.value);
        formData.append('activity_text', this.state.form_controls.activity_text.value);
        formData.append('cognitive_level', this.state.form_controls.cognitive_level.value);
        this.postResource(formData);
        
    }

    async postResource(form_items) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            if (this.props.resourceBeingEdited !== null) {
                form_items.append('id', this.props.resourceBeingEdited.id);
                await Resource.editResource(form_items);
                this.props.showToastNotification('success', 'Kaynak kaydedildi');
            } else {
                await Resource.postResource(form_items);
                this.props.showToastNotification('success', 'Yeni kaynak oluşturuldu');
            }
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();

        } catch (error) {
            this.props.showToastNotification('error', 'Yeni kaynak oluşturulamadı');
            this.setState({ isSpinnerWaitShown: false });
        }
       
        
    }

    cancelButtonClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.closeCallback();
    }

    async deleteResource() {
        if (this.props.resourceBeingEdited === null) {
            return;
        }

        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = { id: this.props.resourceBeingEdited.id };
            await Resource.deleteResource(request);
            this.props.showToastNotification('success', 'Kaynak silindi');
            this.setState({ confirmDeleteShown: false });
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }


    onDeleteConfirmationButtonClickHandler() {
        this.deleteResource();
    }

    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }


    handleFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    handleAudioChange(event) {
        this.setState({ selectedAudio: event.target.files[0] });
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let unitnamesstr = '';
        let resource_name = '';
        let grade_name = '';
        if (this.state.confirmDeleteShown && this.props.resourceBeingEdited !== null && this.props.resourceBeingEdited !== undefined) {
            let units = this.props.resourceBeingEdited.units;
            let unitnamesarr = [];
            units.forEach(unit => {
                unitnamesarr.push(`${unit.name} - ${unit.title}`);
            });
            unitnamesstr = unitnamesarr.join(', ');

            resource_name = this.props.resourceBeingEdited.name;
            grade_name = this.props.resourceBeingEdited.grade.name;
        }

        let confirmDeleteComponent = (
        <MDBModal size='md' open={this.state.confirmDeleteShown}>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>İndirme Dosyası Silme Onayı</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p><strong>Silinecek kaynak adı:</strong> {resource_name}</p>
                        <p><strong>Kademesi:</strong> {grade_name}</p>
                        <p><strong>Üniteleri:</strong> {unitnamesstr}</p>
                        <p>Silme işlemini onaylıyor musunuz?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>);

        let title = '';
        if (this.props.resourceBeingEdited === null) {
            title = 'Yeni Etkinlik Oluştur';
        } else {
            title = 'Etkinlik Düzenle';
        }

        return (
            <>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>{title}</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation onSubmit={event => this.onSubmitHandler(event)} noValidate>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.grade_id.message} invalid>
                                        <MDBSelect
                                            id='grade_id'
                                            label='Sınıf'
                                            search
                                            searchLabel='Kademe ara'
                                            onChange={this.onGradesComboboxSelectHandler}
                                            data={this.state.grade_items}
                                            className={this.state.form_controls.grade_id.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.unit_ids.message} invalid>
                                        <MDBSelect
                                            id='unit_ids'
                                            label='Uniteler'
                                            search
                                            searchLabel='Ünite ara'
                                            multiple
                                            onChange={this.onUnitsComboboxSelectHandler}
                                            data={this.state.unit_items}
                                            className={this.state.form_controls.unit_ids.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.resource_available_for.message} invalid>
                                        <MDBSelect
                                            id='resource_available_for'
                                            label='Kimin İçin'
                                            onChange={this.onAvailableForComboboxSelectHandler}
                                            data={this.state.all_resource_available_for}
                                            className={this.state.form_controls.resource_available_for.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3 d-flex align-items-center'>
                                    <MDBCheckbox
                                        id='published'
                                        label='Yayına alınsın'
                                        onChange={this.formItemOnChangeHandler}
                                        checked={this.state.form_controls.published.value}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.subtype.message} invalid>
                                        <MDBSelect
                                            id='subtype'
                                            label='Etkinlik Türü'
                                            onChange={this.onSubtypeComboboxSelectHandler}
                                            data={this.state.subtypes}
                                            className={this.state.form_controls.subtype.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.column_spread.message} invalid>
                                        <MDBSelect
                                            id='column_spread'
                                            label='Kolon Yayılımı'
                                            onChange={this.onColumSpreadComboboxSelectHandler}
                                            data={this.state.column_spreads}
                                            className={this.state.form_controls.column_spread.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.usage_areas.message} invalid>
                                        <MDBSelect
                                            id='usage_areas'
                                            label='Kullanım Alanları'
                                            multiple
                                            onChange={this.onUsageAreasComboboxSelectHandler}
                                            data={this.state.usage_areas}
                                            className={this.state.form_controls.usage_areas.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.cognitive_level.message} invalid>
                                        <MDBSelect
                                            id='cognitive_level'
                                            label='Bilişsel Düzey (MEB)'
                                            onChange={this.onCognitiveLevelComboboxSelectHandler}
                                            data={this.state.cognitive_levels}
                                            className={this.state.form_controls.cognitive_level.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.meb_function_id.message} invalid>
                                        <MDBSelect
                                            id='meb_function_id'
                                            label='Kazanımlar (MEB)'
                                            onChange={this.onMebFunctionsComboboxSelectHandler}
                                            data={this.state.meb_function_items}
                                            className={this.state.form_controls.meb_function_id.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.answers.message} invalid>
                                        <MDBTextArea
                                            rows={4}
                                            id='answers'
                                            label='Cevaplar - Her satırda ayrı cevap'
                                            value={this.state.form_controls.answers.value}
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.answers.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.activity_xml.message} invalid>
                                        <MDBTextArea
                                            rows={4}
                                            id='activity_xml'
                                            label='Etkileşim kodu (XML)'
                                            value={this.state.form_controls.activity_xml.value}
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.activity_xml.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='12'>
                                    <CKEditor
                                        editor={ Editor }
                                        data={this.state.form_controls.activity_text.value}
                                        config={{
                                            extraPlugins: [ uploadPlugin ]
                                        }}
                                        onInit = { editor => {
                                            //setCkeditor(editor);
                                            // Add custom configuration for image upload
                                            editor.editing.view.change((writer) => {
                                                writer.setStyle(
                                                    "height",
                                                    "200px",
                                                    editor.editing.view.document.getRoot()
                                                );
                                            });
                                        } }
                                        onChange = { ( event, editor ) => {
                                            const data = editor.getData();
                                            this.ckEditorOnChangeHandler(data);
                                            console.log( { event, editor, data } );
                                        } }
                                        onBlur = { editor => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus = { editor => {
                                            console.log( 'Focus.', editor );
                                        } }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='12 file-container'>
                                    <MDBFile 
                                        label='Etkinlik Görüntüsünü yükleyin' 
                                        id='file' 
                                        name='file'
                                        onChange={this.handleFileChange} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='12 file-container'>
                                    <MDBFile 
                                        label='Etkinlik Sesini yükleyin' 
                                        id='audio' 
                                        name='audio'
                                        onChange={this.handleAudioChange} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn type='submit' color='primary' className='me-2'>KAYDET</MDBBtn>
                            {this.props.resourceBeingEdited !== null ? <MDBBtn color='danger' className='me-2' onClick={this.toggleDeleteConfirmationModal}>SİL</MDBBtn> : ''}
                            <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                    {confirmDeleteComponent}
                </MDBCard>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ResourceActivityEditorComponent);
