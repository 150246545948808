/*
    STUDENT ROLE ID 1
    TEACHER ROLE ID 2
    ADMIN   ROLE ID 3
*/

const USER_IS_STUDENT = 1;
const USER_IS_TEACHER = 2;
const USER_IS_ADMIN = 3;

const mainpages = {
    dashboard: {code: 'dashboard', role_id: [USER_IS_TEACHER, USER_IS_STUDENT, USER_IS_ADMIN], icon: 'tachometer-alt', title: 'Gösterge Paneli'},
    changepassword: {code: 'changepassword', role_id: [USER_IS_TEACHER, USER_IS_STUDENT, USER_IS_ADMIN], icon: '', title: 'Change Password'},
    editprofile: {code: 'editprofile', role_id: [USER_IS_TEACHER, USER_IS_STUDENT, USER_IS_ADMIN], icon: '', title: 'Edit User Profile'},

    teacher_downloads: {code: 'teacher_downloads', role_id: [USER_IS_TEACHER], icon: 'download', title: 'İndirme'},
        teacher_downl_iteam_apps: {code: 'teacher_downl_iteam_apps', role_id: [USER_IS_TEACHER], icon: '', title: 'i-TEAM Uygulama', type: 'download', subtype: 'iteam'},
        teacher_downl_challenge_apps: {code: 'teacher_downl_challenge_apps', role_id: [USER_IS_TEACHER], icon: '', title: 'TEAM Challenge', type: 'download', subtype: 'challenge'},
        teacher_downl_game_apps: {code: 'teacher_downl_game_apps', role_id: [USER_IS_TEACHER], icon: '', title: 'Oyunlar', type: 'download', subtype: 'game'},
        teacher_downl_vocabulary_presentations: {code: 'teacher_downl_vocabulary_presentations', role_id: [USER_IS_TEACHER], icon: '', title: 'Kelime Sunumları', type: 'download', subtype: 'vocabpresentation'},
        teacher_downl_vocabulary_lists: {code: 'teacher_downl_vocabulary_lists', role_id: [USER_IS_TEACHER], icon: '', title: 'Kelime Listeleri', type: 'download', subtype: 'vocablists'},
        teacher_downl_vocabulary_videos: {code: 'teacher_downl_vocabulary_videos', role_id: [USER_IS_TEACHER], icon: '', title: 'Kelime Videoları', type: 'download', subtype: 'vocabvideos'},
        teacher_downl_worksheets: {code: 'teacher_downl_worksheets', role_id: [USER_IS_TEACHER], icon: '', title: 'Çalışma Kağıtları', type: 'download', subtype: 'worksheet'},
        teacher_downl_exams: {code: 'teacher_downl_exams', role_id: [USER_IS_TEACHER], icon: '', title: 'Seviye Tespit Sınavlar', type: 'download', subtype: 'exams'},
        teacher_downl_skillexams: {code: 'teacher_downl_skillexams', role_id: [USER_IS_TEACHER], icon: '', title: 'Beceri Sınavlar', type: 'download', subtype: 'skillexams'},
        teacher_downl_posters: {code: 'teacher_downl_posters', role_id: [USER_IS_TEACHER], icon: '', title: 'Posterler', type: 'download', subtype: 'posters'},
        teacher_downl_annual_academic_plans: {code: 'teacher_downl_annual_academic_plans', role_id: [USER_IS_TEACHER], icon: '', title: 'Yıllık Planlar', type: 'download', subtype: 'academicplan'},
        teacher_downl_teachers_resources: {code: 'teacher_downl_teachers_resources', role_id: [USER_IS_TEACHER], icon: '', title: 'Öğretmen Kitapçık', type: 'download', subtype: 'teachersbooklet'},
    teacher_quizmaker: {code: 'teacher_quizmaker', role_id: [USER_IS_TEACHER], icon: 'cogs', title: 'Sınav Üreteci', type: 'quizmaker', subtype: 'pdf'},
    teacher_exammaker: {code: 'teacher_exammaker', role_id: [USER_IS_TEACHER], icon: 'fas fa-book-open', title: 'Yazılı Üreteci', type: 'exammaker', subtype: 'word'},
    teacher_grades: {code: 'teacher_grades', role_id: [USER_IS_TEACHER], icon: 'graduation-cap', title: 'Kademeler'},
    teacher_classes: {code: 'teacher_classes', role_id: [USER_IS_TEACHER], icon: 'chalkboard-teacher', title: 'Sınıf Yönetimi'},
    teacher_students: {code: 'teacher_students', role_id: [USER_IS_TEACHER], icon: 'user-friends', title: 'Öğrenciler'},
    teacher_exams: {code: 'teacher_exams', role_id: [USER_IS_TEACHER], icon: 'sticky-note', title: 'Canlı Sınavlar'},
    teacher_homework: {code: 'teacher_homework', role_id: [USER_IS_TEACHER], icon: 'laptop-house', title: 'Ödevler'},
    teacher_challenges: {code: 'teacher_challenges', role_id: [USER_IS_TEACHER], icon: 'hourglass-half', title: 'Yarışmalar'},
    teacher_games: {code: 'teacher_games', role_id: [USER_IS_TEACHER], icon: 'gamepad', title: 'Online Oyunlar', type: 'links', subtype: 'online_games'},
    teacher_iteam: {code: 'teacher_iteam', role_id: [USER_IS_TEACHER], icon: 'tv', title: 'i-TEAM Online', type: 'links', subtype: 'online_iteam'},

    student_attendclass: {code: 'student_attendclass', role_id: [USER_IS_STUDENT], icon: 'fas fa-chalkboard-teacher', title: 'Sınıflarım'},

    admin_resources_download: {code: 'admin_resources_download', role_id: [USER_IS_ADMIN], icon: 'fas fa-download', title: 'İndirme'},
        admin_downl_iteam_apps: {code: 'admin_downl_iteam_apps', role_id: [USER_IS_ADMIN], icon: '', title: 'i-TEAM Uygulama', type: 'download', subtype: 'iteam'},
        admin_downl_challenge_apps: {code: 'admin_downl_challenge_apps', role_id: [USER_IS_ADMIN], icon: '', title: 'TEAM Challenge', type: 'download', subtype: 'challenge'},
        admin_downl_game_apps: {code: 'admin_downl_game_apps', role_id: [USER_IS_ADMIN], icon: '', title: 'Oyunlar', type: 'download', subtype: 'game'},
        admin_downl_vocabulary_presentations: {code: 'admin_downl_vocabulary_presentations', role_id: [USER_IS_ADMIN], icon: '', title: 'Kelime Sunumları', type: 'download', subtype: 'vocabpresentation'},
        admin_downl_vocabulary_lists: {code: 'admin_downl_vocabulary_lists', role_id: [USER_IS_ADMIN], icon: '', title: 'Kelime Listeleri', type: 'download', subtype: 'vocablists'},
        admin_downl_vocabulary_videos: {code: 'admin_downl_vocabulary_videos', role_id: [USER_IS_ADMIN], icon: '', title: 'Kelime Videoları', type: 'download', subtype: 'vocabvideos'},
        admin_downl_worksheets: {code: 'admin_downl_worksheets', role_id: [USER_IS_ADMIN], icon: '', title: 'Çalışma Kağıtları', type: 'download', subtype: 'worksheet'},
        admin_downl_exams: {code: 'admin_downl_exams', role_id: [USER_IS_ADMIN], icon: '', title: 'Seviye Tespit Sınavlar', type: 'download', subtype: 'exams'},
        admin_downl_skillexams: {code: 'admin_downl_skillexams', role_id: [USER_IS_ADMIN], icon: '', title: 'Beceri Sınavları', type: 'download', subtype: 'skillexams'},
        admin_downl_posters: {code: 'admin_downl_posters', role_id: [USER_IS_ADMIN], icon: '', title: 'Posterler', type: 'download', subtype: 'posters'},
        admin_downl_annual_academic_plans: {code: 'admin_downl_annual_academic_plans', role_id: [USER_IS_ADMIN], icon: '', title: 'Yıllık Planlar', type: 'download', subtype: 'academicplan'},
        admin_downl_teachers_resources: {code: 'admin_downl_teachers_resources', role_id: [USER_IS_ADMIN], icon: '', title: 'Öğretmen Kitapçık', type: 'download', subtype: 'teachersbooklet'},
        admin_downl_quizmaker: {code: 'admin_downl_quizmaker', role_id: [USER_IS_ADMIN], icon: '', title: 'Sınav Üreteci', type: 'download', subtype: 'quizmaker'},
    admin_resources_extlinks: {code: 'admin_resources_extlinks', role_id: [USER_IS_ADMIN], icon: 'fas fa-link', title: 'Linkler'},
        admin_link_iteam: {code: 'admin_link_iteam', role_id: [USER_IS_ADMIN], icon: '', title: 'Online i-TEAM', type: 'links', subtype: 'online_iteam'},
        admin_link_games: {code: 'admin_link_games', role_id: [USER_IS_ADMIN], icon: '', title: 'Online Oyunlar', type: 'links', subtype: 'online_games'},
    admin_resources_activities: {code: 'admin_resources_activities', role_id: [USER_IS_ADMIN], icon: 'fas fa-cubes', title: 'Eğitimsel Kaynaklar', type: 'activity', subtype: ''},
    admin_quizmaker: {code: 'admin_quizmaker', role_id: [USER_IS_ADMIN], icon: 'fas fa-cogs', title: 'Sınav Üreteci', type: 'quizmaker', subtype: 'pdf'},
    admin_exammaker: {code: 'admin_exammaker', role_id: [USER_IS_ADMIN], icon: 'fas fa-book-open', title: 'Yazılı Üreteci', type: 'exammaker', subtype: 'word'},
    admin_user_management: {code: 'admin_downl_user_management', role_id: [USER_IS_ADMIN], icon: 'fas fa-users-cog', title: 'Kullanıcılar'},
        admin_user_mgmt_teachers: {code: 'admin_user_mgmt_teachers', role_id: [USER_IS_ADMIN], icon: '', title: 'Öğretmenler', type: 'users', subtype: 'teachers'},
        admin_user_mgmt_students: {code: 'admin_user_mgmt_students', role_id: [USER_IS_ADMIN], icon: '', title: 'Öğrenciler', type: 'users', subtype: 'students'},
        admin_user_mgmt_admins: {code: 'admin_user_mgmt_admins', role_id: [USER_IS_ADMIN], icon: '', title: 'Yöneticiler', type: 'users', subtype: 'admins'},
    admin_attribute_management: {code: 'admin_attribute_management', role_id: [USER_IS_ADMIN], icon: 'fas fa-tasks', title: 'Özellikler', type: 'attributes', subtype: ''},
    admin_accesscode_management: {code: 'admin_accesscode_management', role_id: [USER_IS_ADMIN], icon: 'fas fa-key', title: 'Erişim Kodları'},

};

/*
 * form_control_name is the name of form control
 * errors is the array of errors returned by laravel
 * form_controls is React state of form related with controls
 */

function cloneConfigForFormControl(form_control_name, errors, form_controls) {
    let field = form_control_name;
    let form_controls_copy = { ...form_controls };

    if (errors[field] !== undefined) {
        let errArrForField = errors[field];
        let configForField = form_controls_copy[field];
        let form_control_copy = { ...configForField };
        form_control_copy.message = errArrForField[0];
        form_control_copy.is_invalid = true;
        return form_control_copy;
    } else {
        let configForField = form_controls_copy[field];
        let form_control_copy = { ...configForField };
        form_control_copy.message = '';
        form_control_copy.is_invalid = false;
        return form_control_copy;
    }
};


function sanitizeUserName(username) {
    let regex = /[^a-z0-9_]/g;
    let replacement = '';
    username = username.toLowerCase();
    return username.replace(regex, replacement);
}

function sanitizePhoneNumber(phone) {
    let regex = /[^0-9]/g;
    let replacement = '';
    return phone.replace(regex, replacement);
}

function sanitizeEmail(email) {
    let regex = /[^0-9a-z._@-]/g;
    let replacement = '';
    email = email.toLowerCase();
    return email.replace(regex, replacement);
}

function formatPhoneNumber(phone) {
    let regex1 = /^([0-9]{0,3})$/g;
    let replacement1 = '($1)';
    let regex2 = /^([0-9]{0,3})([0-9]{0,3})$/g;
    let replacement2 = '($1) $2';
    let regex3 = /^([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})$/g;
    let replacement3 = '($1) $2 $3';
    let regex4 = /^([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})([0-9]{0,2}).*$/g;
    let replacement4 = '($1) $2 $3 $4';
    if (phone.length <= 3) {
        phone = phone.replace(regex1, replacement1);
    } else if (phone.length >= 4 && phone.length <= 6) {
        phone = phone.replace(regex2, replacement2);
    } else if (phone.length >= 7 && phone.length <= 8) {
        phone = phone.replace(regex3, replacement3);
    } else {
        phone = phone.replace(regex4, replacement4);
    }
    return phone;
}


export { 
    mainpages, 
    USER_IS_STUDENT,
    USER_IS_TEACHER,
    USER_IS_ADMIN,
    cloneConfigForFormControl,
    sanitizeUserName,
    sanitizePhoneNumber,
    sanitizeEmail,
    formatPhoneNumber
};