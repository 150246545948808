import { Component } from 'react';
import { connect } from 'react-redux';
import { USER_IS_TEACHER } from '../../common/common';
import TeacherDownloadComponent from './teacher/TeacherDownloadComponent';

class DownloadComponent extends Component {

    render() {
        let downloadComponent = 'You do not have permission to display download component.';

        if (this.props.authenticatedUser.role_id === USER_IS_TEACHER) {
            downloadComponent = <TeacherDownloadComponent />;
        }
        
        return (
            <div>
                {downloadComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(DownloadComponent);