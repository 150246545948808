import { MDBBtn, MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import Moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import Grade from '../../../apis/Grade';
import Resource from '../../../apis/Resource';
import { mainpages } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import TeacherDownloadViewerComponent from './TeacherDownloadViewerComponent';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

class TeacherDownloadComponent extends Component {

    constructor(props) {
        super(props);
        
        this.columns = [
            {
                label: 'Sınıf',
                field: 'grade',
                sort: true,
                searchable: true
            },
            {
                label: 'Üniteler',
                field: 'units',
                sort: true,
                searchable: true
            },
            {
                label: 'Ön İzleme',
                field: 'preview',
                sort: false,
                searchable: false
            },           
            {
                label: 'Kaynak Adı',
                field: 'name',
                sort: true,
                searchable: true
            },
            {
                label: 'Dosya Adı',
                field: 'download_name',
                sort: true,
                searchable: true
            },
            {
                label: 'Güncelleme Zamanı',
                field: 'updated_at',
                sort: true,
                searchable: true
            },
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false,
                fixed: 'right'
            }
        ];

        this.state = {
            isSpinnerWaitShown: false,
            resourceBeingViewed: null,
            isResourceViewerComponentTriggered: false,
            rows: []
        };

        this.all_grades_with_units = [];

        this.resourceViewerComponentCloseCallback = this.resourceViewerComponentCloseCallback.bind(this);
        this.viewResourceButtonClickHandler = this.viewResourceButtonClickHandler.bind(this);
        this.resourceDownload = this.resourceDownload.bind(this);
    }


    componentDidMount() {
        this.fetchGradesWithUnits();
        this.fetchAllDownloads();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.menucode !== this.props.menucode) {
            this.fetchAllDownloads();
            this.setState({ isResourceViewerComponentTriggered: false });
            this.setState({ resourceBeingViewed: null });
        }
    }

    async fetchGradesWithUnits() {
        if (this.props.all_grades_with_units.length > 0) {
            this.all_grades_with_units = this.props.all_grades_with_units;
            return;
        }
        if (this.all_grades_with_units.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Grade.fetchGradesWithUnits();
            this.all_grades_with_units = response.data;
            this.props.storeAllGradesWithUnits(this.all_grades_with_units);
        } catch (error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    async fetchAllDownloads() {

        let mainpageitem = mainpages[this.props.menucode];

        let type = mainpageitem.type;
        let subtype = mainpageitem.subtype;
        let filterLocal = { type: type, subtype: subtype };

        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Resource.fetchAllDownloadResources(filterLocal);
            let rows = [];
            response.data.forEach((resource, index) => {
                let item = {
                    grade: resource.grade.name,
                    units: (resource.units.map((unit, index) => {
                                                return <div key={index}>{unit.name}</div>;
                                            })),
                    preview: ( <div>
                        <Zoom zoomMargin={20}>
                            <img src={`${window.config.downloadserver}/${resource.uuid}/${resource.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: 'auto', height: '50px'}}/>
                        </Zoom>
                    </div>), 
                    name: resource.name,
                    download_name: resource.download_name,
                    updated_at: Moment(resource.updated_at).format('YYYY-MM-DD HH:mm'),
                    actions: (<div key={index}>
                                        <MDBBtn outline rounded color='primary' size='sm' className='px-2 mx-1' onClick={() => this.viewResourceButtonClickHandler(resource)}><MDBIcon fas icon='eye' /><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                                        <MDBBtn outline rounded color='success' size='sm' className='px-2 mx-1' onClick={() => this.resourceDownload(resource)}><MDBIcon fas icon='file-download' /><span className='d-none d-lg-inline ms-1'>İNDİR</span></MDBBtn>
                                </div>)
                };
                rows.push(item);
            });
            this.setState({ rows: rows });
        } catch(error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    viewResourceButtonClickHandler(resource) {
        this.setState({ resourceBeingViewed: resource });
        this.setState({ isResourceViewerComponentTriggered: true });
    }

    resourceViewerComponentCloseCallback() {
        this.setState({ isResourceViewerComponentTriggered: false });
        this.setState({ resourceBeingViewed: null });
    }


    resourceDownload(resource) {
        // window.config is set up from public/teamlms_ui_config.js which is imported in index.html
        
        let urlOriginal = window.config.downloadserver + '/' + resource.uuid + '/' + resource.original_file_name;
       
        let link = document.createElement('a');
        link.href = urlOriginal;
        link.setAttribute('download', resource.download_name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.isResourceViewerComponentTriggered) {
            return (<TeacherDownloadViewerComponent menucode={this.props.menucode} closeCallback={this.resourceViewerComponentCloseCallback} downloadCallback={() => this.resourceDownload(this.state.resourceBeingViewed)} resourceBeingViewed={this.state.resourceBeingViewed} />);
        }

        let gradesinfo = '';
        if (this.props.authenticatedUser.grades.length > 0) {
            let gradenamesarray = [];
            let gradenamesstring = '';
            let grades = this.props.authenticatedUser.grades;
            grades.forEach(grade => {
                gradenamesarray.push(grade.name);
            });

            gradenamesarray.sort();
            gradenamesstring = gradenamesarray.join(', ');
            gradesinfo = (<p>Seçmiş olduğunuz sınıf kademeleri <strong>{gradenamesstring}</strong> olup sadece bu sınıf kademeleri görüntülenmektedir. Diğer sınıf kademelerine ait indirme dosyalarına ulaşmak için soldaki menüde yer alan Kademeler bölümünden diğer kademeleri seçmelisiniz.</p>)
        }

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title + ' İndirme Dosyaları';

        return (
            <>
                <h3>{title}</h3>
                <p>Öğretmenlerle paylaşılmış olan tüm indirilebilir dosyalar bu bölümde yer almaktadır. İndirilebilir tüm öğretmen kaynaklarını aşağıda aratabilir, inceleyebilir ve indirebilirsiniz.</p>
                {gradesinfo}

                <div className='mt-4 p-4 border rounded'>
                    <MDBDatatable 
                            search
                            sm
                            hover
                            fullPagination
                            rowsText='Sayfadaki satır sayısı'
                            data={{columns: this.columns, rows: this.state.rows}} />
                </div>
                {spinnerWaitComponent}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        all_grades_with_units: state.all_grades_with_units,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAllGradesWithUnits: (all_grades_with_units) => dispatch({ type: 'storeAllGradesWithUnits', all_grades_with_units: all_grades_with_units }),
        storeRefreshTeacherDownloadsCallback: (callback) => dispatch({ type: 'storeRefreshTeacherDownloadsCallback', refreshTeacherDownloadsCallback: callback})
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(TeacherDownloadComponent);
