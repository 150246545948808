import React, { Component } from 'react';
import { MDBBtn, 
    MDBModal, 
    MDBModalBody, 
    MDBModalHeader, 
    MDBModalFooter, 
    MDBModalDialog, 
    MDBModalTitle, 
    MDBModalContent 
} from 'mdb-react-ui-kit';


class ModalInformationBoxComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.showModal,
            children: this.props.children
        }
    
    }

    shouldComponentUpdate(nextProps, nextStates) {
        if (this.state.modal !== nextProps.showModal) {
            this.setState({ modal: nextProps.showModal });
            return true;
        } else if (this.state.children !== nextProps.children) {
            this.setState({ children: nextProps.children });
            return true;
        }
        return false;
    }

    toggle = () => {
        this.props.toggleHandler();
    }

    render() {
        return (
            <MDBModal open={this.state.modal} staticBackdrop >
                <MDBModalDialog size='lg' centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{this.props.title}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.toggle}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                        {this.state.children}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='primary' onClick={this.toggle}>KAPAT</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        );
    }
}

export default ModalInformationBoxComponent;
