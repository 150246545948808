import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBCardTitle, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Moment from 'moment';
import StudentListComponent from './virtualclass/StudentListComponent';




class VirtualClassViewerComponent extends Component {

    render() {

        return(
            <div>
                <MDBCard className='col-12'>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>Sanal Sınıf Detayları</MDBCardTitle>
                    </MDBCardHeader>
                        <MDBCardBody className='p-4'>
                            <MDBRow className='mb-0'>
                                <MDBCol md='4' xl='3' className='p-2 mx-2'>
                                    <strong>Sınıf adı:</strong> {this.props.viewitem.name}
                                </MDBCol>
                                <MDBCol md='7' xl='8' className='p-2 mx-2'>
                                    <strong>Sınıf kodu:</strong> {this.props.viewitem.shared_code} <small className='grey-text'>(öğrenciye iletebilirsiniz)</small>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-0'>
                                <MDBCol md='4' xl='3' className='p-2 mx-2'>
                                    <strong>Kademe: </strong> {this.props.viewitem.grade.name}
                                </MDBCol>
                                <MDBCol md='7' xl='8' className='p-2 mx-2'>
                                    <strong>Öğretmen: </strong> {this.props.viewitem.owner.name} {this.props.viewitem.owner.surname}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-0'>
                                <MDBCol md='4' xl='3' className='p-2 mx-2'>
                                    <strong>Oluşturulma:</strong> {Moment(this.props.viewitem.created_at).format('YYYY-MM-DD')}
                                </MDBCol>
                                <MDBCol md='7' xl='8' className='p-2 mx-2'>
                                    <strong>Son Tarih:</strong> {Moment(this.props.viewitem.expire_at).format('YYYY-MM-DD')}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-0 d-flex justify-content-between'>
                                <MDBCol className='col-12 p-2 mx-2'>
                                    <MDBRow>
                                    <strong>Öğrenciler:</strong>
                                    </MDBRow>
                                    <MDBRow>
                                        <StudentListComponent classItem={this.props.viewitem} />
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-0 d-flex justify-content-between'>
                                <MDBCol className='col-12 p-2 mx-2'>
                                    <strong>Etkinlikler:</strong> xxx
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='success' className='me-2' onClick={() => this.props.editorCallback(this.props.viewitem)}>DÜZENLE</MDBBtn>
                            <MDBBtn color='danger' className='me-2' onClick={() => this.props.deleteCallback(this.props.viewitem)}>SİL</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.props.closeCallback}>SINIF LİSTESİNE DÖN</MDBBtn>
                        </MDBCardFooter>
                </MDBCard>
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageCode: state.mainPageCode
    }
};

export default connect(mapStateToProps)(VirtualClassViewerComponent);