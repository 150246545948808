import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput, MDBModalDialog, MDBModalContent, MDBModalTitle, MDBValidation, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import 'react-medium-image-zoom/dist/styles.css';
import ExamMaker from '../../apis/ExamMaker';

class ExamMakerExercisesChangeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            modalAskAndSave: false,
            exercises: this.props.exercises,
            examName: '',
            examDescription: ''
        };

        this.oldexercises = [];

        this.changeAnExercise = this.changeAnExercise.bind(this);
        this.changeAnExerciseRequest = this.changeAnExerciseRequest.bind(this);
        this.toggleAskAndSave = this.toggleAskAndSave.bind(this);

        this.onChangeExamName = this.onChangeExamName.bind(this);
        this.onChangeExamDescription = this.onChangeExamDescription.bind(this);
        this.onSaveAndCloseBtnClicked = this.onSaveAndCloseBtnClicked.bind(this);
        this.saveExam = this.saveExam.bind(this);
        

    }

    componentDidMount() {
        let numofexercises = this.props.exercises.length;

        for (let i = 0; i < numofexercises; i++) {
            this.oldexercises.push(null);
        }
    }
 
    changeAnExercise(index, event) {
        let already_used_ids = [];
        this.state.exercises.forEach(exercise => {
            already_used_ids.push(exercise.id);
        });

        let request = {
            source_exercise_id: this.state.exercises[index].id,
            source_grade_id: this.state.exercises[index].grade.id,
            source_unit_id: this.state.exercises[index].units[0].id,
            source_meb_function_id: this.state.exercises[index].meb_functions[0].id,
            source_subtype: this.state.exercises[index].subtype,
            already_used_ids: already_used_ids
        };

        if (this.oldexercises[index] !== null) {
            // there is saved one, switch back and forth
            let exercises = [ ...this.state.exercises ];
            let tmp = exercises[index];
            exercises[index] = this.oldexercises[index];
            this.oldexercises[index] = tmp;
            this.setState({ exercises: exercises });
        } else {
            // there is no saved one, fetch a new one
            this.changeAnExerciseRequest(index, request);            
        }

    }


    async changeAnExerciseRequest(index, request) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await ExamMaker.changeAnExercise(request);
            this.setState({ isSpinnerWaitShown: false });
            if (response.data !== undefined) {
                let exercises = [ ...this.state.exercises ];
                let fetched_exercises = response.data;
                if (fetched_exercises[0] !== undefined) {
                    // save the old one to switch back and forth
                    this.oldexercises[index] = exercises[index];

                    // change the old one with the fetched one
                    exercises[index] = fetched_exercises[0];
                    this.setState({ exercises: exercises });
                }
            }
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Soru değiştirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Soru değiştirilemedi. Beklenmedik hata!');
            }
        }
        
    }

    toggleAskAndSave() {
        this.setState({ modalAskAndSave: !this.state.modalAskAndSave });
    }

    onChangeExamName(event) {
        let value = event.target.value.substring(0, 240);
        this.setState( { examName: value } );
    }

    onChangeExamDescription(event) {
        this.setState( { examDescription: event.target.value } );
    }

    onSaveAndCloseBtnClicked() {

        let request = {
            name: this.state.examName,
            description: this.state.examDescription,
            grade_id: this.props.grade_id,
            exercises: this.state.exercises,
            exam_type: this.props.examType
        };

        this.saveExam(request);

        
    }


    async saveExam(request) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            await ExamMaker.saveExam(request);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Yazılı başarıyla oluşturuldu.');
            this.setState({ modalAskAndSave: false });
            this.props.reloadContentAndResetToInitial();
            
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.errors.name !== undefined) {
                this.props.showToastNotification('error', 'Yazılı oluşturulamadı. ' + error.response.data.errors.name[0]);
            } else if (error.response.data.errors.description !== undefined) {
                this.props.showToastNotification('error', 'Yazılı oluşturulamadı. ' + error.response.data.errors.description[0]);
            } else if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Yazılı oluşturulamadı. ' + error.response.data.message);
            }
        }
    }


    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let title_half = "Yazılı Üreteci";
        if (this.props.examType === "downloadable_exam") {
            title_half = "Okuma Yazma Yazılısı Üreteci";
        } else if (this.props.examType === "downloadable_listening_exam") {
            title_half = "Dinleme Yazılısı Üreteci";
        }


        let askNameAndSave = (
            <MDBModal size='md' open={this.state.modalAskAndSave} staticBackdrop>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Yazılı Oluştur</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.toggleAskAndSave}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow className='mb-2'>
                                <MDBCol>
                                    <MDBInput label='Yazılı adı' onChange={this.onChangeExamName} value={this.state.examName} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label='Yazılı açıklaması' onChange={this.onChangeExamDescription} value={this.state.examDescription} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="danger" onClick={this.onSaveAndCloseBtnClicked}>KAYDET</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.toggleAskAndSave}>KAPAT</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>);

        return (

            <div>
                <MDBCard>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>{title_half} - Yazılı Önizlemesi</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody className='p-4'>
                            <div className='form-outline row'>
                                {this.state.exercises.map((resource, index) => {
                                    let activity_text = resource.activity_text.replace(/>([A-Za-z0-9_ ])/, `>${index + 1}. $1`);

                                    let unit_name = resource.units.length === 0 ? 'This exercise does not have any unit' :  `${resource.units[0].name} - ${resource.units[0].title}`;
                                    let meb_function_name = resource.meb_functions.length === 0 ? 'This exercise does not have any MEB function' :  `${resource.meb_functions[0].name}`;
                                    return (
                                        <div key={index} className='col-12 border-bottom py-4'>
                                            <div className='row'>
                                                <div className='col-auto' dangerouslySetInnerHTML={{ __html: activity_text }}>
                                                </div>
                                                <div className='row'>
                                                <div className='col-auto py-3'>
                                                    <MDBBtn 
                                                        rounded 
                                                        outline 
                                                        color='primary' 
                                                        size='sm' 
                                                        className='px-2' 
                                                        onClick={(event) => this.changeAnExercise(index, event)}
                                                    >
                                                        <i className='fas fa-sync mt-0'></i>
                                                        <span className='d-none d-lg-inline ms-1'>DEĞİŞTİR</span>
                                                    </MDBBtn>
                                                    </div>
                                                    <small className='text-primary'>{resource.grade.name} : {unit_name}</small>
                                                    <small className='text-secondary'>{meb_function_name}</small>
                                                    <small>CEVAP: {resource.answers}</small>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' className='me-2' onClick={this.toggleAskAndSave}>YAZILIYI OLUŞTUR</MDBBtn>
                            <MDBBtn color='info' className='me-2' onClick={this.props.gotoBackButtonClickCallback}>GERİ</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.props.cancelButtonClickCallback}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                    {askNameAndSave}
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ExamMakerExercisesChangeComponent);
