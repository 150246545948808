import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import VirtualClass from '../../../apis/VirtualClass';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';


class StudentListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            removeAttendeeCandidate: null,
            rows: []
        };

        this.columns = [
            {
                label: 'Adı Soyadı',
                field: 'name_surname',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Durum',
                field: 'status',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Eylemler',
                field: 'actions',
                sort: 'asc',
                width: 100
            }
        ];

        this.onAcceptButtonClickHandler = this.onAcceptButtonClickHandler.bind(this);
        this.onRemoveButtonClickHandler = this.onRemoveButtonClickHandler.bind(this);
        this.onRemoveConfirmationButtonClickHandler = this.onRemoveConfirmationButtonClickHandler.bind(this);
        this.toggleRemoveConfirmationModal = this.toggleRemoveConfirmationModal.bind(this);

    }

    componentDidMount() {

        this.fetchAttendees();

    }

    onRemoveConfirmationButtonClickHandler() {
        this.removeAttendeeFromClass(this.state.removeAttendeeCandidate.id);
        this.setState({ removeAttendeeIdCandidate: null });
        this.setState({ confirmDeleteShown: false });

    }

    toggleRemoveConfirmationModal() {
        this.setState({ removeAttendeeIdCandidate: null });
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }

    onRemoveButtonClickHandler(attendee) {

        this.setState({ removeAttendeeCandidate: attendee });
        this.setState({ confirmDeleteShown: true });
        
    }

    async removeAttendeeFromClass(attendee_id) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                class_id: this.props.classItem.id,
                attendee_id: attendee_id
            };

            await VirtualClass.removeAttendeeFromClass(request);

            this.fetchAttendees();

            this.props.showToastNotification('success', 'Öğrenci sınıftan silindi');

        } catch(error) {
            this.props.showToastNotification('error', 'Öğrenci sınıftan silinirken bilinmeyen hata oluştu');
        }
        this.setState({ isSpinnerWaitShown: false });
    }


    onAcceptButtonClickHandler(attendee) {
        this.acceptAttendeeToClass(attendee.id);
    }

    async acceptAttendeeToClass(attendee_id) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                class_id: this.props.classItem.id,
                attendee_id: attendee_id
            };

            await VirtualClass.acceptAttendeeToClass(request);

            this.fetchAttendees();

            this.props.showToastNotification('success', 'Öğrenci sınıfa kabul edildi');

        } catch(error) {
            this.props.showToastNotification('error', 'Öğrenci sınıfa kabul edilirken bilinmeyen hata oluştu');
        }
        this.setState({ isSpinnerWaitShown: false });
    }

    async fetchAttendees() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                id: this.props.classItem.id
            };

            let response = await VirtualClass.fetchAttendeesByClassId(request);


            let attendees = response.data.attendees;

            let rows = [];
            attendees.forEach((attendee, index) => {
                let status = attendee.pivot.approved === 0 ? 'Onayınızı Bekliyor' : 'Katılımcı';
                
                let actions = (<div key={index}>
                    <MDBBtn rounded outline color='success' size='sm' className='px-2 waves-effect' onClick={() => this.onAcceptButtonClickHandler(attendee)}><i className='fas fa-user-check mt-0 me-1'></i>KABUL ET</MDBBtn>
                    <MDBBtn rounded outline color='danger' size='sm' className='px-2 waves-effect' onClick={() => this.onRemoveButtonClickHandler(attendee)}><i className='fas fa-user-times mt-0 me-1'></i>REDDET</MDBBtn>
                </div>);

                if (attendee.pivot.approved === 1) {
                    actions = (<div key={index}>
                                    <MDBBtn rounded outline color='danger' size='sm' className='px-2 waves-effect' onClick={() => this.onRemoveButtonClickHandler(attendee)}><i className='fas fa-user-times mt-0 me-1'></i>SINIFTAN ÇIKAR</MDBBtn>
                                </div>);
                }

                let item = {
                    name_surname: attendee.name + ' ' + attendee.surname,
                    status: status,
                    actions: actions
                };
                rows.push(item);
            });

            this.setState({ rows: rows });

        } catch (error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }


    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let confirmDeleteComponent = '';

        if (this.state.confirmDeleteShown) {
            confirmDeleteComponent = (<MDBModal centered size='md' isOpen={this.state.confirmDeleteShown} toggle={this.toggleDeleteConfirmationModal}>
                <MDBModalHeader toggle={this.toggleDeleteConfirmationModal}>Öğrenciyi Sınıftan Silme / Reddetme Onayı</MDBModalHeader>
                <MDBModalBody>
                    <p><strong>Sınıf adı:</strong> {this.props.classItem.name} </p>
                    <p><strong>Silinecek Öğrenci Adı:</strong> {this.state.removeAttendeeCandidate.name + ' ' + this.state.removeAttendeeCandidate.surname} </p>
                    <p>Öğrenciyi sınıftan silmek, çıkarmak, sınıfa katılım başvurusunu reddetmek istediğinize emin misiniz?</p>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={this.onRemoveConfirmationButtonClickHandler}>SİL</MDBBtn>
                    <MDBBtn color="secondary" onClick={this.toggleRemoveConfirmationModal}>İPTAL</MDBBtn>
                </MDBModalFooter>
            </MDBModal>);
        }

        return (
            <div>
                <MDBTable btn fixed>
                            <MDBTableHead columns={this.columns} />
                            <MDBTableBody rows={this.state.rows} />
                </MDBTable>
                {spinnerWaitComponent}
                {confirmDeleteComponent}
            </div>            
        );
    }
}


const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(StudentListComponent);