import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBCol, MDBInput, MDBRow, MDBSelect, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import QuizMaker from '../../apis/QuizMaker';
import Attributes from '../../apis/Attributes';
import QuizMakerQuestionsChangeComponent from './QuizMakerQuestionsChangeComponent';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import Moment from 'moment';

class QuizMakerGradeUnitsSelectComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            selected_grade_id: null,
            attributes: [],
            quiz_count_per_week: 1,
            question_count_per_quiz: 20,
            this_week_generated_quiz_count: 0,
            min_question_count: 4,
            grade_items: [],
            unit_items: [],
            unit_question_numbers: [],
            question_count: 0,
            fetched_questions: [],
            gotoPreviewQuestions: false,
            form_controls: {
                grade_ids: { value: '', is_invalid: false, message: '', instruction: '' }
            },
            message: '',
            submit_success: false,
            submit_error: false
        };

        this.onGradesComboboxOpenedHandler = this.onGradesComboboxOpenedHandler.bind(this);
        this.onGradesComboboxSelectHandler = this.onGradesComboboxSelectHandler.bind(this);
        this.onChangeQuestionNumbersHandler = this.onChangeQuestionNumbersHandler.bind(this);
        this.fetchNewQuestionsForQuiz = this.fetchNewQuestionsForQuiz.bind(this);


        this.setGotoPreviewToFalse = this.setGotoPreviewToFalse.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.fetchQuizQuestionsButtonClickHandler = this.fetchQuizQuestionsButtonClickHandler.bind(this);

        this.fetchUserAttributes = this.fetchUserAttributes.bind(this);
        

    }

    componentDidMount() {
        this.loadGradesFromReduxStore();
        this.fetchUserAttributes();
    }



    async fetchUserAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_items = {
                user_id: this.props.authenticatedUser.id
            };
            let response = await Attributes.fetchAllUserAttributes(form_items);
            let attributes = response.data;
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ attributes: attributes });
            attributes.forEach(attribute => {
                if (attribute.code === 'quizmaker_max_quizzes_per_week') {
                    let value = parseInt(attribute.user_attribute_value, 10);
                    this.setState( { quiz_count_per_week: value } );
                } else if (attribute.code === 'quizmaker_max_questions_per_quiz') {
                    let value = parseInt(attribute.user_attribute_value, 10);
                    this.setState( { question_count_per_quiz: value } );
                }
            });
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Kullanıcı Özellikleri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Kullanıcı Özellikleri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }

    }


    loadGradesFromReduxStore() {
        if (this.state.grade_items.length > 0) {
            return;
        }

        let grade_items = [];


        let checked_grade_ids = [];
        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            checked_grade_ids.push(this.props.resourceBeingEdited.grade.id);
            
        }

        let grades = this.props.all_grades_with_units;

        grades.forEach(grade => {

            let checked_grade = false;

            if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                checked_grade = checked_grade_ids.includes(grade.id);
            }   

            if (checked_grade) {
                this.setState({ selected_grade_id: grade.id });
            }

            let option_grade = {
                text: grade.name,
                value: grade.id + '',
                icon: null,
                disabled: false,
                checked: checked_grade
            };

            let regex = /^[2345678]\. SINIF$/g;
            let name = grade.name;
            let found = name.match(regex);

            if (found) {
                grade_items.push(option_grade);
            }

        });
        this.setState({ grade_items: grade_items });
    }

    loadUnitsFromReduxStore(selected_grade_id) {
        let unit_items = [];

        let checked_unit_ids = [];
        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            this.props.resourceBeingEdited.units.forEach(edited_unit => {
                checked_unit_ids.push(edited_unit.id);
            });
        }

        
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {

            if (selected_grade_id === null) {
                return;
            }

            if (grade.id !== selected_grade_id) {
                return;
            }

            let units = grade.units;

            units.forEach(unit => {
                unit_items.push(unit);
            });

        });

        this.setState({ unit_items: unit_items });
    }

    onGradesComboboxOpenedHandler(event) {
        this.loadGradesFromReduxStore();
    }

    onGradesComboboxSelectHandler(selectedItem) {

        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.grade_ids };

        let selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.grade_ids = form_control;


        this.setState({ form_controls: form_controls });
        this.setState({ selected_grade_id: selectedItemNumeric });
        this.setState({ unit_items: [] });
        this.setState({ unit_question_numbers: [] });
        this.setState({ question_count: 0 });

        let from_date = Moment().startOf('week');
        let to_date = Moment().endOf('week');

        let selected_grade_id = selectedItemNumeric;

        // calculate this week's generated quizes
        let grade_name = '';
        let thisWeekQuizCounter = 0;
        this.props.previousQuizes.forEach(rg => {
            if (rg.grade.id !== selected_grade_id) {
                return;
            }

            let created_at = Moment(rg.created_at);

            if (created_at.isAfter(from_date) && created_at.isBefore(to_date)) {
                thisWeekQuizCounter++;
                grade_name = rg.grade.name;
            }
        });

        this.setState({ this_week_generated_quiz_count: thisWeekQuizCounter});

        if (thisWeekQuizCounter < this.state.quiz_count_per_week) {
            this.loadUnitsFromReduxStore(selectedItemNumeric);
            this.setState({ message: ''});
            
        } else {
            this.setState({ message: 'Bu hafta ' + grade_name + ' için sınav oluşturma hakkınızı kullandınız, ' 
                + to_date.format('YYYY-MM-DD HH:mm') 
                + ' zamanından sonra ' + grade_name + ' için yeni bir sınav oluşturabilir veya başka sınıf için bir sınav oluşturmayı deneyebilirsiniz.'});
        }


    }


    onChangeQuestionNumbersHandler(index, event) {
        let value = event.target.value;
        let regex = /[^0-9]/g;
        let replacement = '';
        let newValue = value.replace(regex, replacement).substring(0, 2);
        let newState = { ...this.state };
       
        if ((parseInt(newValue) || 0) > 0) {
            newState.unit_question_numbers[index] = parseInt(newValue) || 0;
        } else {
            delete(newState.unit_question_numbers[index]);
        }
        this.setState(newState);
        this.calculateQuestionCount();
    }


    cancelButtonClickHandler(event) {
        this.props.closeCallback();
    }

    calculateQuestionCount() {
        let question_count = 0;
        this.state.unit_question_numbers.forEach(value => {
            let intVal = parseInt(value) || 0;
            question_count += intVal;
        });
        this.setState({ question_count: question_count });
    }

    fetchQuizQuestionsButtonClickHandler(event) {
        if (this.state.question_count < this.state.min_question_count || this.state.question_count > this.state.question_count_per_quiz) {
            event.preventDefault();
        }

        let unit_question_numbers = [];
        this.state.unit_items.forEach((unit, index) => {
            if (this.state.unit_question_numbers[index] === undefined) {
                unit_question_numbers.push({ unit_id: unit.id, question_count: 0 });
            } else {
                unit_question_numbers.push({ unit_id: unit.id, question_count: this.state.unit_question_numbers[index] });
            }
        });


        let request = {
            grade_id: this.state.selected_grade_id,
            unit_question_numbers: unit_question_numbers
        };

        this.fetchNewQuestionsForQuiz(request);

    }

    async fetchNewQuestionsForQuiz(request) {
        try {
            let response = await QuizMaker.fetchQuizNewQuestions(request)
            if (response.data.length !== undefined && response.data.length > 0) {
                this.setState({ fetched_questions: response.data });
                this.setState({ gotoPreviewQuestions: true });
            }
        } catch (error) {
            console.log(error);
        }
    }


    setGotoPreviewToFalse() {
        this.setState({ gotoPreviewQuestions: false });
    }

    render() {

        if (this.state.fetched_questions.length > 0 && this.state.gotoPreviewQuestions) {
            return (<QuizMakerQuestionsChangeComponent 
                        questions={this.state.fetched_questions} 
                        grade_id={this.state.selected_grade_id}
                        gotoBackButtonClickCallback={this.setGotoPreviewToFalse} 
                        cancelButtonClickCallback={this.cancelButtonClickHandler}
                        reloadContentAndResetToInitial={this.props.reloadContentAndResetToInitial}
                        />);
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let question_count = [];
        this.state.unit_items.forEach((item, index) => {
            if (this.state.unit_question_numbers[index] === undefined) {
                question_count[index] = '';
            } else {
                question_count[index] = this.state.unit_question_numbers[index];
            }
        });


        let get_new_questions_btn_disabled = true;
        let current_question_count_color = 'text-danger';       
        if (this.state.question_count < this.state.min_question_count || this.state.question_count > this.state.question_count_per_quiz) {
            get_new_questions_btn_disabled = true;
            current_question_count_color = 'text-danger';
        } else {
            get_new_questions_btn_disabled = false;
            current_question_count_color = 'text-success';
        }

        return (

            <>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>Sınav Üreteci - Sınıf ve Ünite Seçimi</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody>
                            <MDBRow className='mb-2'>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.grade_ids.message} invalid>
                                        <MDBSelect
                                            id='grade_ids'
                                            label='Sınıf Kademesi'
                                            search
                                            searchLabel='Sınıf Kademesi Ara'
                                            onChange={this.onGradesComboboxSelectHandler}
                                            data={this.state.grade_items}
                                            className={this.state.form_controls.grade_ids.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                    {this.state.unit_items.map((unit_item, index) => {
                                        let unit_item_text = `Soru sayısı (${unit_item.name} - ${unit_item.title})`;
                                        return (
                                            <MDBCol key={index} className='col-12 col-xl-6 mb-3'>
                                                <MDBInput 
                                                    label={unit_item_text} 
                                                    onChange={(event) => this.onChangeQuestionNumbersHandler(index, event)} 
                                                    value={question_count[index]} 
                                                />
                                            </MDBCol>
                                        );
                                    })}
                            </MDBRow>
                            <div className='form-outline row'>
                                {this.state.unit_items.length > 0 ? 
                                (<div className='col'>
                                    Seçtiğiniz soru sayısı: <strong className={current_question_count_color}>{this.state.question_count}</strong>
                                    <div className="small secondary">
                                        En az {this.state.min_question_count} ve en çok <strong className='text-danger'>{this.state.question_count_per_quiz}</strong> adet soru seçebilirsiniz.
                                    </div>
                                    <div className="small secondary">
                                        Bu hafta ilgili sınıftan <strong className='text-danger'>{this.state.quiz_count_per_week}</strong> sınav üretme hakkınızdan <strong className='text-danger'>{this.state.this_week_generated_quiz_count}</strong> adedini kullandınız.
                                    </div>
                                </div>) : ''}
                                {this.state.message !== '' ?
                                (<div className='col'><span className='text-danger'><strong>UYARI: </strong>{this.state.message}</span></div>) : ''}
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' disabled={get_new_questions_btn_disabled} className='me-2' onClick={this.fetchQuizQuestionsButtonClickHandler}>SINAV SORULARINI GETİR - {this.state.question_count}</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                </MDBCard>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(QuizMakerGradeUnitsSelectComponent);
