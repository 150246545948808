import { Component } from 'react';
import { connect } from 'react-redux';
import { MDBSideNavMenu, MDBIcon, MDBSideNavLink, MDBSideNavItem, MDBSideNavCollapse } from 'mdb-react-ui-kit';


import { mainpages } from '../common/common';

class SideBarAdminItemsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_download_expanded: false,
            is_link_expanded: false,
            is_users_expanded: false
        };

        this.changeMainPageToNavigatedMainPageHandler = this.changeMainPageToNavigatedMainPageHandler.bind(this);
        this.onToggleDownloadsNavItemClicked = this.onToggleDownloadsNavItemClicked.bind(this);
        this.onToggleLinksNavItemClicked = this.onToggleLinksNavItemClicked.bind(this);
        this.onToggleUsersNavItemClicked = this.onToggleUsersNavItemClicked.bind(this);
    }

    changeMainPageToNavigatedMainPageHandler(code) {
        if (this.props.isSmallScreen) {
            this.props.setSidenavOpen(false);
        }
        this.props.setMainPageCode(code);
    }

    onToggleDownloadsNavItemClicked() {
        this.setState({ is_link_expanded: false });
        this.setState({ is_users_expanded: false });


        this.setState({ is_download_expanded: !this.state.is_download_expanded });
    }

    onToggleLinksNavItemClicked() {
        this.setState({ is_download_expanded: false });
        this.setState({ is_users_expanded: false });


        this.setState({ is_link_expanded: !this.state.is_link_expanded });
    }
    
    onToggleUsersNavItemClicked() {
        this.setState({ is_download_expanded: false });
        this.setState({ is_link_expanded: false });


        this.setState({ is_users_expanded: !this.state.is_users_expanded });

    }
    
    render() {

        return (
        <>
            <MDBSideNavMenu>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.dashboard.code)}}>
                        <MDBIcon fas icon={mainpages.dashboard.icon} className='me-3' />{mainpages.dashboard.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink icon='angle-down' shouldBeExpanded={this.state.is_download_expanded} onClick={this.onToggleDownloadsNavItemClicked}>
                        <MDBIcon fas icon={mainpages.admin_resources_download.icon} className='me-3' />{mainpages.admin_resources_download.title}
                    </MDBSideNavLink>
                    <MDBSideNavCollapse open={this.state.is_download_expanded}>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_iteam_apps.code)}}>
                            {mainpages.admin_downl_iteam_apps.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_challenge_apps.code)}}>
                            {mainpages.admin_downl_challenge_apps.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_game_apps.code)}}>
                            {mainpages.admin_downl_game_apps.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_vocabulary_presentations.code)}}>
                            {mainpages.admin_downl_vocabulary_presentations.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_vocabulary_lists.code)}}>
                            {mainpages.admin_downl_vocabulary_lists.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_vocabulary_videos.code)}}>
                            {mainpages.admin_downl_vocabulary_videos.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_worksheets.code)}}>
                            {mainpages.admin_downl_worksheets.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_exams.code)}}>
                            {mainpages.admin_downl_exams.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_skillexams.code)}}>
                            {mainpages.admin_downl_skillexams.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_posters.code)}}>
                            {mainpages.admin_downl_posters.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_annual_academic_plans.code)}}>
                            {mainpages.admin_downl_annual_academic_plans.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_teachers_resources.code)}}>
                            {mainpages.admin_downl_teachers_resources.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_downl_quizmaker.code)}}>
                            {mainpages.admin_downl_quizmaker.title}
                        </MDBSideNavLink>
                    </MDBSideNavCollapse>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink icon='angle-down' shouldBeExpanded={this.state.is_link_expanded} onClick={this.onToggleLinksNavItemClicked}>
                        <MDBIcon fas icon={mainpages.admin_resources_extlinks.icon} className='me-3' />{mainpages.admin_resources_extlinks.title}
                    </MDBSideNavLink>
                    <MDBSideNavCollapse open={this.state.is_link_expanded}>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_link_iteam.code)}}>
                            {mainpages.admin_link_iteam.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_link_games.code)}}>
                            {mainpages.admin_link_games.title}
                        </MDBSideNavLink>
                    </MDBSideNavCollapse>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_resources_activities.code)}}>
                        <MDBIcon fas icon={mainpages.admin_resources_activities.icon} className='me-3' />{mainpages.admin_resources_activities.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_quizmaker.code)}}>
                        <MDBIcon fas icon={mainpages.admin_quizmaker.icon} className='me-3' />{mainpages.admin_quizmaker.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_exammaker.code)}}>
                        <MDBIcon fas icon={mainpages.admin_exammaker.icon} className='me-3' />{mainpages.admin_exammaker.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink icon='angle-down' shouldBeExpanded={this.state.is_users_expanded} onClick={this.onToggleUsersNavItemClicked}>
                        <MDBIcon fas icon={mainpages.admin_user_management.icon} className='me-3' />{mainpages.admin_user_management.title}
                    </MDBSideNavLink>
                    <MDBSideNavCollapse open={this.state.is_users_expanded}>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_user_mgmt_teachers.code)}}>
                            {mainpages.admin_user_mgmt_teachers.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_user_mgmt_students.code)}}>
                            {mainpages.admin_user_mgmt_students.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_user_mgmt_admins.code)}}>
                            {mainpages.admin_user_mgmt_admins.title}
                        </MDBSideNavLink>
                    </MDBSideNavCollapse>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_accesscode_management.code)}}>
                        <MDBIcon fas icon={mainpages.admin_accesscode_management.icon} className='me-3' />{mainpages.admin_accesscode_management.title}
                    </MDBSideNavLink>
                </MDBSideNavItem> 
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.admin_attribute_management.code)}}>
                        <MDBIcon fas icon={mainpages.admin_attribute_management.icon} className='me-3' />{mainpages.admin_attribute_management.title}
                    </MDBSideNavLink>
                </MDBSideNavItem> 
            </MDBSideNavMenu>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarAdminItemsComponent);