import { Component } from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { connect } from 'react-redux';

class SpinnerWaitComponent extends Component {
    constructor(props) {
        super(props);

        this.media = window.matchMedia('only screen and (max-width: 768px)');
        
        this.state = {
            showComponent: false,
            isSmallScreen: this.media.matches
        };

        this.timer = null;

        this.showAfterTimeout = this.showAfterTimeout.bind(this);
        this.setIsSmallScreenMatches = this.setIsSmallScreenMatches.bind(this);
    }


    componentDidMount() {
        this.timer = setTimeout(this.showAfterTimeout, 250);
        this.media.addEventListener('change', this.setIsSmallScreenMatches);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.media.removeEventListener('change', this.setIsSmallScreenMatches);
    }

    setIsSmallScreenMatches() {
        this.setState({ isSmallScreen : this.media.matches });
    }

    showAfterTimeout() {
        clearTimeout(this.timer);
        this.setState({ showComponent: true });
    }


    render() {
        let width = '100vw'; // calc(100vw - 260px);
        if (this.state.isSmallScreen) {
            width = '100vw';
        } else if (this.props.isSidenavOpened) {
            width = 'calc(100vw - 260px)';
        }

        if (this.state.showComponent) {
            return (
                <div className='position-absolute d-flex align-items-center justify-content-center h-100 p-3 top-0 left-0'
                        style={{backgroundColor: 'rgba(255, 255, 255, .8)', width: width, zIndex: '1000'}}>
                    <MDBSpinner style={{width: '3rem', height: '3rem'}} color='warning'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>
            );
        } else {
            return (<></>);
        }
    }
}

const mapStateToProps = state => {
    return {
        isSidenavOpened: state.isSidenavOpened
    }
};

export default connect(mapStateToProps)(SpinnerWaitComponent);