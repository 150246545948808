import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import Attributes from '../../../apis/Attributes';

class UserAttributesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSpinnerWaitShown: false,
            attributes: [],
            form_controls: {
            },
        };

        this.fetchUserAttributes = this.fetchUserAttributes.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.formResetButtonClickHandler = this.formResetButtonClickHandler.bind(this);
        this.formSaveButtonClickHandler = this.formSaveButtonClickHandler.bind(this);
        this.saveUserAttributes = this.saveUserAttributes.bind(this);
        this.formLoadSystemDefaultsButtonClickHandler = this.formLoadSystemDefaultsButtonClickHandler.bind(this);
    }

    componentDidMount() {
        this.fetchUserAttributes();
    }

    async fetchUserAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_item = {
                user_id: this.props.user.id
            };
            let response = await Attributes.fetchAllUserAttributes(form_item);
            let attributes = response.data;
            this.setState({ isSpinnerWaitShown: false });

            let form_controls = {};
            attributes.forEach(attribute => {
                form_controls[attribute.code] = {value: attribute.user_attribute_value, is_invalid: false, message: '', instruction: attribute.description};
            });

            this.setState({ form_controls: form_controls });
            this.setState({ attributes: attributes });

            
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Kullanıcı Özellikleri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Kullanıcı Özellikleri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }

    }

    formSaveButtonClickHandler() {
        this.saveUserAttributes();
        this.props.toggleHandler();
    }

    async saveUserAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_items = {};
            console.log(this.props.user);
            form_items['user_id'] = this.props.user.id;
            this.state.attributes.forEach(attribute => {
                form_items[attribute.code] = this.state.form_controls[attribute.code].value;
            });
            await Attributes.saveAllUserAttributes(form_items);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Kullanıcı Özellikleri başarıyla kaydedildi.');
            this.fetchUserAttributes();
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Kullanıcı Özellikleri kaydedilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Kullanıcı Özellikleri kaydedilemedi. Bilinmeyen hata!');
            }
        }
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;
        let oldValue = event.target.defaultValue;

        const regex = /^[0-9]*$/;
        if (key === 'quizmaker_max_questions_per_quiz' || key === 'quizmaker_max_quizzes_per_week') {
            if (regex.test(value) === false) {
                value = oldValue;
            }
        }

        let formControlCopy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        formControlCopy.value = value;
        let formControlsCopy = { ...this.state.form_controls};
        formControlsCopy[key] = formControlCopy;
        this.setState({ form_controls: formControlsCopy }); // reference is changed, now all visible parts will be refreshed
    }

    formResetButtonClickHandler() {
        let attributes = this.state.attributes;
        let form_controls = {};
        attributes.forEach(attribute => {
            form_controls[attribute.code] = {value: attribute.default_value, is_invalid: false, message: '', instruction: attribute.description};
        });

        this.setState({ form_controls: form_controls });
    }

    formLoadSystemDefaultsButtonClickHandler() {
        let form_controls = {};
        this.state.attributes.forEach(attribute => {
            form_controls[attribute.code] = {value: attribute.default_value, is_invalid: false, message: '', instruction: attribute.description};
        });
        this.setState({ form_controls: form_controls });
    }

    render() {

        let attributes = this.state.attributes;
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let disabled = true;
        this.state.attributes.forEach(attribute => {
            if (attribute.user_attribute_value !== this.state.form_controls[attribute.code].value) {
                disabled = false;
            }
        });

        return (
            <MDBModal open={this.props.showModal} staticBackdrop>
                <MDBModalDialog size='lg' centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Kullanıcı Özellikleri</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.props.toggleHandler}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {attributes.length === 0 ? <div>Kullanıcı Özellikleri Yükleniyor... Lütfen Bekleyiniz...</div> :
                            attributes.map((attribute, index) => {
                                let code = attribute.code;
                                let value = attribute.default_value;
                                if (this.state.form_controls[code] !== undefined) {
                                    value = this.state.form_controls[code].value;
                                }
                                return (
                                    <MDBRow key={index} >
                                        <MDBCol className='form-outline pb-4'>
                                            <MDBInput
                                                id={attribute.code}
                                                label={attribute.name} 
                                                aria-describedby={`${attribute.code}_info`}
                                                onChange={this.formItemOnChangeHandler}
                                                value={value} 
                                            />
                                            <div id={`${attribute.code}_info`} className='form-text pt-2'>
                                                {attribute.description}
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                )
                            })}
                            {spinnerWaitComponent}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" disabled={disabled} onClick={this.formSaveButtonClickHandler}>KAYDET</MDBBtn>
                            <MDBBtn color="info" disabled={disabled} onClick={this.formResetButtonClickHandler}>SİL BAŞTAN</MDBBtn>
                            <MDBBtn color="warning" onClick={this.formLoadSystemDefaultsButtonClickHandler}>SİSTEM DEĞERLERİNİ GETİR</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.props.toggleHandler}>KAPAT</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(UserAttributesComponent);
