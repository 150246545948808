import { MDBBtn, MDBDatatable } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Grade from '../../../apis/Grade';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import TeacherGradesEditorComponent from './TeacherGradesEditorComponent';

class TeacherGradesComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            editGradesComponentShown: false,
            rows: []
        };

        this.columns = [
            {
                label: 'Dersine Girdiğiniz Sınıf Kademeleri',
                field: 'name',
                sort: false,
                searchable: false
            }
        ];


        this.onClickEditTeacherGradesBtnHandler = this.onClickEditTeacherGradesBtnHandler.bind(this);
        this.closeTeacherGradesEditorComponentCallback = this.closeTeacherGradesEditorComponentCallback.bind(this);

    }

    componentDidMount() {
        this.fetchGradesOfUser();
    }


    async fetchGradesOfUser() {
        
        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Grade.fetchAttachedGradesForUser();
            this.setState({ isSpinnerWaitShown: false });
            let rows = [];
            response.data.forEach(item => {
                let newItem = {
                    name: item.name
                };
                rows.push(newItem);
            });

            this.setState({ rows: rows });

        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
        }

        
    }


    onClickEditTeacherGradesBtnHandler() {
        this.setState({ editGradesComponentShown: true });
    }

    closeTeacherGradesEditorComponentCallback(cancelled) {
        if (!cancelled) {
            this.fetchGradesOfUser();
        }

        this.setState({ editGradesComponentShown: false });
    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.editGradesComponentShown) {
            return (<TeacherGradesEditorComponent closeCallback={this.closeTeacherGradesEditorComponentCallback} />);
        }

        return (
            <div>
                <h3>Sınıf Kademesi Yönetimi</h3>
                <p>Sadece dersine girdiğiniz kademeleri seçmeniz durumunda daha sade ve kolay bir TEAM elt publishing LMS kullanımı deneyimleyeceksiniz.</p>

                <p>Tüm kademeleri seçmeniz durumunda TEAM elt publishing'in tüm kademelere yönelik sayısal içeriğe ulaşmış olacaksınız.</p>

                <MDBBtn onClick={this.onClickEditTeacherGradesBtnHandler} color='primary'>SINIF KADEMELERİNİ DÜZENLE</MDBBtn>

                {this.state.rows.length > 0 && 
                    <div className='mt-4 p-4 border rounded'>
                        <MDBDatatable 
                            sm
                            hover
                            fullPagination
                            data={{columns: this.columns, rows: this.state.rows}} />
                    </div>
                }
                {this.state.rows.length === 0 &&
                    <div className='mt-4 p-4 border rounded text-success'>
                        <p>Tüm sınıf kademelerine ilişkin sayısal kaynaklara erişiminiz bulunmaktadır.</p>
                        <p>Daha sade bir kullanım deneyimi için yukarıdaki <strong>SINIF KADEMELERİNİ DÜZENLE</strong> düğmesine sadece ilgilendiğiniz kademeleri seçebilirsiniz.</p>
                    </div>
                } 
                {spinnerWaitComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(TeacherGradesComponent);
