import { MDBBtn, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBCardFooter, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import { mainpages } from '../../../common/common';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

class TeacherDownloadViewerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            unit_items: []
        };

        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
    }

    componentDidMount() {

        let resource_unit_ids = [];
        this.props.resourceBeingViewed.units.forEach(unit => {
            resource_unit_ids.push(unit.id);
        });

        let unit_items = [];
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {
            grade.units.forEach(unit => {
                if (resource_unit_ids.includes(unit.id)) {
                    unit_items.push(`${unit.name}: ${unit.title}`);
                }
            });
        });
 
        this.setState({ unit_items: unit_items});


    }


 

    cancelButtonClickHandler() {
        this.props.closeCallback();
    }


    render() {

        let units_array = [];
        this.state.unit_items.forEach(unit => {
            units_array.push(unit);
        });
        let units = units_array.join(', ');

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title + ' İndirme Dosyası İncele';

        return (
            <div className='col-12'>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>{title}</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='grade'
                                            label='Kademe'
                                            type='text'
                                            value={this.props.resourceBeingViewed.grade.name}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='units'
                                            label='Üniteler'
                                            type='text'
                                            value={units}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='name'
                                            label='Kaynak Adı'
                                            type='text'
                                            value={this.props.resourceBeingViewed.name}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='description'
                                            label='Açıklama'
                                            type='textarea'
                                            value={this.props.resourceBeingViewed.description}
                                            disabled
                                            rows='3'
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBInput id='download_name'
                                            label='Dosya Adı'
                                            type='text'
                                            value={this.props.resourceBeingViewed.download_name}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            {this.props.resourceBeingViewed.thumbnail_path ? 
                                (<div className=''><Zoom zoomMargin={20}><img src={`${window.config.downloadserver}/${this.props.resourceBeingViewed.uuid}/${this.props.resourceBeingViewed.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: 'auto', height: '200px'}}/></Zoom></div>) 
                                : ''
                            }
                        </MDBRow>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBBtn color='primary' className='me-2' onClick={this.props.downloadCallback}>İNDİR</MDBBtn>
                        <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>KAPAT</MDBBtn>
                    </MDBCardFooter>
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units
    }
};

export default connect(mapStateToProps)(TeacherDownloadViewerComponent);