import { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBIcon,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse
} from 'mdb-react-ui-kit';


import { mainpages } from '../common/common';

class SideBarTeacherItemsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_download_collapsed: false
        };
        
        this.changeMainPageToNavigatedMainPageHandler = this.changeMainPageToNavigatedMainPageHandler.bind(this);
        this.onToggleDownloadsNavItemClicked = this.onToggleDownloadsNavItemClicked.bind(this);
    }

    changeMainPageToNavigatedMainPageHandler(code) {
        if (this.props.isSmallScreen) {
            this.props.setSidenavOpen(false);
        }
        this.props.setMainPageCode(code);
    }
    
    onToggleDownloadsNavItemClicked() {
        this.setState({ is_download_collapsed: !this.state.is_download_collapsed });
    }

    render() {

        return (
        <>
            <MDBSideNavMenu>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.dashboard.code)}}>
                        <MDBIcon fas icon={mainpages.dashboard.icon} className='me-3' /> {mainpages.dashboard.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_grades.code)}}>
                        <MDBIcon fas icon={mainpages.teacher_grades.icon} className='me-3' /> {mainpages.teacher_grades.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                {
                /*
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_classes.code)}}>
                        <MDBIcon far icon={mainpages.teacher_classes.icon} className='fa-fw me-3' /> {mainpages.teacher_classes.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_students.code)}}>
                        <MDBIcon far icon={mainpages.teacher_students.icon} className='fa-fw me-3' /> {mainpages.teacher_students.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                */
                }
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_iteam.code)}}>
                        <MDBIcon fas icon={mainpages.teacher_iteam.icon} className='me-3' /> {mainpages.teacher_iteam.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink icon='angle-down' shouldBeExpanded={this.state.is_download_collapsed} onClick={this.onToggleDownloadsNavItemClicked}>
                        <MDBIcon fas icon={mainpages.teacher_downloads.icon} className='me-3' /> {mainpages.teacher_downloads.title}
                    </MDBSideNavLink>
                    <MDBSideNavCollapse open={this.state.is_download_collapsed}>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_iteam_apps.code)}}>
                            {mainpages.teacher_downl_iteam_apps.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_challenge_apps.code)}}>
                            {mainpages.teacher_downl_challenge_apps.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_game_apps.code)}}>
                            {mainpages.teacher_downl_game_apps.title}
                        </MDBSideNavLink>
                        {/*
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_vocabulary_presentations.code)}}>
                            {mainpages.teacher_downl_vocabulary_presentations.title}
                        </MDBSideNavLink>
                        */}
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_vocabulary_lists.code)}}>
                            {mainpages.teacher_downl_vocabulary_lists.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_vocabulary_videos.code)}}>
                            {mainpages.teacher_downl_vocabulary_videos.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_worksheets.code)}}>
                            {mainpages.teacher_downl_worksheets.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_exams.code)}}>
                            {mainpages.teacher_downl_exams.title}
                        </MDBSideNavLink>
                        {
                        /*
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_skillexams.code)}}>
                            {mainpages.teacher_downl_skillexams.title}
                        </MDBSideNavLink>
                        */
                        }
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_posters.code)}}>
                            {mainpages.teacher_downl_posters.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_annual_academic_plans.code)}}>
                            {mainpages.teacher_downl_annual_academic_plans.title}
                        </MDBSideNavLink>
                        <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_downl_teachers_resources.code)}}>
                            {mainpages.teacher_downl_teachers_resources.title}
                        </MDBSideNavLink>
                    </MDBSideNavCollapse>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_quizmaker.code)}}>
                        <MDBIcon fas icon={mainpages.teacher_quizmaker.icon} className='me-3' /> {mainpages.teacher_quizmaker.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_exammaker.code)}}>
                        <MDBIcon fas icon={mainpages.teacher_exammaker.icon} className='me-3' /> {mainpages.teacher_exammaker.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.teacher_games.code)}}>
                        <MDBIcon fas icon={mainpages.teacher_games.icon} className='me-3' /> {mainpages.teacher_games.title}
                    </MDBSideNavLink>
                </MDBSideNavItem>
            </MDBSideNavMenu>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        refreshTeacherDownloadsCallback: state.refreshTeacherDownloadsCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarTeacherItemsComponent);