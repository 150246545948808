import { MDBSelect, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDatatable, MDBModalDialog, MDBModalTitle, MDBModalContent, MDBIcon } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import Grade from '../../../apis/Grade';
import Resource from '../../../apis/Resource';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';

import { mainpages } from '../../../common/common';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ResourceActivityEditorComponent from './ResourceActivityEditorComponent';
import ResourceActivityViewerComponent from './ResourceActivityViewerComponent';

class ResourcesActivitiesComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmDeleteShown: false,
            deleteCandidateResource: null,
            isResourceEditorComponentTriggered: false,
            isResourceViewerComponentTriggered: false,
            resourceBeingEdited: null,
            resourceBeingViewed: null,
            rows: [],
            published_value: '',
            available_for_value: '',
            units_value: [],
            grade_items: [{
                text: '- Tüm Kademeler -',
                value: '-1',
                defaultSelected: true
            }],
            unit_items: [{
                text: '- Tüm Üniteler -',
                value: '-1',
                defaultSelected: true
            }],
            meb_function_items: [{
                text: '- Tüm Kazanımlar -',
                value: '-1',
                defaultSelected: true
            }],
            resource_type_items: [
                {   
                    text: '- Tüm Kaynak Türler -', 
                    value: 'all',
                    defaultSelected: true
                },
                {   
                    text: 'Test Sorusu', 
                    value: 'multiple_choice',
                    defaultSelected: false
                },
                { 
                    text: 'Alıştırma', 
                    value: 'exercise',
                    defaultSelected: false
                },
                { 
                    text: 'Yazılı Alıştırması', 
                    value: 'html_exercise',
                    defaultSelected: false
                },
                { 
                    text: 'Dinleme Alıştırması', 
                    value: 'listening_exercise',
                    defaultSelected: false
                },
            ],
        };

        this.selected_resource_type_code = 'all';
        this.selected_grade_id = -1;
        this.selected_unit_id = -1;
        this.selected_meb_function_id = -1;

        

        
        this.all_resource_available_for = [];

        this.columns = [
            {
                label: 'Sınıf',
                field: 'grade',
                sort: true,
                searchable: true
            },
            {
                label: 'Uniteler',
                field: 'units',
                sort: true,
                searchable: true
            },
            {
                label: 'Ön İzleme',
                field: 'preview',
                sort: false,
                searchable: false
            },
            {
                label: 'Güncelleme Zamanı',
                field: 'updated_at',
                sort: true,
                searchable: true
            },
            {
                label: 'Yayın',
                field: 'published',
                sort: true,
                searchable: true
            },
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false
            }
        ];

        this.addNewResourceButtonClickHandler = this.addNewResourceButtonClickHandler.bind(this);
        this.resourceEditorComponentCloseCallback = this.resourceEditorComponentCloseCallback.bind(this);
        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);
        this.onDeleteButtonClickHandler = this.onDeleteButtonClickHandler.bind(this);
        this.editResourceButtonClickHandler = this.editResourceButtonClickHandler.bind(this);

        this.onGradesComboboxSelectHandler = this.onGradesComboboxSelectHandler.bind(this);
        this.loadUnitsFromReduxStore = this.loadUnitsFromReduxStore.bind(this);
        this.onResourceTypeComboboxSelectHandler = this.onResourceTypeComboboxSelectHandler.bind(this);
        this.onUnitsComboboxSelectHandler = this.onUnitsComboboxSelectHandler.bind(this);
        this.onMebFunctionsComboboxSelectHandler = this.onMebFunctionsComboboxSelectHandler.bind(this);
        this.onFilterClickHandler = this.onFilterClickHandler.bind(this);
        this.updateResourceTypeSelectionCombobox = this.updateResourceTypeSelectionCombobox.bind(this);

        this.resourceViewerComponentCloseCallback = this.resourceViewerComponentCloseCallback.bind(this);

    }

    componentDidMount() {
        this.fetchAllTogether();
    }

    async fetchAllTogether() {
        await this.fetchGradesWithUnits();
        await this.fetchResourceAvailableFors();
        await this.fetchFilteredResources();
    }

    async fetchFilteredResources() {
        let filterLocal = {};

        filterLocal = {  };

        if (this.selected_grade_id > -1) {
            filterLocal['grade_id'] = this.selected_grade_id;
        }
        

        if (this.selected_unit_id > -1) {
            filterLocal['unit_ids'] = [ this.selected_unit_id ];
        }

        if (this.selected_meb_function_id > -1) {
            filterLocal['meb_function_ids'] = [ this.selected_meb_function_id ];
        }


        if (this.selected_resource_type_code !== 'all') {
            filterLocal['subtype'] = this.selected_resource_type_code;
        }


        await this.fetchAllResources(filterLocal);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.menucode !== this.props.menucode) {
            this.fetchFilteredResources();
            this.setState({ isResourceEditorComponentTriggered: false });
            this.setState({ isResourceViewerComponentTriggered: false });
            this.setState({ resourceBeingEdited: null });
            this.setState({ resourceBeingViewed: null });
        }
    }

    async fetchResourceAvailableFors() {
        if (this.props.all_resource_available_for.length > 0) {
            this.all_resource_available_for = this.props.all_resource_available_for;
            return;
        }
        if (this.all_resource_available_for.length > 0) {
            return;
        }
        
        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Resource.fetchAllResourcesFor();
            this.setState({ isSpinnerWaitShown: false });
            this.all_resource_available_for = response.data;
            this.props.storeAllResourceAvailableFor(this.all_resource_available_for);
            this.loadFilterComboBoxes();
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }

    async fetchGradesWithUnits() {
        if (this.props.all_grades_with_units.length > 0) {
            this.loadSelectGradeOpenList(this.props.all_grades_with_units);
            return;
        }
        
        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Grade.fetchGradesWithUnits();
            this.setState({ isSpinnerWaitShown: false });
            this.props.storeAllGradesWithUnits(response.data);
            this.loadSelectGradeOpenList(response.data);
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }


    async fetchAllResources(filter) {
        let mainpageitem = mainpages[this.props.menucode];

        let type = mainpageitem.type;

        let filterLocal = {};
        if (filter === undefined) {
            filterLocal = { type: type };
        } else {
            filterLocal = { ...filter, type: type };
        }


        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Resource.fetchAllResources(filterLocal);
            this.setState({ isSpinnerWaitShown: false });
            let rows = [];
            response.data.forEach(resource => {
                let units = resource.units.map((unit, index) => {
                                return <div key={index}>{unit.name}</div>;
                            });
                let meb_functions = resource.meb_functions.map((meb_function, index) => {
                                return <div key={index}>{meb_function.name}</div>;
                            });
                let item = {
                    grade: resource.grade.name,
                    units: (<>{units}{meb_functions}</>),
                    preview: ( resource.thumbnail_path !== null ? (<>
                                    <Zoom zoomMargin={20}>
                                        <img src={`${window.config.downloadserver}/${resource.uuid}/${resource.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: 'auto', height: '30px'}}/>
                                    </Zoom>
                                </>) : ''),
                    updated_at: Moment(resource.updated_at).format('YYYY-MM-DD HH:mm'),
                    published: resource.published ? 'Yayında' : 'Y. Değil',
                    actions: (<>
                                    <MDBBtn outline rounded color='primary' size='sm' className='px-2 mx-1' onClick={() => this.viewResourceButtonClickHandler(resource)}><MDBIcon fas icon='eye' /><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                                    <MDBBtn outline rounded color='success' size='sm' className='px-2 mx-1' onClick={() => this.editResourceButtonClickHandler(resource)}><MDBIcon fas icon='pen' /><span className='d-none d-lg-inline ms-1'>DÜZENLE</span></MDBBtn>
                                    <MDBBtn outline rounded color='danger' size='sm' className='px-2 mx-1' onClick={() => this.onDeleteButtonClickHandler(resource)}><MDBIcon fas icon='trash' /><span className='d-none d-lg-inline ms-1'>SİL</span></MDBBtn>
                            </>)
                };
                rows.push(item);
            });
            this.setState({ rows: rows });
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }

    editResourceButtonClickHandler(resource) {
        this.setState({ isResourceViewerComponentTriggered: false });
        this.setState({ resourceBeingEdited: resource });
        this.setState({ isResourceEditorComponentTriggered: true });
    }

    resourceEditorComponentCloseCallback() {
        this.setState({ isResourceEditorComponentTriggered: false });
        this.setState({ resourceBeingEdited: null });
        this.updateResourceTypeSelectionCombobox();
        this.fetchGradesWithUnits();
        this.loadUnitsFromReduxStore(this.selected_grade_id);
        this.loadMebFunctionsFromReduxStore(this.selected_unit_id);
        this.fetchFilteredResources();
    }

    viewResourceButtonClickHandler(resource) {
        this.setState({ resourceBeingViewed: resource });
        this.setState({ isResourceViewerComponentTriggered: true });
    }

    resourceViewerComponentCloseCallback() {
        this.setState({ isResourceViewerComponentTriggered: false });
        this.setState({ resourceBeingViewed: null });
        this.updateResourceTypeSelectionCombobox();
        this.fetchGradesWithUnits();
        this.loadUnitsFromReduxStore(this.selected_grade_id);
        this.loadMebFunctionsFromReduxStore(this.selected_unit_id);
        this.fetchFilteredResources(); // may be deleted? Need refresh
    }

    addNewResourceButtonClickHandler() {
        this.setState({ isResourceEditorComponentTriggered: true });
    }


    async deleteResource() {
        if (this.state.deleteCandidateResource === null) {
            return;
        }

        
        try {
            let request = { id: this.state.deleteCandidateResource.id };
            this.setState({ isSpinnerWaitShown: true });
            await Resource.deleteResource(request);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Kaynak silindi');
            this.setState({ confirmDeleteShown: false });
            this.setState({ deleteCandidateResource: null });
            await this.fetchFilteredResources();
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }

    onDeleteButtonClickHandler(item) {
        this.setState({ deleteCandidateResource: item });
        this.setState({ confirmDeleteShown: true });
    }

    onDeleteConfirmationButtonClickHandler() {
        this.deleteResource();
    }


    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }


    loadSelectGradeOpenList(all_grades_with_units) {

        let grade_items = [];
        let is_selected = (this.selected_grade_id === -1);
        let option_grade = {
            text: '- Tüm Kademeler -',
            value: '-1',
            defaultSelected: is_selected
        };
        grade_items.push(option_grade);

        let grades = all_grades_with_units;

        grades.forEach(grade => {

            is_selected = (this.selected_grade_id === grade.id);
            option_grade = {
                text: grade.name,
                value: grade.id + '',
                defaultSelected: is_selected
            };

            grade_items.push(option_grade);

        });

        this.setState({ grade_items: grade_items });
    }


    async onGradesComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }


        let selectedItemNumeric = parseInt(selectedItem.value);

        if (selectedItemNumeric === -1) {
            this.selected_grade_id = -1;
        } else {
            this.selected_grade_id = selectedItemNumeric;
        }

        this.loadUnitsFromReduxStore(selectedItemNumeric);


        this.selected_unit_id = -1;

        this.selected_meb_function_id = -1;

    }



    loadUnitsFromReduxStore(selected_grade_id) {
        let unit_items = [];


        let is_selected = (this.selected_unit_id === -1);
        let option_unit = {
            text: '- Tüm Üniteler -',
            value: '-1',
            defaultSelected: is_selected
        };

        unit_items.push(option_unit);

        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {

            if (selected_grade_id === null) {
                return;
            }

            if (grade.id !== selected_grade_id) {
                return;
            }

            let units = grade.units;
            units.forEach(unit => {
                is_selected = (this.selected_unit_id === unit.id);
                option_unit = {
                    text: unit.name + ' - ' + unit.title,
                    value: unit.id + '',
                    defaultSelected: is_selected
                };

                unit_items.push(option_unit);
            });

        });


        this.setState({ unit_items: unit_items });
        
    }

    async onUnitsComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let selectedItemNumeric = parseInt(selectedItem.value);

        if (selectedItemNumeric === -1) {
            this.selected_unit_id = -1;
        } else {
            this.selected_unit_id = selectedItemNumeric;
        }

        this.loadMebFunctionsFromReduxStore(selectedItemNumeric);

        this.selected_meb_function_id = -1;
    }

    loadMebFunctionsFromReduxStore(selected_unit_id) {
        let meb_function_items = [];
        
        let is_selected = (this.selected_unit_id === -1);

        let option_meb_function = {
            text: '- Tüm Kazanımlar -',
            value: '-1',
            defaultSelected: is_selected
        };

        meb_function_items.push(option_meb_function);

        let grades = this.props.all_grades_with_units;

        grades.forEach(grade => {

            let units = grade.units;

            units.forEach(unit => {

                if (selected_unit_id === null) {
                    return;
                }

                if (unit.id !== selected_unit_id) {
                    return;
                }


                let meb_functions = unit.meb_functions;

                meb_functions.forEach(meb_function => {

                    is_selected = (this.selected_meb_function_id === meb_function.id);

                    option_meb_function = {
                        text: meb_function.name,
                        value: meb_function.id + '',
                        defaultSelected: is_selected
                    };

                    meb_function_items.push(option_meb_function);
                });

            });

        });


        this.setState({ meb_function_items: meb_function_items});
    }

    onMebFunctionsComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let selectedItemNumeric = parseInt(selectedItem.value);

        if (selectedItemNumeric === -1) {
            this.selected_meb_function_id = -1;
        } else {
            this.selected_meb_function_id = selectedItemNumeric;
        }
    }

    onResourceTypeComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        if (selectedItem.value === 'all') {
            this.selected_resource_type_code = 'all';
        } else {
            this.selected_resource_type_code = selectedItem.value;
        }
    }


    updateResourceTypeSelectionCombobox() {
        let resource_types = [...this.state.resource_type_items];

        resource_types.forEach(rt => {
            if (rt.value === this.selected_resource_type_code) {
                rt.defaultSelected = true;
            } else {
                rt.defaultSelected = false;
            }
        });

        this.setState({ resource_type_items: resource_types });

    }

    async onFilterClickHandler() {
        await this.fetchFilteredResources();
    }


    render() {

        let unitnamesstr = '';
        if (this.state.deleteCandidateResource !== null) {
            let units = this.state.deleteCandidateResource.units;
            let unitnamesarr = [];
            units.forEach(unit => {
                unitnamesarr.push(`${unit.name} - ${unit.title}`);
            });
            unitnamesstr = unitnamesarr.join(', ');
        }

        let confirmDeleteComponent = (
        <MDBModal size='md' open={this.state.confirmDeleteShown} staticBackdrop>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Etkinlik Silme Onayı</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {this.state.deleteCandidateResource !== null ? (<>
                        <p><strong>Silinecek kaynak:</strong> {this.state.deleteCandidateResource.original_file_name}</p>
                        <p><strong>Kademesi:</strong> {this.state.deleteCandidateResource.grade.name}</p>
                        <p><strong>Üniteleri:</strong> {unitnamesstr}</p></>) : '' }
                        <p>Silme işlemini onaylıyor musunuz?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>);


        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.isResourceEditorComponentTriggered) {
            return (<ResourceActivityEditorComponent menucode={this.props.menucode} closeCallback={this.resourceEditorComponentCloseCallback} resourceBeingEdited={this.state.resourceBeingEdited} />);
        }

        if (this.state.isResourceViewerComponentTriggered) {
            return (<ResourceActivityViewerComponent menucode={this.props.menucode} closeCallback={this.resourceViewerComponentCloseCallback} editorCallBack={this.editResourceButtonClickHandler} resourceBeingViewed={this.state.resourceBeingViewed} />);
        }

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title;

        return (
            <>
                <h3>{title}</h3>
                <p>Öğretmen kaynaklarını ekleme, düzenleme, silme işlemlerini bu bölümde ele alabilirsiniz.</p>
                <div className='row py-2'>
                    <div className='col'>
                        <MDBBtn color='primary' onClick={this.addNewResourceButtonClickHandler}>YENİ KAYNAK EKLE</MDBBtn>
                    </div>
                </div>
                <div className='row py-2'>
                    <h4>Eğitimsel Kaynak Filtreleme Seçenekleri</h4>
                </div>
                <div className='row py-2'>
                    <div className='col'>
                        <MDBSelect
                            id='resource_type'
                            label='Kaynak Türü'
                            data={this.state.resource_type_items}
                            onChange={this.onResourceTypeComboboxSelectHandler}
                        />
                    </div>
                    <div className='col'>
                        <MDBSelect
                            id='grade_ids'
                            label='Sınıf Kademesi'
                            data={this.state.grade_items}
                            onChange={this.onGradesComboboxSelectHandler}
                        />
                    </div>
                </div>
                <div className='row py-2'>
                    <div className='col'>
                        <MDBSelect
                            id='unit_ids'
                            label='Ünite'
                            data={this.state.unit_items}
                            onChange={this.onUnitsComboboxSelectHandler}
                        />
                    </div>
                    <div className='col'>
                        <MDBSelect
                            id='meb_function_ids'
                            label='Kazanım'
                            data={this.state.meb_function_items}
                            onChange={this.onMebFunctionsComboboxSelectHandler}
                        />
                    </div>
                </div>
                <div className='row py-2'>
                    <div className='col'>
                        <MDBBtn color='primary' onClick={this.onFilterClickHandler}>FILTRELE</MDBBtn>
                    </div>
                </div>
                <div className='mt-4 p-4 border rounded'>
                    <MDBDatatable 
                            sm
                            hover
                            fullPagination
                            rowsText='Sayfadaki satır sayısı'
                            data={{columns: this.columns, rows: this.state.rows}} />
                </div>
                {spinnerWaitComponent}
                {confirmDeleteComponent}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAllResourceAvailableFor: (all_resource_available_for) => dispatch({ type: 'storeAllResourceAvailableFor', all_resource_available_for: all_resource_available_for }),
        storeAllGradesWithUnits: (all_grades_with_units) => dispatch({ type: 'storeAllGradesWithUnits', all_grades_with_units: all_grades_with_units })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesActivitiesComponent);