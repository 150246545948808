import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBBtn, 
    MDBCol, 
    MDBInput, 
    MDBRow, 
    MDBCard, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBCardHeader,
    MDBCheckbox,
    MDBValidation,
    MDBAlert,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import DOMPurify from 'dompurify';

import ModalInformationBoxComponent from '../components/ModalInformationBoxComponent';
import User from '../apis/User';
import SpinnerWaitComponent from '../components/SpinnerWaitComponent';
import AlertComponent from '../components/AlertComponent';
import { cloneConfigForFormControl, sanitizePhoneNumber, sanitizeUserName, formatPhoneNumber, sanitizeEmail } from '../common/common';
import axios from 'axios';


class RegistrationFormComponent extends Component {




    constructor(props) {
        super(props);


        this.state = {  isForgotPasswordLinkClicked: false,
                        isForgotUsernameLinkClicked: false,
                        isReturnToLoginPageButtonClicked: false,
                        isHowToObtainAccessCodeModalShown: false,
                        isGDPRDisclaimerModalShown: false,
                        isUserSubmittedSuccessfully: false,
                        isSpinnerWaitShown: false,
                        gdprDisclaimerHTML: '',
                        accessCodeObtainHTML: '',
                        form_controls: {
                            is_user_a_teacher: { value: true, is_invalid: false, message: '', instruction: ''},
                            name: { value: '', is_invalid: false, message: '', instruction: ''},
                            surname: { value: '', is_invalid: false, message: '', instruction: ''},
                            username: { value: '', is_invalid: false, message: '', instruction: ''},
                            access_code: { value: '', is_invalid: false, message: '', instruction: ''},
                            email: { value: '', is_invalid: false, message: '', instruction: ''},
                            mobile_phone: { value: '', is_invalid: false, message: '', instruction: ''},
                            password: { value: '', is_invalid: false, message: '', instruction: ''},
                            password_confirmation: { value: '', is_invalid: false, message: '', instruction: ''},
                            is_gdpr_accepted: { value: false, is_invalid: false, message: '', instruction: ''}
                        },
                        submit_success: false,
                        submit_error: false,
                        alert_header: '',
                        alert_messages: []
                    };

        

        this.forgotPasswordClickHandler = this.forgotPasswordClickHandler.bind(this);
        this.forgotUsernameClickHandler = this.forgotUsernameClickHandler.bind(this);
        this.returnToLoginPageButtonClickHandler = this.returnToLoginPageButtonClickHandler.bind(this);
        this.howToObtainAccessCodeLinkClickHandler = this.howToObtainAccessCodeLinkClickHandler.bind(this);
        this.howToObtainAccessCodeModalToggleHandler = this.howToObtainAccessCodeModalToggleHandler.bind(this);
        this.gdprDisclaimerLinkClickHandler = this.gdprDisclaimerLinkClickHandler.bind(this);
        this.gdprDisclaimerModalToggleHandler = this.gdprDisclaimerModalToggleHandler.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onSignUpButtonClickHandler = this.onSignUpButtonClickHandler.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    }


    forgotPasswordClickHandler(event) {
        this.setState({ isForgotPasswordLinkClicked: true });
    }

    forgotUsernameClickHandler(event) {
        this.setState({ isForgotUsernameLinkClicked: true });
    }

    returnToLoginPageButtonClickHandler(event) {
        this.setState({ isReturnToLoginPageButtonClicked: true });
    }

    async howToObtainAccessCodeLinkClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.accessCodeObtainHTML.length === 0) {
            try {
                this.setState({ isSpinnerWaitShown: true });
                await axios.get('resources/texts/access_code_obtain.html')
                .then(response => {
                    this.setState({accessCodeObtainHTML: DOMPurify.sanitize(response.data, {
                        ALLOWED_TAGS: ["h1", "h2", "h3", "h4", "h5", "p", "span", "b", "strong", "a", "ul", "li"],
                        ALLOWED_ATTR: ["style", "href", "target"],
                    })});
                });    
                this.setState({ isSpinnerWaitShown: false });
                this.setState({ isHowToObtainAccessCodeModalShown: true });
            } catch (error) {
                this.setState({ isSpinnerWaitShown: false });
            }
        } else {
            this.setState({ isHowToObtainAccessCodeModalShown: true });
        }
        
    }

    howToObtainAccessCodeModalToggleHandler() {

        if (!this.state.isHowToObtainAccessCodeModalShown) {
            return;
        }

        this.setState({ isHowToObtainAccessCodeModalShown: false });
    }

    async gdprDisclaimerLinkClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.gdprDisclaimerHTML.length === 0) {
            try {
                this.setState({ isSpinnerWaitShown: true });
                await axios.get('resources/texts/gdpr_disclaimer.html')
                .then(response => {
                    this.setState({gdprDisclaimerHTML: DOMPurify.sanitize(response.data, {
                        ALLOWED_TAGS: ["h1", "h2", "h3", "h4", "h5", "p", "span", "b", "strong", "a"],
                        ALLOWED_ATTR: ["style"],
                    })});
                });        
                this.setState({ isSpinnerWaitShown: false });
                this.setState({ isGDPRDisclaimerModalShown: true });
            } catch (error) {
                this.setState({ isSpinnerWaitShown: false });
            }
        } else {
            this.setState({ isGDPRDisclaimerModalShown: true });
        }
        
    }

    gdprDisclaimerModalToggleHandler() {
        if (!this.state.isGDPRDisclaimerModalShown) {
            return;
        }

        this.setState({ isGDPRDisclaimerModalShown: false });
    }

    /*
     * formItemOnChangeHandler - responsible to copy up to date changed values of form items to component state
     */
    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;
        let oldValue = event.target.defaultValue;

        let form_controls_copy = { ...this.state.form_controls };        
        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference

        if (key === 'is_gdpr_accepted') {
            value = event.target.checked;
        } else if (key === 'is_user_a_teacher') {
            value = event.target.checked;
            if (value === false) { // empty previous access_code value within state
                let tmp = { ...this.state.form_controls['access_code'] };
                tmp.value = '';
                form_controls_copy['access_code'] = tmp;
            }
        } else if (key === 'username') {
            value = sanitizeUserName(value);
        } else if (key === 'email') {
            value = sanitizeEmail(value);
        } else if (key === 'mobile_phone') {
            let numericValue = sanitizePhoneNumber(value);
            if (oldValue.length > value.length) {
                oldValue = sanitizePhoneNumber(oldValue);
                if (numericValue.length === oldValue.length && numericValue.length > 0) {
                    numericValue = numericValue.substring(0, numericValue.length - 1);
                }
            }
            value = numericValue.substring(0, 10);
            form_control_copy.value = value;
            form_control_copy.is_invalid = false;
            form_control_copy.message = '';
            form_controls_copy[key] = form_control_copy; // all controls
        }
        

        form_control_copy.value = value;
        form_control_copy.is_invalid = false;
        form_control_copy.message = '';
        form_controls_copy[key] = form_control_copy; // all controls
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }


    onSignUpButtonClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        this.submitNewUserInfo();
    }


    async submitNewUserInfo() {

        this.setState({ submit_success: false });
        this.setState({ submit_error: false });
        this.setState({ alert_header: '' });
        this.setState({ alert_messages: '' });
        this.setState({ isSpinnerWaitShown: true });

        // prepare form items for submission
        let form_control_names = Object.keys(this.state.form_controls);
        let form_items = {};
        form_control_names.forEach(key => {
            form_items[key] = this.state.form_controls[key].value;
        });

        try {
            let response = await User.register(form_items);

            let form_controls_copy = { ...this.state.form_controls };

            form_control_names.forEach(key => {
                form_controls_copy[key] = cloneConfigForFormControl(key, [], form_controls_copy);
            });
            this.setState({ form_controls: form_controls_copy });

            this.setState({ submit_success: true });
            this.setState({ submit_error: false });
            this.setState({ alert_header: 'Success' });
            this.setState({ alert_messages: response.data.message });
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ isUserSubmittedSuccessfully: true } );

        } catch (error) {
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_control_names.forEach(key => {
                    form_controls_copy[key] = cloneConfigForFormControl(key, errors, form_controls_copy);
                });

                this.setState({ form_controls: form_controls_copy });
                this.setState({ submit_success: false });
                this.setState({ submit_error: false });

                if (errors.error !== undefined) { // handle global algoritmical errors
                    this.setState({ alert_header: error.response.data.message });
                    this.setState({ alert_messages: errors.error });
                    this.setState({ submit_success: false });
                    this.setState({ submit_error: true });
                }
            } else {
                // handle catastrophic errors
                this.setState({ alert_header: 'Beklenmeyen Hata Oluştu' });
                this.setState({ alert_messages: error.toString() });
                this.setState({ submit_success: false });
                this.setState({ submit_error: true });
            }
            this.setState({ isSpinnerWaitShown: false });
        }

    };

    formatPhoneNumber(number) {
        return formatPhoneNumber(number);
    }


    render() {
        if (this.props.authenticatedUser !== null) {
            return (<Navigate to='/' />);
        }

        if (this.state.isForgotPasswordLinkClicked) {
            return (<Navigate to='/forgotpassword' />);
        }

        if (this.state.isForgotUsernameLinkClicked) {
            return (<Navigate to='/forgotusername' />);
        }
        
        if (this.state.isReturnToLoginPageButtonClicked) {
            return (<Navigate to='/login' />);
        }

        if (this.state.isUserSubmittedSuccessfully) {
            return (
                <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                    <div className='col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7'>
                        <MDBCard>
                            <MDBCardHeader>
                                <h5>TEAM LMS - {this.state.form_controls.is_user_a_teacher.value === true ? 'Öğretmen' : 'Öğrenci'} Hesabı Oluşturma Başarılı</h5>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBAlert open color='success' className='w-100'>
                                    <p className='lh-sm fw-light'><strong>{this.state.form_controls.username.value}</strong> kullanıcısı başarıyla oluşturuldu.</p>
                                </MDBAlert>
                                <MDBRow>
                                    <MDBCol>
                                        <p>Size ait olan <strong>{this.state.form_controls.email.value}</strong> e-posta adresine hesap doğrulama linki gönderildi. 
                                        Lütfen e-posta kutunuzu birkaç dakika sonra kontrol ediniz ve size iletmiş olduğumuz e-postada yazan yönergeleri takip ederek yeni oluşturulmuş olan hesabınızın size ait olduğunu doğrulayınız.</p>
                                        <p><strong className='text-danger'>ÖNEMLİ: </strong>Hesap doğrulama işlemini 60 dakika içinde tamamlamazsanız hesabınız otomatik olarak silinecek.</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBBtn color='primary' onClick={this.returnToLoginPageButtonClickHandler}>OTURUM AÇMA SAYFASINA GİT</MDBBtn>
                            </MDBCardFooter>
                        </MDBCard>
                    </div>
                </div>
            );
        }

        let teacherAccessCodeControl = '';
        if (this.state.form_controls.is_user_a_teacher.value === true) {
            teacherAccessCodeControl = (
                <MDBValidationItem feedback={this.state.form_controls.access_code.message} invalid>
                    <MDBInput
                        type='text'
                        id='access_code'
                        label="Öğretmen erişim kodu"
                        autoComplete='off'
                        required
                        onChange={this.formItemOnChangeHandler}
                        className={this.state.form_controls.access_code.is_invalid ? 'is-invalid':''}                        
                    />
                </MDBValidationItem>
            );
        }


        let modalGDPRDisclaimer = (
            <ModalInformationBoxComponent
                title='KVKK Aydınlatma Metni'
                showModal={this.state.isGDPRDisclaimerModalShown}
                toggleHandler={this.gdprDisclaimerModalToggleHandler} >
                    <div dangerouslySetInnerHTML={{__html: this.state.gdprDisclaimerHTML}}></div>
            </ModalInformationBoxComponent>
        );

        let modalHowToObtainAccessCode = (
            <ModalInformationBoxComponent
                title="Öğretmen Erişim Kodunu Nasıl Temin Edebilirim?"
                showModal={this.state.isHowToObtainAccessCodeModalShown}
                toggleHandler={this.howToObtainAccessCodeModalToggleHandler} >
                    <div dangerouslySetInnerHTML={{__html: this.state.accessCodeObtainHTML}}></div>
            </ModalInformationBoxComponent>
        );

        let errorMessageComponent = '';
        if (this.state.alert_header !== '') {
            errorMessageComponent = (<AlertComponent 
                                        type='danger'
                                        alert_header={this.state.alert_header} 
                                        alert_messages={this.state.alert_messages} />
                                    );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let formattedPhoneNumber = this.formatPhoneNumber(this.state.form_controls.mobile_phone.value);

        return(
            <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                <div className='col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7'>
                    <MDBCard>
                        <MDBValidation noValidate>
                        <MDBCardHeader>
                            <h5>TEAM LMS - {this.state.form_controls.is_user_a_teacher.value === true ? 'Öğretmen' : 'Öğrenci'} Olarak Hesap Oluştur</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            {errorMessageComponent}
                            <MDBRow>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBCheckbox value='' id='is_user_a_teacher' label='Ben bir öğretmenim' defaultChecked disabled
                                        onChange={this.formItemOnChangeHandler}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.name.message} invalid>
                                        <MDBInput type='text' id='name' label='Ad' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.name.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.surname.message} invalid>
                                        <MDBInput type='text' id='surname' label='Soyad' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler} 
                                            className={this.state.form_controls.surname.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.username.message} invalid>
                                        <MDBInput type='text' id='username' label='Kullanıcı adı' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler}
                                            value={this.state.form_controls.username.value} 
                                            className={this.state.form_controls.username.is_invalid ? 'is-invalid':''}                                        
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    {teacherAccessCodeControl}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.email.message} invalid>
                                        <MDBInput type='text' id='email' label='E-posta' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler}
                                            value={this.state.form_controls.email.value} 
                                            className={this.state.form_controls.email.is_invalid ? 'is-invalid':''} 
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.mobile_phone.message} invalid>
                                        <MDBInput type='text' id='mobile_phone' label='Cep telefonu - (500) 123 45 67' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler}
                                            value={formattedPhoneNumber} 
                                            className={this.state.form_controls.mobile_phone.is_invalid ? 'is-invalid':''} 
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.password.message} invalid>
                                        <MDBInput type='password' id='password' label='Şifre - en az 8 karakter' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler} 
                                            className={this.state.form_controls.password.is_invalid ? 'is-invalid':''} 
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.password_confirmation.message} invalid>
                                        <MDBInput type='password' id='password_confirmation' label='Şifre doğrulama' autoComplete='off' required 
                                            onChange={this.formItemOnChangeHandler} 
                                            className={this.state.form_controls.password_confirmation.is_invalid ? 'is-invalid':''} 
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBBtn color='link' onClick={this.gdprDisclaimerLinkClickHandler}>KVKK AYDINLATMA METNİNİ OKU</MDBBtn>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBBtn color='link' onClick={this.howToObtainAccessCodeLinkClickHandler}>ÖĞRETMEN ERİŞİM KODU TEMİNİ?</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.is_gdpr_accepted.message} invalid>
                                        <MDBCheckbox name='flexCheck' value='' id='is_gdpr_accepted' label='KVKK kapsamında kişisel verilerimin işlenmesini kabul ediyorum' 
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.is_gdpr_accepted.is_invalid ? 'is-invalid':''}                                 
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' className='me-3' onClick={this.onSignUpButtonClickHandler}>{this.state.form_controls.is_user_a_teacher.value === true ? 'ÖĞRETMEN' : 'ÖĞRENCİ'} HESABI OLUŞTUR</MDBBtn>
                            <MDBBtn color='secondary' onClick={this.returnToLoginPageButtonClickHandler}>OTURUM AÇ SAYFASINA GİT</MDBBtn>
                        </MDBCardFooter>
                        </MDBValidation>
                        {modalGDPRDisclaimer}
                        {modalHowToObtainAccessCode}
                        {spinnerWaitComponent}
                    </MDBCard>
                    <div className='d-flex justify-content-center mt-3'>
                        <MDBBtn color='link' onClick={this.forgotPasswordClickHandler}>ŞİFREMİ UNUTTUM</MDBBtn>
                        <MDBBtn color='link' onClick={this.forgotUsernameClickHandler}>KULLANICI ADIMI UNUTTUM</MDBBtn>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};


export default connect(mapStateToProps)(RegistrationFormComponent);
