import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBValidation } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import 'react-medium-image-zoom/dist/styles.css';
import QuizMakerPreviewQuizColumnComponent from './QuizMakerPreviewQuizColumnComponent';

class QuizMakerPreviewQuizComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            pages: [],
            quizName: '',
            quizDescription: ''
        };


        this.onChangeQuizName = this.onChangeQuizName.bind(this);
        this.onChangeQuizDescription = this.onChangeQuizDescription.bind(this);
    }

    componentDidMount() {
        let pages = [];
        let page = [];
        for (let i = 0; i < this.props.previewColumns.length; i++) {
            page.push(this.props.previewColumns[i]);
            
            if (i % 2 === 0) {
                pages.push(page);
            }

            if (i % 2 === 1) {
                page = [];
            }
        }

        this.setState({pages: pages});
    }

    onChangeQuizName(event) {
        
        this.setState( { quizName: event.target.value } );
    }

    onChangeQuizDescription(event) {
        this.setState( { quizDescription: event.target.value } );
    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }



        return (

            <div>
                <MDBCard>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>Sınav Üreteci - Sınav Önizlemesi</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody className='p-4'>
                            <div className='form-outline row'>
                                <div className='col-auto'>
                                    <strong>SINIF:</strong> {this.props.previewQuiz.grade.name}
                                </div>
                            </div>
                            <div className='form-outline row'>
                                <div className='col-auto'>
                                    <strong>SINAV ADI:</strong> {this.props.previewQuiz.name}
                                </div>
                            </div>
                            <div className='form-outline row mb-4'>
                                <div className='col-auto'>
                                    <strong>AÇIKLAMA:</strong> {this.props.previewQuiz.description}
                                </div>
                            </div>
                            <div className='form-outline row'>
                                <div className='col-auto'>
                                    {this.state.pages.map((page, index) => {
                                        if (page.length === 2) {
                                            let leftColumnComponent = (<QuizMakerPreviewQuizColumnComponent column={page[0]} rightBorder />);
                                            let rightColumnComponent = (<QuizMakerPreviewQuizColumnComponent column={page[1]} />);
                                            return (<div key={index} className='row border mb-2 pt-4'>
                                                        <div className='col'>
                                                            <div className='row'>
                                                                {leftColumnComponent}
                                                                {rightColumnComponent}
                                                            </div>
                                                            <div className='row text-center'>
                                                                <p className='text-center'>- Sayfa {index + 1} -</p>
                                                            </div>
                                                        </div>
                                                    </div>);
                                        } else {
                                            let leftColumnComponent = (<QuizMakerPreviewQuizColumnComponent column={page[0]} rightBorder/>);
                                            return (<div key={index} className='row border pt-4'>
                                                        <div className='col'>
                                                            <div className='row'>
                                                                {leftColumnComponent}
                                                            </div>
                                                            <div className='row text-center'>
                                                                <p className='text-center'>- Sayfa {index + 1} -</p>
                                                            </div>
                                                        </div>
                                                    </div>);
                                        }
                                    })}
                                </div>
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' className='me-2' onClick={() => this.props.downloadQuizCallback(this.props.previewQuiz)}>SINAVI İNDİR</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.props.closeCallback}>KAPAT</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(QuizMakerPreviewQuizComponent);