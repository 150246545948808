import React, { Component } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBBtn, 
    MDBCol, 
    MDBInput, 
    MDBRow, 
    MDBCard, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBCardHeader,
    MDBValidation,
    MDBAlert,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import SpinnerWaitComponent from '../components/SpinnerWaitComponent';
import queryString from 'query-string';
import User from '../apis/User';
import AlertComponent from '../components/AlertComponent';

import { cloneConfigForFormControl } from '../common/common';



function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}



class ResetPasswordFormComponent extends Component {

    constructor(props) {
        super(props);

        this.state = { 
                    isReturnToLoginPageButtonClicked: false,
                    isSpinnerWaitShown: false,
                    responseMessageHeader: '',
                    responseMessages: [],
                    isPasswordChangedSuccessfully: false,
                    form_controls: {
                        password: { value: '', is_invalid: false, message: '', instruction: ''},
                        password_confirmation: { value: '', is_invalid: false, message: '', instruction: ''}
                    } 
                };

        this.returnToLoginPageButtonClickHandler = this.returnToLoginPageButtonClickHandler.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onPasswordChangeButtonClicked = this.onPasswordChangeButtonClicked.bind(this);

    }

    returnToLoginPageButtonClickHandler(event) {
        this.setState({isReturnToLoginPageButtonClicked: true});
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let form_controls_copy = { ...this.state.form_controls };        
        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        form_control_copy.value = value;
        form_controls_copy[key] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
    }


    onPasswordChangeButtonClicked(event) {
        event.stopPropagation();
        event.preventDefault();

        this.passwordChangeRequest();
    }

    async passwordChangeRequest() {
        this.setState({ responseMessageHeader : '' });
        this.setState({ responseMessages : [] });

        let form_control_names = Object.keys(this.state.form_controls);

        let params = queryString.parse(this.props.router.location.search);
        let email = params.email;
        let token = params.token;

        if (email === undefined || token === undefined) {
            this.setState({ responseMessageHeader : 'Parametreler Eksik' });
            let messages = [];
            messages.push('Bu formu e-postanıza gönderilen şifre resetleme talimatlarındaki linke tıklayarak yeniden açınız.');
            this.setState({ responseMessages : messages });
            return;
        }

        try {
            let form_controls_copy = { ...this.state.form_controls };

            form_control_names.forEach(key => {
                form_controls_copy[key] = cloneConfigForFormControl(key, [], form_controls_copy);
            });
            this.setState({ form_controls: form_controls_copy });

            let form_items = {
                password: this.state.form_controls.password.value,
                password_confirmation: this.state.form_controls.password_confirmation.value,
                email: email,
                token: token
            };
            this.setState({ isSpinnerWaitShown: true });
            await User.reset_password_request(form_items);
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ isPasswordChangedSuccessfully: true });
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.status === 500) {
                this.setState({ responseMessageHeader : 'Beklenmeyen Hata' });
                let messages = [];
                messages.push('Beklenmedik bir hata oluştu, daha sonra tekrar deneyiniz.');
                this.setState({ responseMessages : messages });
                this.setState({ isUsernameSent: false });
                return;    
            } else if (error.response.data.errors !== undefined) {

                if (error.response.data.errors.email !== undefined) { // it is sent as an email for token invalid or expired
                    this.setState({ responseMessageHeader : error.response.data.message });
                    let messages = [];
                    messages.push(error.response.data.errors.email[0]);
                    this.setState({ responseMessages : messages });
                    this.setState({ isUsernameSent: false });
                    return;    
                } else {
                    let errors = error.response.data.errors;
                    let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                    form_control_names.forEach(key => {
                        form_controls_copy[key] = cloneConfigForFormControl(key, errors, form_controls_copy);
                    });
    
                    this.setState({ form_controls: form_controls_copy });
                }

            }
        }
    }

    render() {

        if (this.props.authenticatedUser !== null) {
            return (<Navigate to='/' />);
        }
        
        if (this.state.isReturnToLoginPageButtonClicked) {
            return (<Navigate to='/login' />);
        }

        let alertComponent = '';
        if (this.state.responseMessageHeader.length > 0) {
            alertComponent = (<div className='form-group'>
                <AlertComponent alert_messages={this.state.responseMessages}
                                alert_header={this.state.responseMessageHeader} />
                </div>
            );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }


        if (this.state.isPasswordChangedSuccessfully) {
            return (
                <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                    <div className='col-12 col-sm-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5'>
                        <MDBCard>
                            <MDBCardHeader>
                                <h5>TEAM LMS - Şifreniz Değiştirildi</h5>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBAlert open color='success' className='w-100'>
                                    <h6>Şifreniz Başarıyla Değiştirildi</h6>
                                    <p className='lh-sm fw-light'>Sistemde kayıtlı şifreniz az önce değiştirilmiştir. Yeni girişleri yeni belirlemiş olduğunuz şifre ile gerçekleştirebilirsiniz.</p>
                                </MDBAlert>
                                <MDBRow>
                                    <MDBCol>
                                        <p><strong>OTURUM AÇMA EKRANINA GİT</strong> düğmesine basarak oturum açma ekranına geçip yeni şifrenizle oturumunuz açabilirsiniz.</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBBtn color='primary' onClick={this.returnToLoginPageButtonClickHandler}>OTURUM AÇMA EKRANINA GİT</MDBBtn>
                            </MDBCardFooter>
                        </MDBCard>
                    </div>
                </div>
            );
        }

        return(
            <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                <div className='col-12 col-sm-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5'>
                    <MDBCard>
                        <MDBValidation noValidate>
                        <MDBCardHeader>
                            <h5>TEAM LMS - Şifre Belirleme</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBAlert open color='warning' className='w-100'>
                                <h6>Yeni şifre belirleme kuralları</h6>
                                <p className='lh-sm fw-light'>Yeni şifreniz en az 8 karakter uzunluğunda olmalıdır.</p>
                            </MDBAlert>
                            {alertComponent}
                            <MDBRow>
                                <MDBCol className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.password.message} invalid>
                                        <MDBInput type='password'
                                            id='password'
                                            label='Yeni şifre'
                                            autoComplete='off'
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.password.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBValidationItem feedback={this.state.form_controls.password_confirmation.message} invalid>
                                        <MDBInput type='password'
                                            id='password_confirmation'
                                            label='Yeni şifre doğrulama'
                                            autoComplete='off'
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.password_confirmation.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn type='submit' color='primary' className='me-3' onClick={this.onPasswordChangeButtonClicked}>ŞİFREYİ DEĞİŞTİR</MDBBtn>
                            <MDBBtn color='secondary' onClick={this.returnToLoginPageButtonClickHandler}>OTURUM AÇMA EKRANINA GİT</MDBBtn>
                        </MDBCardFooter>
                        </MDBValidation>
                        {spinnerWaitComponent}
                    </MDBCard>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};


export default connect(mapStateToProps)(withRouter(ResetPasswordFormComponent));
