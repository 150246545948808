import { Component } from 'react';
import { connect } from 'react-redux';
import { 
    MDBBtn, 
    MDBCard, 
    MDBCardHeader, 
    MDBCardTitle, 
    MDBCardBody, 
    MDBSelect, 
    MDBInput, 
    MDBCardFooter, 
    MDBRow, 
    MDBCol, 
    MDBValidation,
    MDBCheckbox,
    MDBValidationItem} from 'mdb-react-ui-kit';
import moment from 'moment';
import Province from '../../../apis/Province';
import User from '../../../apis/User';
import { cloneConfigForFormControl, formatPhoneNumber, mainpages, sanitizePhoneNumber, sanitizeUserName } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import UserAttributesComponent from './UserAttributesComponent';
import UserSetPasswordComponent from './UserSetPasswordComponent';

class AdminUserEditorComponent extends Component {
    constructor(props) {
        super(props);

        let province_id = this.props.userBeingEdited.province_id;
        if (province_id === undefined || province_id === null) {
            province_id = null;
        }
        let town_id = this.props.userBeingEdited.town_id;
        if (town_id === undefined || town_id === null) {
            town_id = null;
        }

        let school_name = this.props.userBeingEdited.school_name === null ? '' : this.props.userBeingEdited.school_name;
        let disable_reason = this.props.userBeingEdited.disable_reason === null ? '' : this.props.userBeingEdited.disable_reason;

        this.state = {
            isSpinnerWaitShown: false,
            isModalUserAttributesShown: false,
            isModalUserSetPasswordShown: false,
            provinces: [],
            towns: [],
            form_controls: {
                name: { value: this.props.userBeingEdited.name, is_invalid: false, message: '', instruction: '' },
                surname: { value: this.props.userBeingEdited.surname, is_invalid: false, message: '', instruction: '' },
                email: { value: this.props.userBeingEdited.email, is_invalid: false, message: '', instruction: '' },
                mobile_phone: { value: this.props.userBeingEdited.mobile_phone, is_invalid: false, message: '', instruction: '' },
                username: { value: this.props.userBeingEdited.username, is_invalid: false, message: '', instruction: '' },
                is_verified: { value: this.props.userBeingEdited.is_verified === 1 ? true : false, is_invalid: false, message: '', instruction: '' },
                school_name: { value: school_name, is_invalid: false, message: '', instruction: '' },
                is_disabled: { value: this.props.userBeingEdited.is_disabled === 1 ? true : false, is_invalid: false, message: '', instruction: '' },
                disable_reason: { value: disable_reason, is_invalid: false, message: '', instruction: '' },
                province_id: { value: province_id, is_invalid: false, message: '', instruction: ''},
                town_id: { value: town_id, is_invalid: false, message: '', instruction: ''}
            },
            submit_success: false,
            submit_error: false
        };

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onSaveButtonClickHandler = this.onSaveButtonClickHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
        this.onProvinceComboboxSelectHandler = this.onProvinceComboboxSelectHandler.bind(this);
        this.onTownComboboxSelectHandler = this.onTownComboboxSelectHandler.bind(this);
        this.onUserAttributesButtonClickHandler = this.onUserAttributesButtonClickHandler.bind(this);
        this.onUserSetPasswordButtonClickHandler = this.onUserSetPasswordButtonClickHandler.bind(this);
        this.toggleUserAttributesModalHandler = this.toggleUserAttributesModalHandler.bind(this);
        this.toggleUserSetPasswordModalHandler = this.toggleUserSetPasswordModalHandler.bind(this);

    }

    componentDidMount() {

        this.fetchProvinces();
        if (this.props.userBeingEdited.province_id !== null) {
            this.fetchTowns(this.props.userBeingEdited.province_id );
        }

    }


    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;
        let oldValue = event.target.defaultValue;

        if (key === 'is_disabled') {
            value = event.target.checked;
        } else if (key === 'is_verified') {
            value = event.target.checked;
        } else if (key === 'username') {
            value = sanitizeUserName(value);
        } else if (key === 'mobile_phone') {
            let numericValue = sanitizePhoneNumber(value);
            if (oldValue.length > value.length) {
                oldValue = sanitizePhoneNumber(oldValue);
                if (numericValue.length === oldValue.length && numericValue.length > 0) {
                    numericValue = numericValue.substring(0, numericValue.length - 1);
                }
            }
            value = numericValue.substring(0, 10);
        }

        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        form_control_copy.value = value;
        let form_controls_copy = { ...this.state.form_controls};
        form_control_copy.is_invalid = false;
        form_control_copy.message = '';
        form_controls_copy[key] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }


    async fetchProvinces() {
        if (this.state.provinces.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });

        try {
            let response = await Province.fetchAll();
            let provinces = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let selected = this.props.userBeingEdited.province_id === item.id ? true : false;
                let option = { text: item.province_name, 
                               value: strId,
                               defaultSelected: selected
                               };
                provinces.push(option);
            });
            this.setState( { provinces: provinces } );
        } catch(error) {
            if (error.response !== undefined && error.response.status === 401) {
                this.setState({ isSpinnerWaitShown: false });
                return;
            }
        }

        this.setState({ isSpinnerWaitShown: false });
    }

    onProvinceComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.province_id };
        let selectedItemNumeric;
        selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.province_id = form_control;
        this.setState({ form_controls: form_controls });

        this.fetchTowns(selectedItemNumeric);
        
    }

    async fetchTowns(province_id) {
        this.setState({ isSpinnerWaitShown: true });

        try {
            let request = { id: province_id };
            let response = await Province.fetchTownByProvinceId(request);
            let towns = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let selected = this.props.userBeingEdited.town_id === item.id ? true : false;
                let option = { text: item.town_name, 
                               value: strId,
                               defaultSelected: selected
                             };
                towns.push(option);
            });
            this.setState( { towns: towns } );
        } catch(error) {
            if (error.response !== undefined && error.response.status === 401) {
                this.setState({ isSpinnerWaitShown: false });
                return;
            }
        }

        this.setState({ isSpinnerWaitShown: false });
    }

    onTownComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }
        let selectedItemNumeric;
        selectedItemNumeric = parseInt(selectedItem.value);
        let form_control = { ...this.state.form_controls.town_id };

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.town_id = form_control;
        this.setState({ form_controls: form_controls });
    }


    onSaveButtonClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        let form_items = {
            id: this.props.userBeingEdited.id,
            name: this.state.form_controls.name.value,
            surname: this.state.form_controls.surname.value,
            username: this.state.form_controls.username.value,
            email: this.state.form_controls.email.value,
            mobile_phone: this.state.form_controls.mobile_phone.value,
            is_verified: this.state.form_controls.is_verified.value,
            school_name: this.state.form_controls.school_name.value,
            province_id: this.state.form_controls.province_id.value,
            town_id: this.state.form_controls.town_id.value,
            is_disabled: this.state.form_controls.is_disabled.value,
            disable_reason: this.state.form_controls.disable_reason.value
        };

        this.postUser(form_items);
        
    }

    async postUser(form_items) {
        this.setState({ isSpinnerWaitShown: true });

        let form_control_names = Object.keys(this.state.form_controls);

        try {
            await User.edit_user(form_items);
            
            let form_controls_copy = { ...this.state.form_controls };

            form_control_names.forEach(key => {
                form_controls_copy[key] = cloneConfigForFormControl(key, [], form_controls_copy);
            });

            this.props.showToastNotification('success', 'Kullanıcı bilgileri kaydedildi');
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();

        } catch (error) {
            this.props.showToastNotification('error', 'Kullanıcı bilgileri kaydedilirken hata oluştu');
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_control_names.forEach(key => {
                    form_controls_copy[key] = cloneConfigForFormControl(key, errors, form_controls_copy);
                });

                this.setState({ form_controls: form_controls_copy });
            }
            this.setState({ isSpinnerWaitShown: false });
        }
       
        
    }

    cancelButtonClickHandler() {
        this.props.closeCallback();
    }

    formatPhoneNumber(phone) {
        return formatPhoneNumber(phone);
    }


    onUserAttributesButtonClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        this.toggleUserAttributesModalHandler();
    }

    onUserSetPasswordButtonClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        this.toggleUserSetPasswordModalHandler();
    }

    toggleUserAttributesModalHandler() {
        this.setState({ isModalUserAttributesShown: !this.state.isModalUserAttributesShown });
    }


    toggleUserSetPasswordModalHandler() {
        this.setState({ isModalUserSetPasswordShown: !this.state.isModalUserSetPasswordShown });
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        if (mainpageitem.subtype === 'admins') {
            title = 'Yönetici Hesabı Düzenleme';
        } else if (mainpageitem.subtype === 'teachers') {
            title = 'Öğretmen Hesabı Düzenleme';
        } else if (mainpageitem.subtype === 'students') {
            title = 'Öğrenci Hesabı Düzenleme';
        }

        let formattedPhoneNumber = this.formatPhoneNumber(this.state.form_controls.mobile_phone.value);


        let userAttributesModal = (<UserAttributesComponent 
                            user={this.props.userBeingEdited}
                            showModal={this.state.isModalUserAttributesShown} 
                            toggleHandler={this.toggleUserAttributesModalHandler} 
                            />);

        let userSetPasswordModal = (<UserSetPasswordComponent 
                            user={this.props.userBeingEdited}
                            showModal={this.state.isModalUserSetPasswordShown} 
                            toggleHandler={this.toggleUserSetPasswordModalHandler} 
                            />);
        return (
            <>
            <MDBCard>
                <MDBValidation noValidate>
                <MDBCardHeader>
                    <MDBCardTitle>{title}</MDBCardTitle>
                    
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.name.message} invalid>
                                <MDBInput id='name'
                                    type='text'
                                    label='Ad'
                                    onChange={this.formItemOnChangeHandler}
                                    value={this.state.form_controls.name.value}
                                    className={this.state.form_controls.name.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.surname.message} invalid>
                                <MDBInput 
                                    id='surname'
                                    label='Soyad'
                                    type='text'
                                    onChange={this.formItemOnChangeHandler}
                                    value={this.state.form_controls.surname.value}
                                    className={this.state.form_controls.surname.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.email.message} invalid>
                                <MDBInput 
                                    id='email'
                                    label='E-posta'
                                    type='text'
                                    onChange={this.formItemOnChangeHandler}
                                    value={this.state.form_controls.email.value}
                                    className={this.state.form_controls.email.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.mobile_phone.message} invalid>
                                <MDBInput 
                                    id='mobile_phone'
                                    label='Cep telefonu - (500) 123 45 67'
                                    type='text'
                                    onChange={this.formItemOnChangeHandler}
                                    value={formattedPhoneNumber}
                                    className={this.state.form_controls.mobile_phone.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.username.message} invalid>
                                <MDBInput 
                                    id='username'
                                    label='Kullanıcı adı'
                                    type='text'
                                    onChange={this.formItemOnChangeHandler}
                                    value={this.state.form_controls.username.value}
                                    className={this.state.form_controls.username.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBInput 
                                id='gdpr_accepted_at'
                                label='KVKK kabul zamanı'
                                type='text'
                                value={moment(this.props.userBeingEdited.gdpr_accepted_at).format('YYYY-MM-DD HH:mm')}
                                disabled
                                readonly
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3 d-flex align-items-center'>
                            <MDBValidationItem feedback={this.state.form_controls.is_verified.message} invalid>
                                <MDBCheckbox 
                                    id='is_verified' 
                                    label='Hesabı etkinleştir' 
                                    checked={this.state.form_controls.is_verified.value}
                                    onChange={this.formItemOnChangeHandler}
                                    className={this.state.form_controls.is_verified.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.school_name.message} invalid>
                                <MDBInput 
                                    id='school_name'
                                    label='Okul adı'
                                    type='text'
                                    onChange={this.formItemOnChangeHandler}
                                    value={this.state.form_controls.school_name.value}
                                    className={this.state.form_controls.school_name.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.province_id.message} invalid>
                                <MDBSelect
                                    id='province_id'
                                    label='İl'
                                    search
                                    searchLabel='İl Ara'
                                    onChange={this.onProvinceComboboxSelectHandler}
                                    data={this.state.provinces}
                                    className={this.state.form_controls.province_id.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.town_id.message} invalid>
                            <MDBSelect
                                id='town_id'
                                label='İlçe'
                                search
                                searchLabel='İlçe Ara'
                                onChange={this.onTownComboboxSelectHandler}
                                data={this.state.towns}
                                className={this.state.form_controls.town_id.is_invalid ? 'is-invalid':''}
                            />
                            </MDBValidationItem>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3'>
                            <MDBInput 
                                id='created_at'
                                label='Hesap oluşturulma zamanı'
                                type='text'
                                value={moment(this.props.userBeingEdited.created_at).format('YYYY-MM-DD HH:mm')}
                                disabled
                                readonly
                            />
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBInput 
                                id='updated_at'
                                label='Hesap güncelleme zamanı'
                                type='text'
                                value={moment(this.props.userBeingEdited.updated_at).format('YYYY-MM-DD HH:mm')}
                                disabled
                                readonly
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md='6' className='mb-3 d-flex align-items-center'>
                            <MDBValidationItem feedback={this.state.form_controls.is_disabled.message} invalid>
                                <MDBCheckbox 
                                    id='is_disabled' 
                                    label='Hesabı engelle' 
                                    checked={this.state.form_controls.is_disabled.value}
                                    onChange={this.formItemOnChangeHandler}
                                    className={this.state.form_controls.is_disabled.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <MDBValidationItem feedback={this.state.form_controls.disable_reason.message} invalid>
                                <MDBInput 
                                    id='disable_reason'
                                    label='Hesap engelleme açıklaması'
                                    type='text'
                                    onChange={this.formItemOnChangeHandler}
                                    value={this.state.form_controls.disable_reason.value}
                                    className={this.state.form_controls.disable_reason.is_invalid ? 'is-invalid':''}
                                />
                            </MDBValidationItem>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
                <MDBCardFooter>
                    <MDBBtn color='primary' className='me-3' onClick={this.onSaveButtonClickHandler}>KAYDET</MDBBtn>
                    {mainpageitem.subtype === 'teachers' ?
                        <MDBBtn color='info' className='me-3' onClick={this.onUserAttributesButtonClickHandler}>ÖZELLİKLER</MDBBtn> : ''
                    }
                    <MDBBtn color='danger' className='me-3' onClick={this.onUserSetPasswordButtonClickHandler}>ŞİFRE ATA</MDBBtn>
                    <MDBBtn color='secondary' className='me-3' onClick={this.cancelButtonClickHandler}>KAPAT</MDBBtn>
                </MDBCardFooter>
                </MDBValidation>
            </MDBCard>
            {userAttributesModal}
            {userSetPasswordModal}
            {spinnerWaitComponent}
        </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
    }
};

export default connect(mapStateToProps)(AdminUserEditorComponent);
