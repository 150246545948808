import Api from './Api';
import Csrf from './Csrf';


const Province = {
    async fetchAll() {
        await Csrf.getCookie();
        return Api.get('/provinces');
    },

    async searchProvince(form_items) {
        await Csrf.getCookie();
        return Api.post('/provinces/search', form_items);
    },

    async fetchTownByProvinceId(form_items) {
        await Csrf.getCookie();
        return Api.post('/towns/fetch', form_items);
    }

};

export default Province;