import { Component } from 'react';
import { connect } from 'react-redux';

class TeacherExamsComponent extends Component {

    render() {
        return (
            <div>
                <p>{this.props.authenticatedUser.name} {this.props.authenticatedUser.surname} create online interactive exams.</p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(TeacherExamsComponent);