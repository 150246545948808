import { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { MDBToast } from 'mdb-react-ui-kit';
import Moment from 'moment';


import RootPageStructureContainer from './RootPageStructureContainer';


class AuthenticatedRootPageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stackingToasts: []
        };

        this.showToastNotification = this.showToastNotification.bind(this);
        this.closeToastMessage = this.closeToastMessage.bind(this);
        this.removeToastMessage = this.removeToastMessage.bind(this);
    }


    componentDidMount() {
        this.props.setShowToastNotificationFunction((type, message) => this.showToastNotification(type, message));
    }

    showToastNotification(type, message) {
        let color = 'danger';
        switch (type) {
            case 'primary':
            case 'secondary':
            case 'success':
            case 'info':
            case 'warning':
            case 'light':
            case 'dark':
                color = type;
                break;
            default:
                color = 'danger';
                break;
        }

        let toastItem = { isOpened: true, color: color, message: message, timeStr: Moment().format('HH:mm:ss') };

        let stackingToasts = [...this.state.stackingToasts];
        stackingToasts.push(toastItem);
        this.setState({ stackingToasts: stackingToasts });

    }

    closeToastMessage(item) {
        item.isOpened = false;
        let stackingToasts = [...this.state.stackingToasts];
        this.setState({ stackingToasts: stackingToasts });
    }

    removeToastMessage(item) {
        let stackingToasts = [];
        this.state.stackingToasts.forEach(arrayItem => {
            if (item === arrayItem) {
                return;
            }
            stackingToasts.push(arrayItem);
        })
        this.setState({ stackingToasts: stackingToasts });
    }

    render() {
        if (this.props.authenticatedUser === null) {
            return (<Navigate to='/login' />);
        }

        return (
            <>
                <RootPageStructureContainer />
                {this.state.stackingToasts.map((item, index) => {
                    return (<MDBToast
                        key={index}
                        color={item.color}
                        open={item.isOpened}
                        appendToBody
                        onClose={() => this.closeToastMessage(item)}
                        onClosed={() => this.removeToastMessage(item)}
                        autohide
                        position='top-right'
                        delay={5000}
                        offset={100}
                        headerContent= {
                            <>
                                <strong className='me-auto'>Bilgilendirme</strong>
                                <small>{item.timeStr}</small>
                            </>
                        }
                        bodyContent={item.message}
                    />)
                })}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setShowToastNotificationFunction: (callback) => dispatch({ type: 'storeShowToastNotificationCallback', showToastNotificationCallback: callback })
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRootPageContainer);