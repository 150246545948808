import Api from './Api';
import Csrf from './Csrf';


const QuizMaker = {
    async fetchAllSystemAttributes() {
        await Csrf.getCookie();
        return Api.get('/attributes/system');
    },

    async saveAllSystemAttributes(form_items) {
        await Csrf.getCookie();
        return Api.post('/attributes/system', form_items);
    },

    async fetchAllUserAttributes(form_items) {
        await Csrf.getCookie();
        return Api.get('/attributes/user/' + form_items.user_id);
    },

    async saveAllUserAttributes(form_items) {
        await Csrf.getCookie();
        return Api.post('/attributes/user', form_items);
    },

};

export default QuizMaker;