import { Component } from 'react';
import { connect } from 'react-redux';
import { USER_IS_ADMIN, USER_IS_STUDENT, USER_IS_TEACHER } from '../../common/common';
import AdminDashboardComponent from './admin/AdminDashboardComponent';
import StudentDashboardComponent from './student/StudentDashboardComponent';
import TeacherDashboardComponent from './teacher/TeacherDashboardComponent';

class DashboardComponent extends Component {

    render() {
        let dashboardComponent = <StudentDashboardComponent />;

        if (this.props.authenticatedUser.role_id === USER_IS_ADMIN) {
            dashboardComponent = <AdminDashboardComponent />;
        } else if (this.props.authenticatedUser.role_id === USER_IS_TEACHER) {
            dashboardComponent = <TeacherDashboardComponent />;
        } else if (this.props.authenticatedUser.role_id === USER_IS_STUDENT) {
            dashboardComponent = <StudentDashboardComponent />;
        }

        return (<>
            {dashboardComponent}
        </>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(DashboardComponent);