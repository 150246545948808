import { Component } from 'react';
import { connect } from 'react-redux';

class HomeworkComponent extends Component {

    render() {
        return (
            <div>
                <p>{this.props.authenticatedUser.name} {this.props.authenticatedUser.surname} create and assign homework to your classes.</p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle
    }
};

export default connect(mapStateToProps)(HomeworkComponent);