import { MDBBtn, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBInput, MDBCol, MDBModalDialog, MDBModalContent, MDBModalTitle } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Resource from '../../../apis/Resource';
import { mainpages } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';



class ResourceLinkViewerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            unit_items: [],
            resource_type: '',
            resource_target: '',
            resource_available_for: '',
        };

        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);

        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);
    }

    componentDidMount() {

        let resource_unit_ids = [];
        this.props.resourceBeingViewed.units.forEach(unit => {
            resource_unit_ids.push(unit.id);
        });

        let unit_items = [];
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {
            grade.units.forEach(unit => {
                if (resource_unit_ids.includes(unit.id)) {
                    unit_items.push(`${unit.name}: ${unit.title}`);
                }
            });
        });
 
        this.setState({ unit_items: unit_items});


        this.props.all_resource_available_for.forEach(available_for => {
            if (available_for.id === this.props.resourceBeingViewed.available_for) {
                this.setState({ resource_available_for: available_for.name });
            }
        });

    }


 

    cancelButtonClickHandler() {
        this.props.closeCallback();
    }

    async deleteResource() {
        if (this.props.resourceBeingViewed === null) {
            return;
        }

        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = { id: this.props.resourceBeingViewed.id };
            await Resource.deleteResource(request);
            this.props.showToastNotification('success', 'Kaynak silindi');
            this.setState({ confirmDeleteShown: false });
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }


    onDeleteConfirmationButtonClickHandler() {
        this.deleteResource(); // close is handled in deleteResource() method.
    }

    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let unitnamesstr = '';
        if (this.state.confirmDeleteShown) {
            let units = this.props.resourceBeingViewed.units;
            let unitnamesarr = [];
            units.forEach(unit => {
                unitnamesarr.push(`${unit.name} - ${unit.title}`);
            });
            unitnamesstr = unitnamesarr.join(', ');
        }

        let confirmDeleteComponent = (
        <MDBModal size='md' open={this.state.confirmDeleteShown} staticBackdrop>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Harici Link Silme Onayı</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p><strong>Silinecek kaynak adı:</strong> {this.props.resourceBeingViewed.name}</p>
                        <p><strong>Kademesi:</strong> {this.props.resourceBeingViewed.grade.name}</p>
                        <p><strong>Üniteleri:</strong> {unitnamesstr}</p>
                        <p>Silme işlemini onaylıyor musunuz?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>);

        let units_array = [];
        this.state.unit_items.forEach(unit => {
            units_array.push(unit);
        });
        let units = units_array.join(', ');

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title + ' Harici Link İncele';

        return (
            <div>
                <MDBCard>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>{title}</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody className='p-4'>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='grade'
                                            label='Kademe'
                                            type='text'
                                            value={this.props.resourceBeingViewed.grade.name}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='grades_units'
                                            label='Üniteler'
                                            type='text'
                                            value={units}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='resource_available_for'
                                            label='Kimin İçin?'
                                            type='text'
                                            value={this.state.resource_available_for}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='published'
                                            label='Yayında mı?'
                                            type='text'
                                            value={this.props.resourceBeingViewed.published === 1 ? 'Evet' : 'Hayır'}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='name'
                                            label='Kaynak adı'
                                            type='text'
                                            value={this.props.resourceBeingViewed.name}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='description'
                                            label='Açıklama'
                                            type='textarea'
                                            value={this.props.resourceBeingViewed.description}
                                            disabled
                                            rows='3'
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBInput id='external_link'
                                            label='Harici Link'
                                            type='text'
                                            value={this.props.resourceBeingViewed.external_link}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12'>
                                {this.props.resourceBeingViewed.thumbnail_path ? 
                                (<div className=''><Zoom zoomMargin={20}><img src={`${window.config.downloadserver}/${this.props.resourceBeingViewed.uuid}/${this.props.resourceBeingViewed.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: 'auto', height: '200px'}}/></Zoom></div>) : ''}
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBBtn color='primary' className='me-2' onClick={() => this.props.editorCallBack(this.props.resourceBeingViewed)}>DÜZENLE</MDBBtn>
                        <MDBBtn color='danger' className='me-2' onClick={this.toggleDeleteConfirmationModal}>SİL</MDBBtn>
                        <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>KAPAT</MDBBtn>
                    </MDBCardFooter>
                    {spinnerWaitComponent}
                    {confirmDeleteComponent}
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ResourceLinkViewerComponent);
