import { MDBBtn, MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import Grade from '../../apis/Grade';
import QuizMakerGradeUnitsSelectComponent from './QuizMakerGradeUnitsSelectComponent';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import QuizMaker from '../../apis/QuizMaker';
import QuizMakerPreviewQuizComponent from './QuizMakerPreviewQuizComponent';

class QuizMakerComponent extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                label: 'Sınıf',
                field: 'class',
                sort: true,
                searchable: true
            },
            {
                label: 'Sınav Adı',
                field: 'name',
                sort: true,
                searchable: true
            },
            {
                label: 'Tarih',
                field: 'datetime',
                sort: true,
                searchable: true
            },           
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false,
                
            }
        ];

        this.state = {
            isSpinnerWaitShown: false,
            generateNewQuizButtonClicked: false,
            previewQuiz: null,
            previewColumns: [],
            previewQuizButtonClicked: false,
            previousQuizes: [],
            rows: []
        };

        this.all_grades_with_units = [];

        this.generateNewQuizButtonClickHandler = this.generateNewQuizButtonClickHandler.bind(this);
        this.closeRequestButtonClicked = this.closeRequestButtonClicked.bind(this);
        this.downloadQuiz = this.downloadQuiz.bind(this);

        this.reloadContentAndResetToInitial = this.reloadContentAndResetToInitial.bind(this);
        this.previewQuiz = this.previewQuiz.bind(this);

    }


    componentDidMount() {
        this.reloadContentAndResetToInitial();
    }

    generateNewQuizButtonClickHandler() {
        this.setState({ generateNewQuizButtonClicked: true });
    }

    downloadQuiz(resource) {
        this.downloadGeneratedQuiz(resource);
    }  

    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }

    async downloadGeneratedQuiz(resource) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                id: resource.id
            };
            let response = await QuizMaker.downloadQuiz(request);
            this.setState({ isSpinnerWaitShown: false });


            let regex = /[^a-zA-ZĞÜŞİÖÇğüşöçı0-9_.-]/g;
            let downloadname = resource.name.replaceAll(regex, '_') + '.pdf';


            const myUrl = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
            const url = myUrl.createObjectURL(new Blob([this._base64ToArrayBuffer(response.data)], 
                                                {type: 'application/pdf'}));

            const link = document.createElement('a');
            link.href = url;

            link.target='_blank';
            link.setAttribute('download', `${downloadname}`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            myUrl.revokeObjectURL(url);

        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sınavlar sunucudan indirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sınavlar sunucudan indirilemedi. Bilinmeyen hata!');
            }
        }
    }

    async fetchAllQuizes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await QuizMaker.fetchAllQuizzesOfUser();
            this.setState({ isSpinnerWaitShown: false });
            let rows = [];
            response.data.forEach((resource, index) => {
                let item = {
                    class: resource.grade.name,
                    name: resource.name,
                    datetime: Moment(resource.updated_at).format('YYYY-MM-DD HH:mm'),
                    actions: (<div key={index}>
                            <MDBBtn outline rounded color='primary' size='sm' className='px-2 mx-1' onClick={() => this.previewQuiz(resource)}><MDBIcon fas icon='eye' /><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                            <MDBBtn outline rounded color='success' size='sm' className='px-2 mx-1' onClick={() => this.downloadQuiz(resource)}><MDBIcon fas icon='file-download' /><span className='d-none d-lg-inline ms-1'>İNDİR</span></MDBBtn>
                    </div>)
                };
                rows.push(item);
            });
            this.setState({ rows: rows });
            this.setState({ previousQuizes: response.data});
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sınavlar sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sınavlar sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }
    }


    async fetchGradesWithUnits() {
        if (this.props.all_grades_with_units.length > 0) {
            this.all_grades_with_units = this.props.all_grades_with_units;
            return;
        }
        if (this.all_grades_with_units.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Grade.fetchGradesWithUnits();
            this.setState({ isSpinnerWaitShown: false });
            this.all_grades_with_units = response.data;
            this.props.storeAllGradesWithUnits(this.all_grades_with_units);
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sınıf ve ünite bilgileri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sınıf ve ünite bilgileri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }
        
    }


    closeRequestButtonClicked() {
        this.setState({ generateNewQuizButtonClicked: false });
        this.setState({ previewQuiz: null });
        this.setState({ previewColumns: [] });
        this.setState({ previewQuizButtonClicked: false });
    }


    reloadContentAndResetToInitial() {
        this.setState({ generateNewQuizButtonClicked: false });
        this.fetchGradesWithUnits();
        this.fetchAllQuizes();
    }


    previewQuiz(resource) {
        this.previewGeneratedQuiz(resource);
    }

    async previewGeneratedQuiz(resource) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                id: resource.id
            };
            let response = await QuizMaker.fetchQuiz(request);
            this.setState({ isSpinnerWaitShown: false });

            this.setState({ previewQuiz: response.data.resourceGroup });
            this.setState({ previewColumns: response.data.columns });
            this.setState({ previewQuizButtonClicked: true });
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sınav sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sınav sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }
    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.generateNewQuizButtonClicked) {
            return (<QuizMakerGradeUnitsSelectComponent closeCallback={this.closeRequestButtonClicked} 
                previousQuizes={this.state.previousQuizes}
                reloadContentAndResetToInitial={this.reloadContentAndResetToInitial} />);
        }

        if (this.state.previewQuizButtonClicked) {
            return (<QuizMakerPreviewQuizComponent
                previewQuiz={this.state.previewQuiz}
                previewColumns={this.state.previewColumns}
                downloadQuizCallback={this.downloadQuiz}
                closeCallback={this.closeRequestButtonClicked} />);
        }

        return (
            <>
                <h3>Sınav Üreteci</h3>
                <p>Online olarak sınavlar oluşturup, PDF formatında indirip sınıflarınızda sınav olarak uygulayabilirsiniz.</p>

                <MDBBtn color='primary' onClick={this.generateNewQuizButtonClickHandler}>YENİ SINAV ÜRET</MDBBtn>
                <div className='mt-4 p-4 border rounded'>
                    <MDBDatatable 
                            search
                            sm
                            hover
                            fullPagination
                            rowsText='Sayfadaki satır sayısı'
                            data={{columns: this.columns, rows: this.state.rows}} />
                </div>
                {spinnerWaitComponent}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        all_grades_with_units: state.all_grades_with_units,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAllGradesWithUnits: (all_grades_with_units) => dispatch({ type: 'storeAllGradesWithUnits', all_grades_with_units: all_grades_with_units })
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(QuizMakerComponent);