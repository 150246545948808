import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBValidation } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import 'react-medium-image-zoom/dist/styles.css';

class ExamMakerPreviewExamComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            examName: '',
            examDescription: ''
        };

        this.onChangeExamName = this.onChangeExamName.bind(this);
        this.onChangeExamDescription = this.onChangeExamDescription.bind(this);
    }

    componentDidMount() {

    }

    onChangeExamName(event) {
        
        this.setState( { examName: event.target.value } );
    }

    onChangeExamDescription(event) {
        this.setState( { examDescription: event.target.value } );
    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }



        return (

            <div>
                <MDBCard>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>Yazılı Üreteci - Yazılı Önizlemesi</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody className='p-4'>
                            <div className='form-outline row'>
                                <div className='col-auto'>
                                    <strong>SINIF:</strong> {this.props.previewExam.grade.name}
                                </div>
                            </div>
                            <div className='form-outline row'>
                                <div className='col-auto'>
                                    <strong>SINAV ADI:</strong> {this.props.previewExam.name}
                                </div>
                            </div>
                            <div className='form-outline row mb-4'>
                                <div className='col-auto'>
                                    <strong>AÇIKLAMA:</strong> {this.props.previewExam.description}
                                </div>
                            </div>
                            <div className='form-outline row'>
                            
                             {this.props.previewResources.map((resource, index) => {
                                let activity_text = resource.activity_text.replace(/>([A-Za-z0-9_ ])/, `>${index + 1}. $1`);

                                let unit_name = resource.units.length === 0 ? 'This exercise does not have any unit' :  `${resource.units[0].name} - ${resource.units[0].title}`;
                                let meb_function_name = resource.meb_functions.length === 0 ? 'This exercise does not have any MEB function' :  `${resource.meb_functions[0].name}`;
                                return (
                                    <div key={index} className='col-12 border-bottom py-4'>
                                        <div className='row'>
                                            <div className='col-auto' dangerouslySetInnerHTML={{ __html: activity_text }}>
                                            </div>
                                            <div className='row'>
                                                <small className='text-primary'>{resource.grade.name} : {unit_name}</small>
                                                <small className='text-secondary'>{meb_function_name}</small>
                                                <small>CEVAP: {resource.answers}</small>
                                            </div>
                                        </div>
                                    </div>
                                );
                             })}
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                        <MDBBtn color='primary' className='me-2' onClick={() => this.props.downloadExamCallback(this.props.previewExam)}>YAZILIYI İNDİR</MDBBtn>
                        <MDBBtn color='info' className='me-2' onClick={() => this.props.downloadExamAnswerKeyCallback(this.props.previewExam)}>CEVAP ANAHTARINI İNDİR</MDBBtn>
                        <MDBBtn color='secondary' className='me-2' onClick={this.props.closeCallback}>KAPAT</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ExamMakerPreviewExamComponent);