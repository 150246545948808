import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from  'react-redux';
import { MDBBtn, 
        MDBCol, 
        MDBInput, 
        MDBRow, 
        MDBCard, 
        MDBCardBody, 
        MDBCardFooter, 
        MDBCardHeader,
        MDBValidation,
        MDBValidationItem
    } from 'mdb-react-ui-kit';


import AlertComponent from '../components/AlertComponent';
import SpinnerWaitComponent from '../components/SpinnerWaitComponent';
import User from '../apis/User';
import { cloneConfigForFormControl, mainpages, sanitizeUserName } from '../common/common';

import TeamLMSLogo from '../images/teamlms_logo.svg';


class LoginFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {  isForgotPasswordLinkClicked: false,
                        isForgotUsernameLinkClicked: false,
                        isGotoSignupPageButtonClicked: false,
                        isSpinnerWaitShown: false,
                        form_controls: {
                                        username: { value: '', is_invalid: false, message: '', instruction: ''},
                                        password: { value: '', is_invalid: false, message: '', instruction: ''},
                                        remember: { value: false, is_invalid: false, message: '', instruction: ''}
                                      },
                        submit_success: false,
                        submit_error: false,
                        submit_error_code: '',
                        alert_header: '',
                        alert_messages: []
                     };

        this.forgotPasswordClickHandler = this.forgotPasswordClickHandler.bind(this);
        this.forgotUsernameClickHandler = this.forgotUsernameClickHandler.bind(this);
        this.gotoSignupPageButtonClickHandler = this.gotoSignupPageButtonClickHandler.bind(this);
        this.logInBtnClickHandler = this.logInBtnClickHandler.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.modalCloseHandler = this.modalCloseHandler.bind(this);
        this.resendVerification = this.resendVerification.bind(this);


    }

    componentDidMount() {

        // check if global application store has an authenticated user
        if (this.props.authenticatedUser !== null) {
            return; // yes, global session has an authenticated user
        }


        // precheck whether there is an active session on backend side or not.
        this.getAuthenticatedUser();
    }


    async getAuthenticatedUser() {
        try {
            let response = await User.getauthenticateduser();
            this.setState({ alert_header: '' });
            this.setState({ alert_messages: [] });
            // this causes component unmount, don't update state after this call
            this.props.storeAuthenticatedUser(response.data.user); 
        } catch (error) {
            // skip unauthenticated session this part and let the component to handle it.
        }
    }

    forgotPasswordClickHandler(e) {
        this.setState({isForgotPasswordLinkClicked: true});
    }

    gotoSignupPageButtonClickHandler(e) {
        this.setState({isGotoSignupPageButtonClicked: true});
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let form_controls_copy = { ...this.state.form_controls }; 
        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        if (key === 'remember') {
            value = event.target.checked;
        } else if (key === 'username') {
            value = sanitizeUserName(value);
        }
        
        form_control_copy.value = value;
        form_control_copy.is_invalid = false;
        form_control_copy.message = '';
        form_controls_copy[key] = form_control_copy; // all controls
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }


    forgotUsernameClickHandler(event) {
        this.setState({ isForgotUsernameLinkClicked: true });
    }


    logInBtnClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        e.target.className += ' was-validated';

        this.startLoginSubmission();
    }

    async startLoginSubmission() {

        this.setState({ submit_success: false });
        this.setState({ submit_error: false });
        this.setState({ submit_error_code: '' });
        this.setState({ alert_header: '' });
        this.setState({ alert_messages: '' });
        this.setState({ isSpinnerWaitShown: true });


        try {
            let request_data = { username: this.state.form_controls.username.value,
                                 password: this.state.form_controls.password.value,
                                 remember: this.state.form_controls.remember.value
                               };
            let response = await User.login(request_data);

            this.setState({ isSpinnerWaitShown: false });
            // this causes component unmount, don't update state after this call
            this.props.storeAllGradesWithUnits([]); // empty it to give it a chance to reload from server if necessary
            this.props.setMainPageCode(mainpages.dashboard.code); // redirect newly logged in users to dashboard
            this.props.storeAuthenticatedUser(response.data.user); 


        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_controls_copy['username'] = cloneConfigForFormControl('username', errors, form_controls_copy);
                form_controls_copy['password'] = cloneConfigForFormControl('password', errors, form_controls_copy);
                this.setState({ form_controls: form_controls_copy });

                if (error.response.data.error_code !== undefined) {
                    this.setState({ submit_error_code: error.response.data.error_code });
                }

                if (errors.error !== undefined) { // handle global algoritmical errors
                    this.setState({ alert_header: error.response.data.message });
                    this.setState({ alert_messages: errors.error });
                    this.setState({ submit_success: false });
                    this.setState({ submit_error: true });
                }
            } else {
                // handle catastrophic errors
                this.setState({ alert_header: 'Unknown Error Occured' });
                this.setState({ alert_messages: error.toString() });
                this.setState({ submit_success: false });
                this.setState({ submit_error: true });
            }
            
        }

        
    }

    modalCloseHandler() {
        this.setState({ submit_error_code: '' });
    }

    async resendVerification() {
        await User.resend_verification();
    }


    render() {
        if (this.props.authenticatedUser !== null) {
            return (<Navigate to='/' />);
        }

        if (this.state.isForgotUsernameLinkClicked) {
            return (<Navigate to='/forgotusername' />);
        }
        
        if (this.state.isForgotPasswordLinkClicked) {
            return (<Navigate to='/forgotpassword' />);
        }

        if (this.state.isGotoSignupPageButtonClicked) {
            return (<Navigate to='/signup' />);
        }
        
        let errorMessageComponent = '';
        if (this.state.submit_error) {
            errorMessageComponent = (<AlertComponent 
                                        type='danger'
                                        alert_header={this.state.alert_header} 
                                        alert_messages={this.state.alert_messages} />
                                    );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        return(
            <div className='height-full d-flex align-items-center justify-content-center flex-column bg-light'>
                <div className='col-12 col-sm-8 col-md-7 col-lg-6 col-xl-5 col-xxl-4'>
                    <MDBCard md='12' className='fluid'>
                        <MDBValidation noValidate>
                        <MDBCardHeader>
                            <h5>TEAM LMS - Oturum Aç</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol className='mb-3 d-flex justify-content-center'>
                                    <img src={TeamLMSLogo} alt='TEAM LMS Logo' width='80px' height='80px'/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className='mb-3 d-flex justify-content-center'>
                                    {errorMessageComponent}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.username.message} invalid>
                                        <MDBInput 
                                            type='text' 
                                            id='username'
                                            autoFocus
                                            autoComplete='off'
                                            label='Kullanıcı adı'
                                            onChange={this.formItemOnChangeHandler}
                                            required
                                            value={this.state.form_controls.username.value}
                                            className={this.state.form_controls.username.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBValidationItem feedback={this.state.form_controls.password.message} invalid>
                                        <MDBInput 
                                            type='password' 
                                            id='password'
                                            label='Şifre'
                                            required
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.password.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn type='submit' color='primary' className='me-3' onClick={this.logInBtnClickHandler}>OTURUM AÇ</MDBBtn>
                            <MDBBtn color='secondary' onClick={this.gotoSignupPageButtonClickHandler}>HESAP OLUŞTUR</MDBBtn>
                        </MDBCardFooter>
                        </MDBValidation>
                        {spinnerWaitComponent}
                    </MDBCard>
                    <div className='d-flex justify-content-center mt-3'>
                        <MDBBtn color='link' onClick={this.forgotPasswordClickHandler}>ŞİFREMİ UNUTTUM</MDBBtn>
                        <MDBBtn color='link' onClick={this.forgotUsernameClickHandler}>KULLANICI ADIMI UNUTTUM</MDBBtn>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAuthenticatedUser: (authenticatedUser) => dispatch({ type: 'storeAuthenticatedUser', authenticatedUser: authenticatedUser }),
        storeAllGradesWithUnits: (all_grades_with_units) => dispatch({ type: 'storeAllGradesWithUnits', all_grades_with_units: all_grades_with_units }),
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode: mainPageCode })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent);
