import Api from './Api';
import Csrf from './Csrf';
import store from '../store/index';

const User = {
    async register(form_items) { // anonymous
        await Csrf.getCookie();
        return Api.post('/register', form_items);
    },

    async getauthenticateduser() {
        await Csrf.getCookie();

        let response = Api.get('/getauthenticateduser');

        if (response.status === 401) {
            // session is expired or logged out
            store.dispatch({ type: 'logout' });
        }

        return response;
    },

    // users change own password
    async changepassword(form_items) {
        await Csrf.getCookie();
        return Api.post('/changepassword', form_items);
    },

    // admins set the password of a user
    async setPassword(form_items) {
        await Csrf.getCookie();
        return Api.post('/users/setpassword', form_items);
    },

    async updateUserProfile(form_items) {
        await Csrf.getCookie();
        return Api.put('/updateuserprofile', form_items);
    },

    async login(form_items) {
        await Csrf.getCookie();
        return Api.post('/login', form_items);
    },

    async logout() {
        await Csrf.getCookie();
        return Api.get('/logout');
    },

    async resend_email_verification_url() {
        await Csrf.getCookie();
        return Api.get('/resend_email_verification_url');
    },

    async validate_mobile_phone_number(form_items) {
        await Csrf.getCookie();
        return Api.post('/verify/sms', form_items);
    },

    async resend_mobile_verification_code() {
        await Csrf.getCookie();
        return Api.get('/resend_mobile_verification_code');
    },

    async reset_password_initiate_request(form_items) {
        await Csrf.getCookie();
        return Api.post('/password/reset/initiate', form_items);
    },

    async reset_password_request(form_items) {
        await Csrf.getCookie();
        return Api.post('/password/reset', form_items);
    },

    async forgot_username_request(form_items) {
        await Csrf.getCookie();
        return Api.post('/username/remind', form_items);
    },

    async fetch_users(form_items) {
        await Csrf.getCookie();
        return Api.post('/users', form_items);
    },

    async edit_user(form_items) {
        await Csrf.getCookie();
        return Api.post('/users/edit', form_items);
    }
};

export default User;