import { Component } from 'react';
import { connect } from 'react-redux';

import { mainpages } from '../common/common';
import DashboardComponent from '../components/mainpage/DashboardComponent';
import DownloadComponent from '../components/mainpage/DownloadComponent';
import PasswordChangeComponent from '../components/mainpage/PasswordChangeComponent';
import QuizMakerComponent from '../components/mainpage/QuizMakerComponent';
import ExamMakerComponent from '../components/mainpage/ExamMakerComponent';
import VirtualClassesComponent from '../components/mainpage/VirtualClassesComponent';
import StudentsComponent from '../components/mainpage/StudentsComponent';
import TeacherGradesComponent from '../components/mainpage/teacher/TeacherGradesComponent';
import TeacherExamsComponent from '../components/mainpage/teacher/TeacherExamsComponent';
import TeacherDownloadComponent from '../components/mainpage/teacher/TeacherDownloadComponent';
import HomeworkComponent from '../components/mainpage/HomeworkComponent';
import ChallengesComponent from '../components/mainpage/ChallengesComponent';
import EditProfileComponent from '../components/mainpage/EditProfileComponent';
import AttendToAClassComponent from '../components/mainpage/AttendToAClassComponent';
import ResourcesDownloadableComponent from '../components/mainpage/admin/ResourcesDownloadableComponent';
import UserNotVerifiedComponent from '../components/mainpage/UserNotVerifiedComponent';
import UserIsDisabledComponent from '../components/mainpage/UserIsDisabledComponent';
import ResourcesLinksComponent from '../components/mainpage/admin/ResourcesLinksComponent';
import TeacherLinkComponent from '../components/mainpage/teacher/TeacherLinkComponent';
import AdminUserManagementComponent from '../components/mainpage/admin/AdminUserManagementComponent';
import ResourcesActivitiesComponent from '../components/mainpage/admin/ResourcesActivitiesComponent';
import SystemAttributesComponent from '../components/mainpage/admin/SystemAttributesComponent';


class MainPageContainer extends Component {


    render() {
        let mainPageComponent = (<DashboardComponent />);

        if (this.props.authenticatedUser.is_verified && !this.props.authenticatedUser.is_disabled) {


            if ( ! mainpages[this.props.mainPageCode].role_id.includes(this.props.authenticatedUser.role_id)) {
                this.props.setMainPageCode(mainpages.dashboard.code);
            }

            /* Common for each type of user */
            if (this.props.mainPageCode === mainpages.dashboard.code) {
                mainPageComponent = (<DashboardComponent />);
            } else if (this.props.mainPageCode === mainpages.editprofile.code) {
                mainPageComponent = (<EditProfileComponent />);
            } else if (this.props.mainPageCode === mainpages.changepassword.code) {
                mainPageComponent = (<PasswordChangeComponent />);

            /* Teacher Menu Items */

            } else if (this.props.mainPageCode === mainpages.teacher_downloads.code) {
                mainPageComponent = (<DownloadComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_grades.code) {
                mainPageComponent = (<TeacherGradesComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_quizmaker.code) {
                mainPageComponent = (<QuizMakerComponent menucode={this.props.mainPageCode} />);
            } else if (this.props.mainPageCode === mainpages.teacher_exammaker.code) {
                mainPageComponent = (<ExamMakerComponent menucode={this.props.mainPageCode} />);
            } else if (this.props.mainPageCode === mainpages.teacher_classes.code) {
                mainPageComponent = (<VirtualClassesComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_students.code) {
                mainPageComponent = (<StudentsComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_exams.code) {
                mainPageComponent = (<TeacherExamsComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_homework.code) {
                mainPageComponent = (<HomeworkComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_challenges.code) {
                mainPageComponent = (<ChallengesComponent />);
            } else if (this.props.mainPageCode === mainpages.teacher_games.code) {
                mainPageComponent = (<TeacherLinkComponent menucode={this.props.mainPageCode} />);
            } else if (this.props.mainPageCode === mainpages.teacher_iteam.code) {
                mainPageComponent = (<TeacherLinkComponent menucode={this.props.mainPageCode} />);
            } else if (this.props.mainPageCode === mainpages.teacher_downl_iteam_apps.code
                || this.props.mainPageCode === mainpages.teacher_downl_challenge_apps.code
                || this.props.mainPageCode === mainpages.teacher_downl_game_apps.code
                || this.props.mainPageCode === mainpages.teacher_downl_vocabulary_presentations.code
                || this.props.mainPageCode === mainpages.teacher_downl_vocabulary_lists.code
                || this.props.mainPageCode === mainpages.teacher_downl_vocabulary_videos.code
                || this.props.mainPageCode === mainpages.teacher_downl_worksheets.code
                || this.props.mainPageCode === mainpages.teacher_downl_exams.code
                || this.props.mainPageCode === mainpages.teacher_downl_skillexams.code
                || this.props.mainPageCode === mainpages.teacher_downl_posters.code
                || this.props.mainPageCode === mainpages.teacher_downl_annual_academic_plans.code
                || this.props.mainPageCode === mainpages.teacher_downl_teachers_resources.code
                ) {
                mainPageComponent = (<TeacherDownloadComponent menucode={this.props.mainPageCode}/>);

            /* Student Menu Items */ 
                           
            } else if (this.props.mainPageCode === mainpages.student_attendclass.code) {
                mainPageComponent = (<AttendToAClassComponent />);

            /* Admin Menu Items */

            } else if (this.props.mainPageCode === mainpages.admin_downl_iteam_apps.code
                || this.props.mainPageCode === mainpages.admin_downl_challenge_apps.code
                || this.props.mainPageCode === mainpages.admin_downl_game_apps.code
                || this.props.mainPageCode === mainpages.admin_downl_vocabulary_presentations.code
                || this.props.mainPageCode === mainpages.admin_downl_vocabulary_lists.code
                || this.props.mainPageCode === mainpages.admin_downl_vocabulary_videos.code
                || this.props.mainPageCode === mainpages.admin_downl_worksheets.code
                || this.props.mainPageCode === mainpages.admin_downl_exams.code
                || this.props.mainPageCode === mainpages.admin_downl_skillexams.code
                || this.props.mainPageCode === mainpages.admin_downl_posters.code
                || this.props.mainPageCode === mainpages.admin_downl_annual_academic_plans.code
                || this.props.mainPageCode === mainpages.admin_downl_teachers_resources.code
                || this.props.mainPageCode === mainpages.admin_downl_quizmaker.code
                ) {
                mainPageComponent = (<ResourcesDownloadableComponent menucode={this.props.mainPageCode}/>);
            } else if (this.props.mainPageCode === mainpages.admin_resources_activities.code) {
                mainPageComponent = (<ResourcesActivitiesComponent menucode={this.props.mainPageCode}/>);
            } else if (this.props.mainPageCode === mainpages.admin_quizmaker.code) {
                mainPageComponent = (<QuizMakerComponent menucode={this.props.mainPageCode}/>);
            } else if (this.props.mainPageCode === mainpages.admin_exammaker.code) {
                mainPageComponent = (<ExamMakerComponent menucode={this.props.mainPageCode}/>);
            } else if (this.props.mainPageCode === mainpages.admin_link_iteam.code
                || this.props.mainPageCode === mainpages.admin_link_games.code
                ) {
                mainPageComponent = (<ResourcesLinksComponent menucode={this.props.mainPageCode}/>);
            } else if (this.props.mainPageCode === mainpages.admin_user_mgmt_teachers.code
                || this.props.mainPageCode === mainpages.admin_user_mgmt_students.code
                || this.props.mainPageCode === mainpages.admin_user_mgmt_admins.code
                ) {
                mainPageComponent = (<AdminUserManagementComponent menucode={this.props.mainPageCode}/>);
            } else if (this.props.mainPageCode === mainpages.admin_attribute_management.code) {
                mainPageComponent = (<SystemAttributesComponent menucode={this.props.mainPageCode}/>);
            }

        } else if (!this.props.authenticatedUser.is_verified) {
            mainPageComponent = <UserNotVerifiedComponent />;
        } else  if (this.props.authenticatedUser.is_disabled) {
            mainPageComponent = <UserIsDisabledComponent />;
        }

        return(<>
                {mainPageComponent}
            </>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageCode: state.mainPageCode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode: mainPageCode })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPageContainer);