import { Component } from 'react';
import { connect } from 'react-redux';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTooltip } from 'mdb-react-ui-kit';

import VirtualClass from '../../apis/VirtualClass';
import VirtualClassEditorComponent from './VirtualClassEditorComponent';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import VirtualClassViewerComponent from './VirtualClassViewerComponent';



class VirtualClassesComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            deleteCandidateVirtualClass: null,
            createnew: false,
            editexisting: false,
            edititem: null,
            viewVirtualClass: null,
            viewexisting: false,
            rows: []
        };

        this.columns = [
            {
                label: 'Sınıf Kodu',
                field: 'shared_code',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Kademe',
                field: 'grade_name',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Sınıf Adı',
                field: 'name',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Öğrenci (Yeni)',
                field: 'attendee_count',
                align: 'right',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Eylemler',
                field: 'action_buttons',
                sort: 'asc',
                width: 300
            }
        ];
            

        this.virtualClassEditorIsClosedCallback = this.virtualClassEditorIsClosedCallback.bind(this);
        this.onEditButtonClickHandler = this.onEditButtonClickHandler.bind(this);
        this.onDeleteButtonClickHandler = this.onDeleteButtonClickHandler.bind(this);
        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);
        this.onViewButtonClickHandler = this.onViewButtonClickHandler.bind(this);
        this.virtualClassViewerIsClosedCallback = this.virtualClassViewerIsClosedCallback.bind(this);
    }


    componentDidMount() {
        this.refreshVirtualClasses();
    }

    refreshVirtualClasses() {
        this.fetchClassesByOwner();
    }

    async fetchClassesByOwner() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await VirtualClass.fetchVirtualClassesByOwner();
            let rows = [];
            let virtualClasses = response.data.virtualClasses;
            virtualClasses.forEach((item, index) => {
                
                let none_approve_count = 0;
                item.attendees.forEach(attendee => {
                    if (!attendee.pivot.approved) {
                        none_approve_count++;
                    }
                });


                let str_count = item.attendees.length + '';
                if (none_approve_count > 0 ) {
                    str_count = item.attendees.length + ' (' + none_approve_count +  ')';
                }

                let newItem = {
                    shared_code: item.shared_code,
                    grade_name: item.grade.name,
                    name: item.name,
                    attendee_count: str_count,
                    action_buttons: (<div key={index}>
                                        <MDBTooltip placement="top">
                                            <MDBBtn rounded outline color='primary' size='sm' className='px-2 waves-effect' onClick={() => this.onViewButtonClickHandler(item)}><i className='fas fa-eye mt-0'></i><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                                            <div>Sınıfı incele</div>
                                        </MDBTooltip>
                                        <MDBTooltip placement="top">
                                            <MDBBtn rounded outline color='success' size='sm' className='px-2 waves-effect' onClick={() => this.onEditButtonClickHandler(item)}><i className='fas fa-pen mt-0'></i><span className='d-none d-lg-inline ms-1'>DÜZENLE</span></MDBBtn>
                                            <div>Sınıfı düzenle</div>
                                        </MDBTooltip>
                                        <MDBTooltip placement="top">
                                            <MDBBtn rounded outline color='danger' size='sm' className='px-2 waves-effect' onClick={() => this.onDeleteButtonClickHandler(item)}><i className='fas fa-trash mt-0'></i><span className='d-none d-lg-inline ms-1'>SİL</span></MDBBtn>
                                            <div>Sınıfı sil</div>
                                        </MDBTooltip>
                                    </div>)
                };

                rows.push(newItem);
            });

            this.setState({ rows: rows });
            this.setState({ createnew: false });
            this.setState({ editexisting: false });
        } catch(error) {
            this.props.showToastNotification('error', 'Beklenmeye bir hata oluştu');
        }
        this.setState({ isSpinnerWaitShown: false });
    }

    virtualClassEditorIsClosedCallback(cancel) {
        this.setState({ createnew: false });
        this.setState({ editexisting: false });
        this.setState({ edititem: null });
        if (!cancel) {
            this.refreshVirtualClasses();
        }
    }

    virtualClassViewerIsClosedCallback() {
        this.setState({ viewexisting: false });
        this.setState({ viewVirtualClass: null });
    }

    onViewButtonClickHandler(item) {
        this.setState({ viewVirtualClass: item });
        this.setState({ viewexisting: true });
    }


    onEditButtonClickHandler(item) {
        this.setState({ edititem: item });
        this.setState({ editexisting: true });

        // close viewer trigger
        this.setState({ viewVirtualClass: null });
        this.setState({ viewexisting: false });

        // remove delete trigger
        this.setState({ deleteCandidateVirtualClass: null });
        this.setState({ confirmDeleteShown: false });
    }

    onDeleteButtonClickHandler(item) {
        // close viewer trigger
        this.setState({ viewVirtualClass: null });
        this.setState({ viewexisting: false });

        this.setState({ deleteCandidateVirtualClass: item });
        this.setState({ confirmDeleteShown: true });
    }

    onDeleteConfirmationButtonClickHandler() {
        this.deleteVirtualClass(this.state.deleteCandidateVirtualClass.id);
    }

    async deleteVirtualClass(id) {
        try {
            let request = { id: id };
            await VirtualClass.deleteVirtualClass(request);
            this.props.showToastNotification('success', 'Sınıf silindi');
            this.refreshVirtualClasses();
        } catch (error) {
            this.props.showToastNotification('error', 'Sınıf silerken bir hata oluştu');
        }

        this.setState({ confirmDeleteShown: false });

    }


    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.createnew || this.state.editexisting) {
            return (<VirtualClassEditorComponent editmode={this.state.editexisting} edititem={this.state.edititem} closeCallback={this.virtualClassEditorIsClosedCallback} />)
        }

        if (this.state.viewexisting && this.state.viewVirtualClass !== null) {
            return (<VirtualClassViewerComponent viewitem={this.state.viewVirtualClass} closeCallback={this.virtualClassViewerIsClosedCallback} editorCallback={this.onEditButtonClickHandler} deleteCallback={this.onDeleteButtonClickHandler} />)
        }

        let confirmDeleteComponent = '';

        if (this.state.confirmDeleteShown) {
            confirmDeleteComponent = (<MDBModal centered size='md' isOpen={this.state.confirmDeleteShown} toggle={this.toggleDeleteConfirmationModal}>
                <MDBModalHeader toggle={this.toggleDeleteConfirmationModal}>Sınıf Silme Onayı</MDBModalHeader>
                <MDBModalBody>
                    <p><strong>Silinecek sınıf adı:</strong> {this.state.deleteCandidateVirtualClass.name}</p>
                    <p><strong>Silinecek sınıf kodu:</strong> {this.state.deleteCandidateVirtualClass.shared_code}</p>
                    <p>Sınıfı sildiğinizde tüm öğrencilerin bu sınıfla bağları kesilecek, geçmiş tüm paylaşımlar ve istatistikler de sınıfla beraber silinecek.</p>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                    <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                </MDBModalFooter>
            </MDBModal>);
        }


        return (
            <div>
                <h3>Sınıf Yönetimi</h3>
                <p>Yeni sanal sınıf oluşturma, sınıf düzenleme, sınıf silme gibi sınıf yönetim işlemlerini yapabilirsiniz. Eğitim kurumunuzdaki fiziksel sınıflarınızla bire bir aynı olacak biçimde sanal sınıf oluşturabilirsiniz.</p>
                <p><strong>Sınıf kodu</strong> ilgili sınıfa öğrencilerin katılabilmesi için öğrencilerinizle paylaşabileceğiniz tekil bir koddur. Öğrencilerinizin sınıfınıza katılımını sağlamak için bu tekil sınıf kodunu öğrencilerinize ulaştırmalısınız. Sınıf kodu tekil olacak biçimde otomatik olarak üretilmektedir. Siber güvenlik saldırısı gibi istenmeyen durumlarla karşılaştığınızda sınıf kodunu değiştirebilirisiniz.</p>
                <MDBBtn color='primary' onClick={() => this.setState({ createnew: true })}>YENİ SINIF OLUŞTUR</MDBBtn>    
                <div>
                    {this.state.rows.length > 0 ?
                    <MDBTable btn small>
                        <MDBTableHead columns={this.columns} />
                        <MDBTableBody rows={this.state.rows} />
                    </MDBTable>
                    : '' }
                </div>
                {confirmDeleteComponent}
                {spinnerWaitComponent}
            </div>
        );    
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageCode: state.mainPageCode,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(VirtualClassesComponent);