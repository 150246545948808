import { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

import LoginFormComponent from '../pagecomponents/LoginFormComponent';
import RegistrationFormComponent from '../pagecomponents/RegistrationFormComponent';
import ForgotPasswordFormComponent from '../pagecomponents/ForgotPasswordFormComponent';
import ForgotUsernameFormComponent from '../pagecomponents/ForgotUsernameFormComponent';
import ResetPasswordFormComponent from '../pagecomponents/ResetPasswordFormComponent';
import AuthenticatedRootPageContainer from '../containers/AuthenticatedRootPageContainer';


class TeamLMSApp extends Component {

    render() {
        return (<Router>
            <Routes>
                <Route path='/login' element={<LoginFormComponent />} />
                <Route path='/signup' element={<RegistrationFormComponent />} />
                <Route path='/forgotpassword' element={<ForgotPasswordFormComponent />} />
                <Route path='/forgotusername' element={<ForgotUsernameFormComponent />} />
                <Route path='/resetpassword' element={<ResetPasswordFormComponent />} />
                <Route path='/' element={<AuthenticatedRootPageContainer />} />
            </Routes>
        </Router>);
    }
}

export default TeamLMSApp;