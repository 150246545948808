import { MDBBtn, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBInput, MDBCol, MDBModalTitle, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Resource from '../../../apis/Resource';
import { mainpages } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

class ResourceActivityViewerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            unit_items: [],
            resource_type: '',
            resource_target: '',
            meb_function: '',
            resource_available_for: '',
        };

        this.subtypes = {};
        this.subtypes['multiple_choice'] = 'Test Sorusu';
        this.subtypes['exercise'] = 'Alıştırma';
        this.subtypes['html_exercise'] = 'Yazılı Alıştırması';
        this.subtypes['listening_exercise'] = 'Dinleme Alıştırması';

        this.column_spreads = {};
        this.column_spreads['single'] = 'Tek kolon (Dar)';
        this.column_spreads['double'] = 'Çift Kolon (Geniş)';
        
        this.usage_areas = {};
        this.usage_areas['online_quiz_maker'] = 'Sınav Üreteci (PDF)';
        this.usage_areas['online_reading_writing_exam_maker'] = 'Reading & Writing Yazılı Üreteci (Word)';
        this.usage_areas['online_listening_exam_maker'] = 'Listening Yazılı Üreteci (Word)';
        this.usage_areas['online_speaking_exam_maker'] = 'Speaking Yazılı Üreteci (Word)';
        this.usage_areas['interactive_homework'] = 'Etkileşimli Ödev';
        this.usage_areas['live_interactive_exam'] = 'Canlı Etkileşimli Sınav';
        this.usage_areas['live_interactive_challenge'] = 'Canlı Etkileşimli Mücadele';

        this.cognitive_levels = {};
        this.cognitive_levels['simple'] = 'Basit Bilişsel Süreçleri Ölçme Sorusu';
        this.cognitive_levels['complex'] = 'Karmaşık Bilişsel Süreçleri Ölçme Sorusu';

        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);

        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);


    }

    componentDidMount() {

        let resource_unit_ids = [];
        this.props.resourceBeingViewed.units.forEach(unit => {
            resource_unit_ids.push(unit.id);
        });

        let unit_items = [];
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {
            grade.units.forEach(unit => {
                if (resource_unit_ids.includes(unit.id)) {
                    unit_items.push(`${unit.name}: ${unit.title}`);
                }
            });
        });
 
        this.setState({ unit_items: unit_items});

        this.props.all_resource_available_for.forEach(available_for => {
            if (available_for.id === this.props.resourceBeingViewed.available_for) {
                this.setState({ resource_available_for: available_for.name });
            }
        });

        let meb_functions = this.props.resourceBeingViewed.meb_functions;
        if (meb_functions && meb_functions.length > 0) {
            let meb_function = meb_functions[0];
            this.setState({ meb_function: meb_function.name});
        }
        

    }


 

    cancelButtonClickHandler() {
        this.props.closeCallback();
    }

    async deleteResource() {
        if (this.props.resourceBeingViewed === null) {
            return;
        }

        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = { id: this.props.resourceBeingViewed.id };
            await Resource.deleteResource(request);
            this.props.showToastNotification('success', 'Kaynak silindi');
            this.setState({ confirmDeleteShown: false });
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }


    onDeleteConfirmationButtonClickHandler() {
        this.deleteResource(); // close is handled in deleteResource() method.
    }

    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let units_for_delete_confirmation = this.props.resourceBeingViewed.units;
        let unitnamesarr = [];
        units_for_delete_confirmation.forEach(unit => {
            unitnamesarr.push(`${unit.name} - ${unit.title}`);
        });
        let unitnamesstr = unitnamesarr.join(', ');

        let confirmDeleteComponent = (
        <MDBModal size='md' open={this.state.confirmDeleteShown} staticBackdrop>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Etkinlik Silme Onayı</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p><strong>Silinecek kaynak:</strong> {this.props.resourceBeingViewed.original_file_name}</p>
                        <p><strong>Kademesi:</strong> {this.props.resourceBeingViewed.grade.name}</p>
                        {unitnamesarr.length > 0 ? <p><strong>Üniteleri:</strong> {unitnamesstr}</p> : ''}
                        <p>Silme işlemini onaylıyor musunuz?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>);

        let units_array = [];
        this.state.unit_items.forEach(unit => {
            units_array.push(unit);
        });
        let units = units_array.join(', ');

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title + ' İncele';

        return (
            <div>
                <MDBCard>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>{title}</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='grade'
                                            label='Kademe'
                                            type='text'
                                            value={this.props.resourceBeingViewed.grade.name}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='grades_units'
                                            label='Üniteler'
                                            type='text'
                                            value={units}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='resource_available_for'
                                            label='Kimin İçin?'
                                            type='text'
                                            value={this.state.resource_available_for}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='published'
                                            label='Yayında mı?'
                                            type='text'
                                            value={this.props.resourceBeingViewed.published === 1 ? 'Evet' : 'Hayır'}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='subtype'
                                            label='Etkinlik Türü'
                                            type='text'
                                            value={this.subtypes[this.props.resourceBeingViewed.subtype] === undefined ? this.props.resourceBeingViewed.subtype : this.subtypes[this.props.resourceBeingViewed.subtype]}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='column_spread'
                                            label='Kolon Yayılımı'
                                            type='text'
                                            value={this.column_spreads[this.props.resourceBeingViewed.column_spread] === undefined ? this.props.resourceBeingViewed.column_spread : this.column_spreads[this.props.resourceBeingViewed.column_spread]}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='usage_areas'
                                            label='Kullanım Alanları'
                                            type='text'
                                            value={this.usage_areas[this.props.resourceBeingViewed.usage_areas] === undefined ? this.props.resourceBeingViewed.usage_areas : this.usage_areas[this.props.resourceBeingViewed.usage_areas]}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='cognitive_level'
                                            label='Bilişsel Düzey (MEB)'
                                            type='text'
                                            value={(this.props.resourceBeingViewed.cognitive_level && (this.cognitive_levels[this.props.resourceBeingViewed.cognitive_level] === undefined)) ? this.props.resourceBeingViewed.cognitive_level : this.cognitive_levels[this.props.resourceBeingViewed.cognitive_level]}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBInput id='meb_function'
                                            label='Kazanım (MEB)'
                                            type='text'
                                            value={this.state.meb_function}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <CKEditor
                                    editor={ Editor }
                                    disabled={true}
                                    config={{
                                        isReadOnly: true
                                    }}
                                    data={this.props.resourceBeingViewed.activity_text}
                                    onInit = { editor => {
                                        //setCkeditor(editor);
                                        // Add custom configuration for image upload
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                "height",
                                                "200px",
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    } }
                                    onBlur = { editor => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus = { editor => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBInput id='original_file_name'
                                            label='Sunucudaki Etkinlik Görüntüsü Dosya Adı'
                                            type='text'
                                            value={this.props.resourceBeingViewed.original_file_name || 'There is no file'}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12' className='mb-3'>
                                <MDBInput id='audio_path'
                                            label='Sunucudaki Ses Dosya Adı'
                                            type='text'
                                            value={this.props.resourceBeingViewed.audio_path || 'There is no file'}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12'>
                            {this.props.resourceBeingViewed.thumbnail_path !== null ? (<div className=''>
                                <Zoom zoomMargin={20}>
                                    <img src={`${window.config.downloadserver}/${this.props.resourceBeingViewed.uuid}/${this.props.resourceBeingViewed.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: 'auto', height: '200px'}}/>
                                </Zoom>
                            </div>) : ''}
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBBtn color='primary' className='me-2' onClick={() => this.props.editorCallBack(this.props.resourceBeingViewed)}>DÜZENLE</MDBBtn>
                        <MDBBtn color='danger' className='me-2' onClick={this.toggleDeleteConfirmationModal}>SİL</MDBBtn>
                        <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>KAPAT</MDBBtn>
                    </MDBCardFooter>
                    {spinnerWaitComponent}
                    {confirmDeleteComponent}
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ResourceActivityViewerComponent);