import axios from 'axios';
import store from '../store';

// window.config is set up from public/teamlms_ui_config.js which is imported in index.html

let Api = axios.create({
    baseURL: window.config.apiserver
});


Api.defaults.withCredentials = true;

Api.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
        store.dispatch({ type: 'logout' });
    }
    return Promise.reject(err);
});

export default Api;