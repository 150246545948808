import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { MDBInput, MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBCardTitle, MDBRow, MDBCol, MDBSelect } from 'mdb-react-ui-kit';
import DatePicker, { registerLocale } from 'react-datepicker';
import trTR from 'date-fns/locale/tr';
import Moment from 'moment';

import Grade from '../../apis/Grade';


import "react-datepicker/dist/react-datepicker.css";

import { cloneConfigForFormControl } from '../../common/common';
import AlertComponent from '../AlertComponent';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import VirtualClass from '../../apis/VirtualClass';


class VirtualClassEditorComponent extends Component {

    constructor(props) {
        super(props);

        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);

        let grade_id = null;

        this.state = {
            isSpinnerWaitShown: false,
            selectedDate: date,
            isSharedCodeRenewed: false,
            grades: [],
            form_controls: {
                shared_code: { value: '', is_invalid: false, message: '', instruction: ''},
                grade_id: { value: grade_id, is_invalid: false, message: '', instruction: 'Sadece kendi kademeleriniz listelenir'},
                name: { value: '', is_invalid: false, message: '', instruction: ''},
                expire_at: { value: Moment(date).format('YYYY-MM-DD'), is_invalid: false, message: '', instruction: ''}
            },
            submit_success: false,
            submit_error: false,
            alert_header: '',
            alert_messages: []
        };

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onSaveButtonClickHandler = this.onSaveButtonClickHandler.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

        this.closeWindowByCancel = this.closeWindowByCancel.bind(this);
        this.closeWindowBySave = this.closeWindowBySave.bind(this);
        this.onRenewSharedCodeButtonClickHandler = this.onRenewSharedCodeButtonClickHandler.bind(this);
        this.onGradeComboboxOpenedHandler = this.onGradeComboboxOpenedHandler.bind(this);
        this.onGradeComboboxSelectHandler = this.onGradeComboboxSelectHandler.bind(this);

    }

    componentDidMount() {
        registerLocale('tr-TR', trTR);

        this.fetchGradesOfUser();


        if (this.props.editmode && this.props.edititem !== undefined && this.props.edititem !== null) {
            let item = this.props.edititem;
            let form_controls_copy = { ...this.state.form_controls };
            let form_control_copy = { ...this.state.form_controls.shared_code };
            form_control_copy.value = item.shared_code;
            form_controls_copy.shared_code = form_control_copy;

            form_control_copy = { ...this.state.form_controls.name };
            form_control_copy.value = item.name;
            form_controls_copy.name = form_control_copy;

            form_control_copy = { ...this.state.form_controls.grade_id };
            form_control_copy.value = item.grade_id;
            form_controls_copy.grade_id = form_control_copy;

            form_control_copy = { ...this.state.form_controls.expire_at };
            form_control_copy.value = Moment(item.expire_at).format('YYYY-MM-DD');
            form_controls_copy.expire_at = form_control_copy;

            let date = new Date(item.expire_at);

            this.setState({ selectedDate: date });

            this.setState({ form_controls: form_controls_copy });
        }

    }


    async fetchGradesOfUser() {
        if (this.state.grades.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Grade.fetchAttachedGradesForUser();
            let userGrades = [];
            let option = { text: 'Sınıf Kademeleriniz', 
                value: '-1',
                icon: null,
                disabled: true,
                checked: false
                };
                userGrades.push(option);
            response.data.forEach(item => {
                let strId = item.id + '';
                let checked = false;
                if (this.props.editmode === true) {
                    checked = item.id === this.props.edititem.grade_id ? true : false;
                }
                option = { text: item.name, 
                    value: strId,
                    icon: null,
                    disabled: false,
                    checked: checked
                    };
                userGrades.push(option);
            });
            this.setState( { grades: userGrades } );
        } catch (error) {

        }

        this.setState({ isSpinnerWaitShown: false });
    }


    handleDateChange(date) {
        let expire_at_config = { ...this.state.form_controls.expire_at };
        expire_at_config.value = Moment(date).format('YYYY-MM-DD');
        let form_controls = { ...this.state.form_controls };
        form_controls.expire_at = expire_at_config;

        this.setState({
            selectedDate: date,
            form_controls: form_controls
          });
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let formControlCopy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        formControlCopy.value = value;
        let formControlsCopy = { ...this.state.form_controls};
        formControlsCopy[key] = formControlCopy;
        this.setState({ form_controls: formControlsCopy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }

    onSaveButtonClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        this.startVirtualClassSubmission();
    }

    async startVirtualClassSubmission() {

        this.setState({ submit_success: false });
        this.setState({ submit_error: false });
        this.setState({ alert_header: '' });
        this.setState({ alert_messages: '' });
        this.setState({ isSpinnerWaitShown: true });

        try {
            let request_data;
            if (this.props.editmode && this.props.edititem !== undefined && this.props.edititem !== null) {
                let virtualClassItem = this.props.edititem;
                request_data = { 
                    id: virtualClassItem.id,
                    name: this.state.form_controls.name.value,
                    expire_at: this.state.form_controls.expire_at.value
                };
                await VirtualClass.updateVirtualClass(request_data);
                this.props.showToastNotification('success', 'Sınıf ' + this.state.form_controls.name.value + ' kaydedildi');
            } else {
                request_data = { 
                    name: this.state.form_controls.name.value,
                    grade_id: this.state.form_controls.grade_id.value,
                    expire_at: this.state.form_controls.expire_at.value
                };
                await VirtualClass.createVirtualClass(request_data);
                this.props.showToastNotification('success', 'Sınıf ' + this.state.form_controls.name.value + ' oluşturuldu');
            }

            let form_controls_copy = { ...this.state.form_controls };
            form_controls_copy['name'] = cloneConfigForFormControl('name', [], form_controls_copy);
            form_controls_copy['grade_id'] = cloneConfigForFormControl('grade_id', [], form_controls_copy);
            form_controls_copy['expire_at'] = cloneConfigForFormControl('expire_at', [], form_controls_copy);
            this.setState({ form_controls: form_controls_copy });

            

            this.setState({ submit_success: false });
            this.setState({ submit_error: false });
            this.setState({ alert_header: '' });
            this.setState({ alert_messages: '' });
            this.setState({ isSpinnerWaitShown: false });
            this.closeWindowBySave();
            // this causes component unmount, don't update state after this call


        } catch(error) {
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_controls_copy['name'] = cloneConfigForFormControl('name', errors, form_controls_copy);
                form_controls_copy['grade_id'] = cloneConfigForFormControl('grade_id', errors, form_controls_copy);
                form_controls_copy['expire_at'] = cloneConfigForFormControl('expire_at', errors, form_controls_copy);


                this.setState({ form_controls: form_controls_copy });
                this.setState({ submit_success: false });
                this.setState({ submit_error: false });

                if (errors.error !== undefined) { // handle global algoritmical errors
                    this.setState({ alert_header: error.response.data.message });
                    this.setState({ alert_messages: errors.error });
                    this.setState({ submit_success: false });
                    this.setState({ submit_error: true });
                    this.props.showToastNotification('error', errors.error);
                }
            } else {
                // handle catastrophic errors
                this.setState({ alert_header: 'Unknown Error Occured' });
                this.setState({ alert_messages: error.toString() });
                this.setState({ submit_success: false });
                this.setState({ submit_error: true });
                this.props.showToastNotification('error', 'Unknown Error Occured');
            }
            this.setState({ isSpinnerWaitShown: false });
        }
    }


    onRenewSharedCodeButtonClickHandler() {
        if (this.props.editmode && this.props.edititem !== undefined && this.props.edititem !== null) {
            this.renewSharedCode(this.props.edititem.id);
        }
    }

    async renewSharedCode(id) {
        this.setState({ submit_success: false });
        this.setState({ submit_error: false });
        this.setState({ alert_header: '' });
        this.setState({ alert_messages: '' });
        this.setState({ isSpinnerWaitShown: true });
        
        try {
            let request_data = { 
                id: id
            };
            
            let response = await VirtualClass.renewSharedCode(request_data);

            let form_controls_copy = { ...this.state.form_controls };
            let form_control_shared_code = { ...this.state.form_controls.shared_code };
            form_control_shared_code.value = response.data.virtualClass.shared_code;
            form_controls_copy.shared_code = form_control_shared_code;

            this.setState({ isSharedCodeRenewed: true });
            this.setState({ form_controls: form_controls_copy });
            this.setState({ submit_success: false });
            this.setState({ submit_error: false });
            this.setState({ alert_header: '' });
            this.setState({ alert_messages: '' });
            this.props.showToastNotification('success', 'Sınıf kodu ' + response.data.virtualClass.shared_code + ' olarak yenilenmiştir');

            
        } catch (error) {
                this.props.showToastNotification('error', 'Sınıf kodunu yenilerken bir hata oluştu');

                this.setState({ alert_header: '' });
                this.setState({ alert_messages: '' });
                this.setState({ submit_success: false });
                this.setState({ submit_error: false });
        }


        this.setState({ isSpinnerWaitShown: false });
    }
    
    closeWindowByCancel() {
        if (this.state.isSharedCodeRenewed) {
            this.props.closeCallback(false); // false means request refresh
        } else {
            this.props.closeCallback(true); // true means cancel refresh
        }
    }

    closeWindowBySave() {
        this.props.closeCallback(false); // false means request refresh
    }

    onGradeComboboxOpenedHandler(event) {
        this.fetchGradesOfUser();
    }

    onGradeComboboxSelectHandler(selectedItems) {
        let selectedItem;
        let form_control = { ...this.state.form_controls.grade_id };
        if (selectedItems.length === 0) {
            selectedItem = null;
        } else {
            selectedItem = parseInt(selectedItems[0]);
        }


        form_control.value = selectedItem;
        let form_controls = { ...this.state.form_controls };
        form_controls.grade_id = form_control;
        this.setState({ form_controls: form_controls });
    }

    render() {

        const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
            <MDBInput className="example-custom-input" onClick={onClick} ref={ref} label='Son tarih (yyyy-aa-gg)' value={value} >
                <div className='invalid-feedback'>{this.state.form_controls.name.message}</div>
            </MDBInput>
        ));

        let infoMessageComponent = '';
        if (this.state.submit_error && this.state.alert_header !== '') {
            infoMessageComponent = (<AlertComponent 
                                        type='danger'
                                        alert_header={this.state.alert_header} 
                                        alert_messages={this.state.alert_messages} />
                                    );
        } else if (this.state.submit_success && this.state.alert_header !== '') {
            infoMessageComponent = (<AlertComponent 
                                        type='warning'
                                        alert_header={this.state.alert_header} 
                                        alert_messages={this.state.alert_messages} />
                                    );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let sharedCodeManagementComponent = '';
        if (this.props.editmode) {
            sharedCodeManagementComponent = (<div className='form-outline'>
            <MDBRow>
                <MDBCol className='col'>
                    <MDBInput
                        type='text'
                        className='form-control'
                        id='shared_code'
                        disabled
                        readOnly
                        label='Sınıf kodu (salt-okunur)'
                        value={this.state.form_controls.shared_code.value}
                        autoComplete='off'
                    />
                </MDBCol>
                <MDBCol className='col-auto d-flex align-items-center'>
                    <MDBBtn rounded outline color='primary' size='sm' className='px-2 waves-effect' onClick={this.onRenewSharedCodeButtonClickHandler}><i className='fas fa-sync mt-0 me-1'></i>YENİLE</MDBBtn>
                </MDBCol>
            </MDBRow>
        </div>);
        }

        return (
            <div>
                <MDBCard className='col-sm-12 col-md-8 col-lg-7 col-xl-5'>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>{this.props.editmode === true ? 'Sınıf Düzenle' : 'Yeni Sınıf Oluştur'}</MDBCardTitle>
                    </MDBCardHeader>
                    <form noValidate>
                        <MDBCardBody className='p-4'>
                            {infoMessageComponent}
                            {sharedCodeManagementComponent}
                            <div className='form-outline'>
                                <MDBSelect
                                    id='grade_id'
                                    label='Sınıf / Kademe'
                                    disabled={this.props.editmode ? true : false}
                                    readOnly={this.props.editmdoe ? true : false}
                                    onClick={this.onGradeComboboxOpenedHandler}
                                    onChange={this.onGradeComboboxSelectHandler}
                                    options={this.state.grades}
                                    className='me-0 pe-0'
                                />
                                <small className='text-muted fw-light my-0 py-0'>{this.state.form_controls.grade_id.instruction}</small>
                                <div className='invalid-feedback'>{this.state.form_controls.grade_id.message}</div>
                            </div>
                            <div className='form-outline'>
                                <MDBInput
                                    type='text'
                                    className={`form-control ${this.state.form_controls.name.is_invalid ? 'is-invalid invalid' : ''}`}
                                    id='name'
                                    label='Sınıf adı'
                                    value={this.state.form_controls.name.value}
                                    autoComplete='off'
                                    onChange={this.formItemOnChangeHandler}
                                >
                                <div className='invalid-feedback'>{this.state.form_controls.name.message}</div>
                                </MDBInput>
                            </div>
                            <div className='form-outline'>
                                <div className='md-form'>
                                <DatePicker
                                    selected={ this.state.selectedDate }
                                    onChange={ this.handleDateChange }
                                    dateFormat='yyyy-MM-dd'
                                    locale='tr-TR'
                                    customInput={<CustomDatePickerInput />}
                                    calendarStartDay={1}
                                />
                                <div className='invalid-feedback'>{this.state.form_controls.name.message}</div>
                                </div>
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' className='me-2' onClick={this.onSaveButtonClickHandler}>KAYDET</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.closeWindowByCancel}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </form>
                    {spinnerWaitComponent}
                </MDBCard>
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageCode: state.mainPageCode,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(VirtualClassEditorComponent);
