import { MDBBtn, MDBCol, MDBCollapse, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import User from '../../apis/User';
import { USER_IS_STUDENT, formatPhoneNumber } from '../../common/common';
import { parse2DErrorArrayTo1DArray } from '../../common/errorhandler';

class UserNotVerifiedComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            isMobileVerificationCollapseShown: false,
            form_controls: {
                code: { value: '', is_invalid: false, message: '', instruction: ''},
            },
        }

        this.onValidateMobilePhoneButtonClicked = this.onValidateMobilePhoneButtonClicked.bind(this);
        this.onResendEmailVerificationUrlButtonClicked = this.onResendEmailVerificationUrlButtonClicked.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
        this.onVerifyAccountWithMobilePhoneBtnClicked = this.onVerifyAccountWithMobilePhoneBtnClicked.bind(this);
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let form_controls_copy = { ...this.state.form_controls };        
        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        if (key === 'code') {
            let regex = /[^0-9]/g;
            let replacement = '';
            value = value.replace(regex, replacement);
            if (value.length > 6) {
                value = value.substring(0, 6);
            }
        } 


        form_control_copy.value = value;
        form_controls_copy[key] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
    }

    onValidateMobilePhoneButtonClicked() {
        this.validateMobileNumber();
    }

    async validateMobileNumber() {
        try {
            let response = await User.validate_mobile_phone_number({code: this.state.form_controls.code.value});
            
            if (response.data.user) {
                this.props.showToastNotification('success', response.data.message);
                this.props.storeAuthenticatedUser(response.data.user);
            } else if (response.data.errors) {
                this.props.showToastNotification('error', response.data.message);
            }
        } catch (error) {
            let message = 'Beklenmeyen hata oluştu';
            let errors = parse2DErrorArrayTo1DArray(error.response.data.errors);
            if (errors !== undefined && Array.isArray(errors)) {
                errors.forEach(msg => {
                    message = msg;
                });
            }
            this.props.showToastNotification('error', message);
        }
    }



    onResendEmailVerificationUrlButtonClicked() {
        this.resendEmailVerificationUrl();
    }

    async resendEmailVerificationUrl() {
        try {
            let response = await User.resend_email_verification_url();
            
            if (!response.data.errors) {
                this.props.showToastNotification('success', response.data.message);
            } else if (response.data.errors) {
                this.props.showToastNotification('error', response.data.message);
            }
        } catch (error) {
            this.props.showToastNotification('error', 'Beklenmeyen hata oluştu');
        }
    }

    formatPhoneNumber(number) {
        return '0 ' + formatPhoneNumber(number);
    }

    onVerifyAccountWithMobilePhoneBtnClicked() {
        this.resendMobileVerificationCode();
        this.setState({ isMobileVerificationCollapseShown: !this.state.isMobileVerificationCollapseShown });
    }
    
    async resendMobileVerificationCode() {
        try {
            let response = await User.resend_mobile_verification_code();
            
            if (!response.data.errors) {
                this.props.showToastNotification('success', response.data.message);
            } else if (response.data.errors) {
                this.props.showToastNotification('error', response.data.message);
            }
        } catch (error) {
            this.props.showToastNotification('error', 'Beklenmeyen hata oluştu');
        }
    }

    render() {
        return (
            <div>
                <h3>Hesap İletişim Bilgilerini Doğrulayınız</h3>
                <p>
                    {this.props.authenticatedUser.role_id === USER_IS_STUDENT ? 'Sevgili' : 'Sayın' } {this.props.authenticatedUser.name} {this.props.authenticatedUser.surname}
                </p>
                <p>
                    Tebrikler! TEAM LMS uygulamasına başarıyla oturum açtınız.
                </p>
                <p>
                    TEAM LMS uygulamasını kullanabilmeniz ve gelecekte olası bir şifre unutma vb durumda hesabınızı kurtarabilmeniz için iletişim bilgilerinizi doğrulamalısınız. 
                </p>
                <p>
                    <strong>{this.props.authenticatedUser.email}</strong> e-posta adresinizin Gelen Kutusuna ve İstenmeyen E-postalar, Junk, Spam klasörlerine göz atıp, size göndermiş olduğumuz e-postadaki düğmeye basarak e-posta adresinizi doğrulayabilirsiniz.
                    <br/>
                    <button type='button' className='btn btn-link m-0 p-0 text-primary' onClick={this.onResendEmailVerificationUrlButtonClicked}>DOĞRULAMA E-POSTASINI YENİDEN GÖNDER</button>
                </p>
                <p>
                    <MDBBtn onClick={this.onVerifyAccountWithMobilePhoneBtnClicked}>HESABIMI CEP TELEFONU İLE ETKİNLEŞTİR</MDBBtn> 
                </p>
                <MDBCollapse open={this.state.isMobileVerificationCollapseShown}>
                    <p>
                        <strong>{this.formatPhoneNumber(this.props.authenticatedUser.mobile_phone)}</strong> cep telefonunuza az önce bir adet SMS doğrulama kodu gönderdik.
                    </p>
                    <p>
                        Doğrulama kodunu aşağıdaki kutuya girip cep telefonumu doğrula düğmesine basarak cep telefonunuzu doğrulayabilir ve hesabınızı etkinleştirip kullanmaya başlayabilirsiniz.
                    </p>
                    <MDBRow className='d-flex align-items-center py-2'>
                        <MDBCol><MDBInput label='SMS Doğrulama Kodu' type='text' id='code' onChange={this.formItemOnChangeHandler} value={this.state.form_controls.code.value}></MDBInput></MDBCol>
                        <MDBCol><MDBBtn color='info' onClick={this.onValidateMobilePhoneButtonClicked}>CEP TELEFONUMU DOĞRULA</MDBBtn></MDBCol>
                    </MDBRow>
                </MDBCollapse>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAuthenticatedUser: (authenticatedUser) => dispatch({ type: 'storeAuthenticatedUser', authenticatedUser: authenticatedUser })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotVerifiedComponent);
