import { Component } from 'react';
import { connect } from 'react-redux';
import { USER_IS_STUDENT } from '../../common/common';

class UserIsDisabledComponent extends Component {

    render() {
        return (
            <div>
                <h3>Hesabınız Yönetici Tarafından Kilitlenmiş</h3>
                <p>
                    {this.props.authenticatedUser.role_id === USER_IS_STUDENT ? 'Sevgili' : 'Sayın' } {this.props.authenticatedUser.name} {this.props.authenticatedUser.surname}
                </p>
                <p>
                    Hesabınız sistem yöneticisi tarafından kilitlenmiş.
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAuthenticatedUser: (authenticatedUser) => dispatch({ type: 'storeAuthenticatedUser', authenticatedUser: authenticatedUser })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserIsDisabledComponent);