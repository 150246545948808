import { Component } from 'react';
import { connect } from 'react-redux';
import { MDBNavbarItem} from 'mdb-react-ui-kit';


import { mainpages } from '../common/common';

class SideBarStudentItemsComponent extends Component {
    constructor(props) {
        super(props);

        this.changeMainPageToNavigatedMainPageHandler = this.changeMainPageToNavigatedMainPageHandler.bind(this);
    }

    changeMainPageToNavigatedMainPageHandler(code) {
        this.props.setMainPageCode(code);
    }
    
    render() {

        return (<>
            <MDBNavbarItem>
                <a href='#!' className='nav-link d-flex align-items-center px-3 m-0'
                    onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.dashboard.code)}}>
                    <i className={`${mainpages.dashboard.icon} fs-3 text-center sidebar-icon`}></i>
                    <span className='d-none d-md-inline ms-2'>{mainpages.dashboard.title}</span>
                </a>

            </MDBNavbarItem>
            <MDBNavbarItem>
                <a href='#!' className='nav-link d-flex align-items-center px-3 m-0'
                    onClick={() => {this.changeMainPageToNavigatedMainPageHandler(mainpages.attendclass.code)}}>
                    <i className={`${mainpages.attendclass.icon} fs-3 text-center sidebar-icon`}></i>
                    <span className='d-none d-md-inline ms-2'>{mainpages.attendclass.title}</span>
                </a>
            </MDBNavbarItem>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarStudentItemsComponent);