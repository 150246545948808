import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput, MDBModalDialog, MDBModalContent, MDBModalTitle, MDBValidation, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import QuizMaker from '../../apis/QuizMaker';

class QuizMakerQuestionsChangeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            modalAskAndSave: false,
            questions: this.props.questions,
            quizName: '',
            quizDescription: ''
        };


        this.changeAQuestion = this.changeAQuestion.bind(this);
        this.changeAQuestionRequest = this.changeAQuestionRequest.bind(this);
        this.toggleAskAndSave = this.toggleAskAndSave.bind(this);

        this.onChangeQuizName = this.onChangeQuizName.bind(this);
        this.onChangeQuizDescription = this.onChangeQuizDescription.bind(this);
        this.onSaveAndCloseBtnClicked = this.onSaveAndCloseBtnClicked.bind(this);
        this.saveQuiz = this.saveQuiz.bind(this);
        

    }

 
    changeAQuestion(index, event) {
        let already_used_ids = [];
        this.state.questions.forEach(question => {
            already_used_ids.push(question.id);
        });

        let request = {
            source_question_id: this.state.questions[index].id,
            source_grade_id: this.state.questions[index].grade.id,
            source_unit_id: this.state.questions[index].units[0].id,
            already_used_ids: already_used_ids
        };

        this.changeAQuestionRequest(index, request);
    }


    async changeAQuestionRequest(index, request) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await QuizMaker.changeAQuestion(request)
            this.setState({ isSpinnerWaitShown: false });
            if (response.data !== undefined) {
                let questions = [ ...this.state.questions ];
                let fetched_questions = response.data;
                if (fetched_questions[0] !== undefined) {
                    questions[index] = fetched_questions[0];
                    this.setState({ questions: questions });
                }
            }
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Soru değiştirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Soru değiştirilemedi. Beklenmedik hata!');
            }
        }
        
    }

    toggleAskAndSave() {
        this.setState({ modalAskAndSave: !this.state.modalAskAndSave });
    }

    onChangeQuizName(event) {
        let value = event.target.value.substring(0, 40);
        this.setState( { quizName: value } );
    }

    onChangeQuizDescription(event) {
        this.setState( { quizDescription: event.target.value } );
    }

    onSaveAndCloseBtnClicked() {

        let request = {
            name: this.state.quizName,
            description: this.state.quizDescription,
            grade_id: this.props.grade_id,
            questions: this.state.questions
        };

        this.saveQuiz(request);

        
    }


    async saveQuiz(request) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            await QuizMaker.saveQuiz(request);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Sınav başarıyla oluşturuldu.');
            this.setState({ modalAskAndSave: false });
            this.props.reloadContentAndResetToInitial();
            
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.errors.name !== undefined) {
                this.props.showToastNotification('error', 'Sınav oluşturulamadı. ' + error.response.data.errors.name[0]);
            } else if (error.response.data.errors.description !== undefined) {
                this.props.showToastNotification('error', 'Sınav oluşturulamadı. ' + error.response.data.errors.description[0]);
            } else if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sınav oluşturulamadı. ' + error.response.data.message);
            }
        }
    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let askNameAndSave = (
            <MDBModal size='md' open={this.state.modalAskAndSave} staticBackdrop>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Sınav Oluştur</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.toggleAskAndSave}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow className='mb-2'>
                                <MDBCol>
                                    <MDBInput label='Sınav adı' onChange={this.onChangeQuizName} value={this.state.quizName} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput label='Sınav açıklaması' onChange={this.onChangeQuizDescription} value={this.state.quizDescription} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="danger" onClick={this.onSaveAndCloseBtnClicked}>KAYDET</MDBBtn>
                            <MDBBtn color="secondary" onClick={this.toggleAskAndSave}>KAPAT</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>);

        return (

            <div>
                <MDBCard>
                    <MDBCardHeader className='px-4 pt-3'>
                        <MDBCardTitle>Sınav Üreteci - Sınav Önizlemesi</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody className='p-4'>
                            <div className='form-outline row'>
                                {this.state.questions.map((resource, index) => {
                                    let color = resource.usage_counter === 0 ? 'text-success' : 'text-danger';
                                    let usage_message = resource.usage_counter === 0 ? 'hiç kullanılmamış' : `${resource.usage_counter} kez kullanılmış`;
                                    return (
                                        <div key={index} className='col-12 col-lg-6 p-4 border-bottom'>
                                            <div className='row'>
                                                <div className='col-auto'>
                                                    <Zoom zoomMargin={20}>
                                                        <img src={`${window.config.downloadserver}/${resource.uuid}/${resource.thumbnail_path}`} alt='thumbnails' style={{display: 'block', width: '150px', height: 'auto'}}/>
                                                    </Zoom>
                                                </div>
                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className='col-* small'>
                                                            {resource.grade.name}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-*'>
                                                            {resource.units.map((unit, key) => {
                                                                return (<div key={key} className='block small'>{unit.name} - {unit.title}</div>);
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-* small'>
                                                            <span className={color}>{usage_message}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-* small'>
                                                            CEVAP: {resource.answers}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-* small'>
                                                            <MDBBtn 
                                                                rounded 
                                                                outline 
                                                                color='primary' 
                                                                size='sm' 
                                                                className='px-2' 
                                                                onClick={(event) => this.changeAQuestion(index, event)}
                                                            >
                                                                <i className='fas fa-sync mt-0'></i>
                                                                <span className='d-none d-lg-inline ms-1'>DEĞİŞTİR</span>
                                                            </MDBBtn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' className='me-2' onClick={this.toggleAskAndSave}>SINAVI OLUŞTUR</MDBBtn>
                            <MDBBtn color='info' className='me-2' onClick={this.props.gotoBackButtonClickCallback}>GERİ</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.props.cancelButtonClickCallback}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                    {askNameAndSave}
                </MDBCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(QuizMakerQuestionsChangeComponent);
