import { Component } from 'react';
import { connect } from 'react-redux';
import { 
    MDBBtn, 
    MDBCol, 
    MDBInput, 
    MDBRow, 
    MDBCard, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBCardHeader,
    MDBValidation, 
    MDBValidationItem
} from 'mdb-react-ui-kit';

import User from '../../apis/User';
import { cloneConfigForFormControl } from '../../common/common';
import AlertComponent from '../AlertComponent';
import SpinnerWaitComponent from '../SpinnerWaitComponent';

class PasswordChangeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            form_controls: {
                current_password: { value: '', is_invalid: false, message: '', instruction: ''},
                new_password: { value: '', is_invalid: false, message: '', instruction: 'En az 8 karakter uzunluğunda'},
                new_password_confirmation: { value: '', is_invalid: false, message: '', instruction: 'Yeni şifrenizle aynı'}
            },
            submit_success: false,
            submit_error: false,
            alert_header: '',
            alert_messages: []
        };

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onChangePasswordClickHandler = this.onChangePasswordClickHandler.bind(this);

    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let form_control_copy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        form_control_copy.value = value;
        let form_controls_copy = { ...this.state.form_controls};
        form_control_copy.is_invalid = false;
        form_control_copy.message = '';
        form_controls_copy[key] = form_control_copy;
        this.setState({ form_controls: form_controls_copy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }

    onChangePasswordClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        this.startPasswordChangeSubmission();
    }

    async startPasswordChangeSubmission() {

        this.setState({ submit_success: false });
        this.setState({ submit_error: false });
        this.setState({ alert_header: '' });
        this.setState({ alert_messages: '' });
        this.setState({ isSpinnerWaitShown: true });

        try {
            let request_data = { current_password: this.state.form_controls.current_password.value,
                new_password: this.state.form_controls.new_password.value,
                new_password_confirmation: this.state.form_controls.new_password_confirmation.value
            };

            let response = await User.changepassword(request_data);

            let form_controls_copy = { ...this.state.form_controls };
            form_controls_copy['current_password'] = cloneConfigForFormControl('current_password', [], form_controls_copy);
            form_controls_copy['new_password'] = cloneConfigForFormControl('new_password', [], form_controls_copy);
            form_controls_copy['new_password_confirmation'] = cloneConfigForFormControl('new_password_confirmation', [], form_controls_copy);
            this.setState({ form_controls: form_controls_copy });

            this.setState({ submit_success: true });
            this.setState({ submit_error: false });
            this.setState({ alert_header: 'Success' });
            this.setState({ alert_messages: response.data.message });
            this.setState({ isSpinnerWaitShown: false });
            // this causes component unmount, don't update state after this call

        } catch(error) {
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_controls_copy['current_password'] = cloneConfigForFormControl('current_password', errors, form_controls_copy);
                form_controls_copy['new_password'] = cloneConfigForFormControl('new_password', errors, form_controls_copy);
                form_controls_copy['new_password_confirmation'] = cloneConfigForFormControl('new_password_confirmation', errors, form_controls_copy);
                this.setState({ form_controls: form_controls_copy });
                this.setState({ submit_success: false });
                this.setState({ submit_error: false });

                if (errors.error !== undefined) { // handle global algoritmical errors
                    this.setState({ alert_header: error.response.data.message });
                    this.setState({ alert_messages: errors.error });
                    this.setState({ submit_success: false });
                    this.setState({ submit_error: true });
                }
            } else {
                // handle catastrophic errors
                this.setState({ alert_header: 'Bilinmeyen hata oluştu' });
                this.setState({ alert_messages: error.toString() });
                this.setState({ submit_success: false });
                this.setState({ submit_error: true });
            }
            this.setState({ isSpinnerWaitShown: false });
        }
    }

    render() {

        let infoMessageComponent = '';
        if (this.state.submit_error && this.state.alert_header !== '') {
            infoMessageComponent = (<AlertComponent 
                                        alert_header={this.state.alert_header} 
                                        alert_messages={this.state.alert_messages} />
                                    );
        } else if (this.state.submit_success) {
            infoMessageComponent = (<AlertComponent 
                                        type='warning'
                                        alert_header='Başarılı' 
                                        alert_messages={this.state.alert_messages} />
                                    );
        }

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        return (
            <div className='col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6'>
                <MDBCard md='12' className='fluid'>
                    <MDBValidation noValidate>
                    <MDBCardHeader>
                        <h5>Şifre Değiştirme</h5>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol className='mb-3 d-flex justify-content-start'>
                                Mevcut şifrenizi aşağıdaki form yardımı ile değiştirebilirsiniz.
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className='mb-3 d-flex justify-content-center'>
                                {infoMessageComponent}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className='mb-4'>
                                <MDBValidationItem feedback={this.state.form_controls.current_password.message} invalid>
                                    <MDBInput 
                                        type='password' 
                                        id='current_password'
                                        autoFocus
                                        autoComplete='off'
                                        label='Mevcut şifrenizi doğrulayınız'
                                        onChange={this.formItemOnChangeHandler}
                                        required
                                        value={this.state.form_controls.current_password.value}
                                        className={this.state.form_controls.current_password.is_invalid ? 'is-invalid':''}
                                    />
                                </MDBValidationItem>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className='mb-4'>
                                <MDBValidationItem feedback={this.state.form_controls.new_password.message} invalid>
                                    <MDBInput 
                                        type='password' 
                                        id='new_password'
                                        autoComplete='off'
                                        label='Yeni şifrenizi belirleyiniz'
                                        onChange={this.formItemOnChangeHandler}
                                        required
                                        aria-describedby='new_password_info'
                                        value={this.state.form_controls.new_password.value}
                                        className={this.state.form_controls.new_password.is_invalid ? 'is-invalid':''}
                                    />
                                </MDBValidationItem>
                                <div id='new_password_info' className='form-text pt-2'>
                                    {this.state.form_controls.new_password.instruction}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className='mb-4'>
                                <MDBValidationItem feedback={this.state.form_controls.new_password_confirmation.message} invalid>
                                    <MDBInput 
                                        type='password' 
                                        id='new_password_confirmation'
                                        autoComplete='off'
                                        label='Yeni şifrenizi doğrulayınız'
                                        onChange={this.formItemOnChangeHandler}
                                        required
                                        aria-describedby='new_password_confirmation_info'
                                        value={this.state.form_controls.new_password_confirmation.value}
                                        className={this.state.form_controls.new_password_confirmation.is_invalid ? 'is-invalid':''}
                                    />
                                </MDBValidationItem>
                                <div id='new_password_confirmation_info' className='form-text pt-2'>
                                    {this.state.form_controls.new_password_confirmation.instruction}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBBtn color='primary' className='me-3' onClick={this.onChangePasswordClickHandler}>ŞİFREYİ DEĞİŞTİR</MDBBtn>
                    </MDBCardFooter>
                    </MDBValidation>
                </MDBCard>
                {spinnerWaitComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageCode: state.mainPageCode
    }
};

export default connect(mapStateToProps)(PasswordChangeComponent);
