import Api from './Api';
import Csrf from './Csrf';


const VirtualClass = {
    async fetchVirtualClassesByOwner() {
        await Csrf.getCookie();
        return Api.get('/virtualclassesbysessionowner');
    },

    async createVirtualClass(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/create', form_items);
    },

    async updateVirtualClass(form_items) {
        await Csrf.getCookie();
        return Api.put('/virtualclasses/update', form_items);
    },

    async renewSharedCode(form_items) {
        await Csrf.getCookie();
        return Api.put('/virtualclasses/updatesharedcode', form_items);
    },

    async deleteVirtualClass(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/delete', form_items);
    },

    async fetchClassInformationBySharedCode(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/infobysharedcode', form_items);
    },

    async requestJoinToClassWithSharedCode(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/requestattendwithsharedcode', form_items);
    },

    async fetchVirtualClassesByAttendee() {
        await Csrf.getCookie();
        return Api.get('/virtualclassesbyattendee');
    },

    async fetchAttendeesByClassId(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/attendeesbyclassid', form_items);
    },

    async acceptAttendeeToClass(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/acceptattendee', form_items);
    },

    async removeAttendeeFromClass(form_items) {
        await Csrf.getCookie();
        return Api.post('/virtualclasses/removeattendee', form_items);
    }





};

export default VirtualClass;