import Api from './Api';
import Csrf from './Csrf';


const ExamMaker = {
    async fetchAllExamsOfUser() {
        await Csrf.getCookie();
        return Api.get('/exammaker/all');
    },

    async fetchExamNewExercises(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/get_new_exercises', form_items);
    },

    async changeAnExercise(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/change_an_exercise', form_items);
    },

    async saveExam(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/save_exam', form_items);
    },

    async fetchExam(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/get_exam', form_items);
    },

    async downloadExam(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/download', form_items);
    },

    async downloadAnswerKey(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/download_answer_key', form_items);
    },

    async checkExamCreationQuota(form_items) {
        await Csrf.getCookie();
        return Api.post('/exammaker/check_exam_creation_quota', form_items);
    },
    
};

export default ExamMaker;