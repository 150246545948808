import Api from './Api';
import Csrf from './Csrf';

const Grade = {
    async fetchAll() {
        await Csrf.getCookie();
        return Api.get('/grades');
    },

    async attachGradesToUser(form_items) {
        await Csrf.getCookie();
        return Api.put('/grades/attach', form_items);
    },

    async fetchAttachedGradesForUser() {
        await Csrf.getCookie();
        return Api.get('/grades/getusergrades');
    },

    async fetchGradesWithUnits() {
        await Csrf.getCookie();
        return Api.get('/grades/units');
    }

};

export default Grade;