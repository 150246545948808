import { MDBBtn, MDBIcon, MDBDatatable } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';

import { formatPhoneNumber, mainpages, USER_IS_ADMIN, USER_IS_STUDENT, USER_IS_TEACHER } from '../../../common/common';
import User from '../../../apis/User';
import AdminUserViewerComponent from './AdminUserViewerComponent';
import AdminUserEditorComponent from './AdminUserEditorComponent';

class AdminUserManagementComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmDeleteShown: false,
            deleteCandidateResource: null,
            isUserEditorComponentTriggered: false,
            isUserViewerComponentTriggered: false,
            userBeingEdited: null,
            userBeingViewed: null,
            rows: [],
            published_value: '',
            available_for_value: '',
            units_value: []
        };

        this.all_grades_with_units = [];
        this.all_resource_available_for = [];

        this.columns = [
            {
                label: 'Ad Soyad',
                field: 'name_surname',
                sort: true,
                searchable: true
            },
            {
                label: 'Kullanıcı Adı',
                field: 'username',
                sort: true,
                searchable: true
            },
            {
                label: 'E-posta',
                field: 'email',
                sort: true,
                searchable: true
            },
            {
                label: 'Cep Tel',
                field: 'mobile_phone',
                sort: true,
                searchable: true
            },
            {
                label: 'Doğrulama',
                field: 'is_verified',
                sort: true,
                searchable: true
            },            {
                label: 'Güncellenme',
                field: 'updated_at',
                sort: true,
                searchable: true
            },
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false
            }
        ];

        this.addNewUserButtonClickHandler = this.addNewUserButtonClickHandler.bind(this);
        this.userEditorComponentCloseCallback = this.userEditorComponentCloseCallback.bind(this);
        this.editUserButtonClickHandler = this.editUserButtonClickHandler.bind(this);
        this.userViewerComponentCloseCallback = this.userViewerComponentCloseCallback.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);

    }

    componentDidMount() {
        this.fetchAllUsers();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.menucode !== this.props.menucode) {
            this.fetchAllUsers();
            this.setState({ isUserEditorComponentTriggered: false });
            this.setState({ isUserViewerComponentTriggered: false });
            this.setState({ userBeingEdited: null });
            this.setState({ userBeingViewed: null });
        }
    }

    formatPhoneNumber(phone) {
        return '0 ' + formatPhoneNumber(phone);
    }

    async fetchAllUsers(filter) {

        let mainpageitem = mainpages[this.props.menucode];

        let type = mainpageitem.type;
        let subtype = mainpageitem.subtype;

        let filterLocal = {};
        if (filter === undefined && type === 'users' && subtype === 'teachers') {
            filterLocal = { role_id: USER_IS_TEACHER };
        } else if (filter === undefined && type === 'users' && subtype === 'admins') {
            filterLocal = { role_id: USER_IS_ADMIN };
        } else {
            filterLocal = { role_id: USER_IS_STUDENT };
        }


        this.setState({ isSpinnerWaitShown: true });
        try {

            let response = await User.fetch_users(filterLocal);
            let rows = [];
            response.data.forEach(user => {
                let item = {
                    name_surname: user.name + ' ' + user.surname,
                    username: user.username,
                    email: user.email,
                    mobile_phone: this.formatPhoneNumber(user.mobile_phone),
                    is_verified: user.is_verified ? 'Doğrulanmış' : 'Doğrulanmamış',
                    updated_at: Moment(user.updated_at).format('YYYY-MM-DD HH:mm'),
                    actions: (<div>
                                <MDBBtn outline rounded color='primary' size='sm' className='px-2 mx-1' onClick={() => this.viewUserButtonClickHandler(user)}><MDBIcon fas icon='eye' /><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                                <MDBBtn outline rounded color='success' size='sm' className='px-2 mx-1' onClick={() => this.editUserButtonClickHandler(user)}><MDBIcon fas icon='pen' /><span className='d-none d-lg-inline ms-1'>DÜZENLE</span></MDBBtn>
                            </div>)
                };
                rows.push(item);
            });
            this.setState({ rows: rows });
        } catch(error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    editUserButtonClickHandler(resource) {
        this.setState({ isUserViewerComponentTriggered: false });
        this.setState({ userBeingEdited: resource });
        this.setState({ isUserEditorComponentTriggered: true });
    }

    userEditorComponentCloseCallback() {
        this.setState({ isUserEditorComponentTriggered: false });
        this.setState({ userBeingEdited: null });
        this.fetchAllUsers();
    }

    viewUserButtonClickHandler(resource) {
        this.setState({ userBeingViewed: resource });
        this.setState({ isUserViewerComponentTriggered: true });
    }

    userViewerComponentCloseCallback() {
        this.setState({ isUserViewerComponentTriggered: false });
        this.setState({ userBeingViewed: null });
    }

    addNewUserButtonClickHandler() {
        this.setState({ isUserEditorComponentTriggered: true });
    }


    render() {


        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.isUserEditorComponentTriggered) {
            return (<AdminUserEditorComponent menucode={this.props.menucode} closeCallback={this.userEditorComponentCloseCallback} userBeingEdited={this.state.userBeingEdited} />);
        }

        if (this.state.isUserViewerComponentTriggered) {
            return (<AdminUserViewerComponent menucode={this.props.menucode} closeCallback={this.userViewerComponentCloseCallback} editorCallBack={this.editUserButtonClickHandler} userBeingViewed={this.state.userBeingViewed} />);
        }

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        title = mainpageitem.title;

        return (
            <div>
                <h3>{title}</h3>
                <p>Kullanıcı hesaplarını buradan yönetebilirsiniz.</p>

                <div className='mt-4 p-4 border rounded'>
                    <MDBDatatable 
                        search
                        sm
                        hover
                        fullPagination
                        rowsText='Sayfadaki satır sayısı'
                        data={{columns: this.columns, rows: this.state.rows}} />
                </div>
                {spinnerWaitComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
    }
};

export default connect(mapStateToProps)(AdminUserManagementComponent);
