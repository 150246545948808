import { MDBBtn, MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import Attributes from '../../apis/Attributes';
import Moment from 'moment';
import Grade from '../../apis/Grade';
import ExamMakerGradeUnitsSelectComponent from './ExamMakerGradeUnitsSelectComponent';
import SpinnerWaitComponent from '../SpinnerWaitComponent';
import ExamMaker from '../../apis/ExamMaker';
import ExamMakerPreviewExamComponent from './ExamMakerPreviewExamComponent';
import { USER_IS_ADMIN } from '../../common/common';

class ExamMakerComponent extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                label: 'Sınav Türü',
                field: 'examtype',
                sort: true,
                searchable: true
            },
            {
                label: 'Sınıf',
                field: 'class',
                sort: true,
                searchable: true
            },
            {
                label: 'Sınav Adı',
                field: 'name',
                sort: true,
                searchable: true
            },
            {
                label: 'Tarih',
                field: 'datetime',
                sort: true,
                searchable: true
            },           
            {
                label: 'Eylemler',
                field: 'actions',
                sort: false,
                searchable: false,
                
            }
        ];

        this.resource_group_types = [];
        this.resource_group_types["downloadable_exam"] = "Okuma Yazma Yazılı Sınavı";
        this.resource_group_types["downloadable_listening_exam"] = "Dinleme Yazılı Sınavı";
        

        this.state = {
            isSpinnerWaitShown: false,
            attributes: [],
            generateNewExamButtonClicked: false,
            generateNewListeningExamButtonClicked: false,
            previewExam: null,
            previewResources: [],
            previewExamButtonClicked: false,
            exammaker_enddate: '01.01.1970',
            exammaker_startdate: '01.01.2050',
            previousExams: [],
            rows: []
        };

        this.all_grades_with_units = [];

        this.generateNewExamButtonClickHandler = this.generateNewExamButtonClickHandler.bind(this);
        this.generateNewListeningExamButtonClickHandler = this.generateNewListeningExamButtonClickHandler.bind(this);
        this.closeRequestButtonClicked = this.closeRequestButtonClicked.bind(this);
        this.downloadExam = this.downloadExam.bind(this);
        this.downloadExamAnswerKey = this.downloadExamAnswerKey.bind(this);
        this.fetchUserAttributes = this.fetchUserAttributes.bind(this);

        this.reloadContentAndResetToInitial = this.reloadContentAndResetToInitial.bind(this);
        this.previewExam = this.previewExam.bind(this);
        this.fetchUserAttributes = this.fetchUserAttributes.bind(this);

    }


    componentDidMount() {
        this.reloadContentAndResetToInitial();
        this.fetchUserAttributes();
    }

    
    generateNewExamButtonClickHandler() {
        this.setState({ generateNewExamButtonClicked: true });
    }

    generateNewListeningExamButtonClickHandler() {
        this.setState({ generateNewListeningExamButtonClicked: true });
    }

    async fetchUserAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_items = {
                user_id: this.props.authenticatedUser.id
            };
            let response = await Attributes.fetchAllUserAttributes(form_items);
            let attributes = response.data;
            this.setState({ isSpinnerWaitShown: false });
            this.setState({ attributes: attributes });
            attributes.forEach(attribute => {
                if (attribute.code === 'exammaker_max_questions_per_exam') {
                    let value = parseInt(attribute.user_attribute_value, 10);
                    this.setState( { exammaker_max_questions_per_exam: value } );
                } else if (attribute.code === 'exammaker_startdate') {
                    let value = attribute.user_attribute_value;
                    let isValidDate = Moment(attribute.user_attribute_value, "DD.MM.YYYY", true).isValid();
                    if (isValidDate) {
                        this.setState( { exammaker_startdate: value } );
                    } else {
                        this.setState( { exammaker_startdate: '01.01.2050' } );
                    }
                } else if (attribute.code === 'exammaker_enddate') {
                    let value = attribute.user_attribute_value;
                    let isValidDate = Moment(attribute.user_attribute_value, "DD.MM.YYYY", true).isValid();
                    if (isValidDate) {
                        this.setState( { exammaker_enddate: value } );
                    } else {
                        this.setState( { exammaker_enddate: '01.01.1970' } );
                    }
                } else if (attribute.code === 'exammaker_exam_count_per_class') {
                    let value = parseInt(attribute.user_attribute_value, 10);
                    this.setState( { exammaker_exam_count_per_class: value } );
                }
            });
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Kullanıcı Tercihleri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Kullanıcı Tercihleri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }

    }


    downloadExam(resource) {
        this.downloadGeneratedExam(resource);
    }  

    
    async downloadGeneratedExam(resource) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                id: resource.id
            };
            let response = await ExamMaker.downloadExam(request);
            this.setState({ isSpinnerWaitShown: false });


            let regex = /[^a-zA-ZĞÜŞİÖÇğüşöçı0-9_.-]/g;
            let downloadname = resource.name.substring(0, 40).replaceAll(regex, '_') + '.zip';


            const myUrl = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
            const url = myUrl.createObjectURL(new Blob([this._base64ToArrayBuffer(response.data)], 
                                                {type: 'application/zip'}));

            const link = document.createElement('a');
            link.href = url;

            link.target='_blank';
            link.setAttribute('download', `${downloadname}`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            myUrl.revokeObjectURL(url);

        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Yazılılar sunucudan indirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Yazılılar sunucudan indirilemedi. Bilinmeyen hata!');
            }
        }
    }

    downloadExamAnswerKey(resource) {
        this.downloadGeneratedExamAnswerKey(resource);
    }  

    async downloadGeneratedExamAnswerKey(resource) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                id: resource.id
            };
            let response = await ExamMaker.downloadAnswerKey(request);
            this.setState({ isSpinnerWaitShown: false });


            let regex = /[^a-zA-ZĞÜŞİÖÇğüşöçı0-9_.-]/g;
            let downloadname = resource.name.substring(0, 40).replaceAll(regex, '_') + '_answer_key.docx';


            const myUrl = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
            const url = myUrl.createObjectURL(new Blob([this._base64ToArrayBuffer(response.data)], 
                                                {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));

            const link = document.createElement('a');
            link.href = url;

            link.target='_blank';
            link.setAttribute('download', `${downloadname}`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            myUrl.revokeObjectURL(url);

        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Yazılılar sunucudan indirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Yazılılar sunucudan indirilemedi. Bilinmeyen hata!');
            }
        }
    }

    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }


    async fetchAllExams() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await ExamMaker.fetchAllExamsOfUser();
            this.setState({ isSpinnerWaitShown: false });
            let rows = [];
            response.data.forEach((resource_group, index) => {
                let item = {
                    examtype: this.resource_group_types[resource_group.type] ? this.resource_group_types[resource_group.type] : resource_group.type,
                    class: resource_group.grade.name,
                    name: resource_group.name,
                    datetime: Moment(resource_group.updated_at).format('YYYY-MM-DD HH:mm'),
                    actions: (<div key={index}>
                            <MDBBtn outline rounded color='primary' size='sm' className='px-2 mx-1' onClick={() => this.previewExam(resource_group)}><MDBIcon fas icon='eye' /><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                            <MDBBtn outline rounded color='success' size='sm' className='px-2 mx-1' onClick={() => this.downloadExam(resource_group)}><MDBIcon fas icon='file-download' /><span className='d-none d-lg-inline ms-1'>İNDİR</span></MDBBtn>
                    </div>)
                };
                rows.push(item);
            });
            this.setState({ rows: rows });
            this.setState({ previousExams: response.data});
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Yazılılar sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Yazılılar sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }
    }


    async fetchGradesWithUnits() {
        if (this.props.all_grades_with_units.length > 0) {
            this.all_grades_with_units = this.props.all_grades_with_units;
            return;
        }
        if (this.all_grades_with_units.length > 0) {
            return;
        }
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Grade.fetchGradesWithUnits();
            this.setState({ isSpinnerWaitShown: false });
            this.all_grades_with_units = response.data;
            this.props.storeAllGradesWithUnits(this.all_grades_with_units);
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sınıf ve ünite bilgileri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sınıf ve ünite bilgileri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }
        
    }


    closeRequestButtonClicked() {
        this.setState({ generateNewExamButtonClicked: false });
        this.setState({ generateNewListeningExamButtonClicked: false });
        this.setState({ previewExam: null });
        this.setState({ previewResources: [] });
        this.setState({ previewExamButtonClicked: false });
    }


    reloadContentAndResetToInitial() {
        this.setState({ generateNewExamButtonClicked: false });
        this.setState({ generateNewListeningExamButtonClicked: false });

        this.fetchGradesWithUnits();
        this.fetchAllExams();
    }


    previewExam(resource) {
        this.previewGeneratedExam(resource);
    }

    async previewGeneratedExam(resource) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = {
                id: resource.id
            };
            let response = await ExamMaker.fetchExam(request);
            this.setState({ isSpinnerWaitShown: false });

            this.setState({ previewExam: response.data.resourceGroup });
            this.setState({ previewResources: response.data.resources });
            this.setState({ previewExamButtonClicked: true });
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Yazılı sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Yazılı sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }
    }

    render() {

        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        if (this.state.generateNewExamButtonClicked) {
            return (<ExamMakerGradeUnitsSelectComponent closeCallback={this.closeRequestButtonClicked} 
                previousExams={this.state.previousExams}
                examType="downloadable_exam"
                reloadContentAndResetToInitial={this.reloadContentAndResetToInitial} />);
        }

        if (this.state.generateNewListeningExamButtonClicked) {
            return (<ExamMakerGradeUnitsSelectComponent closeCallback={this.closeRequestButtonClicked} 
                previousExams={this.state.previousExams}
                examType="downloadable_listening_exam"
                reloadContentAndResetToInitial={this.reloadContentAndResetToInitial} />);
        }


        if (this.state.previewExamButtonClicked) {
            return (<ExamMakerPreviewExamComponent
                previewExam={this.state.previewExam}
                previewResources={this.state.previewResources}
                downloadExamCallback={this.downloadExam}
                downloadExamAnswerKeyCallback={this.downloadExamAnswerKey}
                closeCallback={this.closeRequestButtonClicked} />);
        }


        let startdate = Moment(this.state.exammaker_startdate, 'DD.MM.YYYY', true).unix();
        let enddate = Moment(this.state.exammaker_enddate, 'DD.MM.YYYY', true).unix();
        let today = Moment().unix();

        let isExammakerAvailable = ((startdate <= today) && (today <= enddate));

        
        if (this.props.authenticatedUser.role_id === USER_IS_ADMIN) {
            isExammakerAvailable = true;
        }

        return (
            <>
                <h3>Yazılı Üreteci</h3>
                <p>Online olarak yazılılar oluşturup, Microsoft Word formatında indirip sınıflarınızda yazılı olarak uygulayabilirsiniz.</p>

                {!isExammakerAvailable && <>
                Yazılı üretecini {this.state.exammaker_startdate} - {this.state.exammaker_enddate} tarihleri arasında kullanabileceksiniz.</>}
                {isExammakerAvailable && <>
                    <MDBBtn color='primary' onClick={this.generateNewExamButtonClickHandler}>YENİ YAZILI ÜRET</MDBBtn>&nbsp;&nbsp;
                    <MDBBtn color='primary' onClick={this.generateNewListeningExamButtonClickHandler}>YENİ DİNLEME YAZILISI ÜRET</MDBBtn>
                    <div className='mt-4 p-4 border rounded'>
                        <MDBDatatable 
                                search
                                sm
                                hover
                                fullPagination
                                rowsText='Sayfadaki satır sayısı'
                                data={{columns: this.columns, rows: this.state.rows}} />
                    </div>
                </>}
                {spinnerWaitComponent}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        all_grades_with_units: state.all_grades_with_units,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAllGradesWithUnits: (all_grades_with_units) => dispatch({ type: 'storeAllGradesWithUnits', all_grades_with_units: all_grades_with_units })
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ExamMakerComponent);