import { MDBBtn, MDBCol, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';
import Attributes from '../../../apis/Attributes';

class SystemAttributesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSpinnerWaitShown: false,
            attributes: [],
            form_controls: {
            },
        };

        this.fetchSystemAttributes = this.fetchSystemAttributes.bind(this);
        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.formResetButtonClickHandler = this.formResetButtonClickHandler.bind(this);
        this.formSaveButtonClickHandler = this.formSaveButtonClickHandler.bind(this);
        this.saveSystemAttributes = this.saveSystemAttributes.bind(this);
    }

    componentDidMount() {
        this.fetchSystemAttributes();
    }

    async fetchSystemAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Attributes.fetchAllSystemAttributes();
            let attributes = response.data;
            this.setState({ isSpinnerWaitShown: false });

            let form_controls = {};
            attributes.forEach(attribute => {
                form_controls[attribute.code] = {value: attribute.default_value, is_invalid: false, message: '', instruction: attribute.description};
            });

            this.setState({ form_controls: form_controls });
            this.setState({ attributes: attributes });

            
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sistem Özellikleri sunucudan getirilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sistem Özellikleri sunucudan getirilemedi. Bilinmeyen hata!');
            }
        }

    }

    formSaveButtonClickHandler() {
        this.saveSystemAttributes();
    }

    async saveSystemAttributes() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let form_items = {};
            this.state.attributes.forEach(attribute => {
                form_items[attribute.code] = this.state.form_controls[attribute.code].value;
            });
            await Attributes.saveAllSystemAttributes(form_items);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Sistem Özellikleri başarıyla kaydedildi.');
            this.fetchSystemAttributes();
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response.data.message !== undefined) {
                this.props.showToastNotification('error', 'Sistem Özellikleri kaydedilemedi. ' + error.response.data.message);
            } else {
                this.props.showToastNotification('error', 'Sistem Özellikleri kaydedilemedi. Bilinmeyen hata!');
            }
        }
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;
        let oldValue = event.target.defaultValue;

        const regex = /^[0-9]*$/;
        if (key === 'quizmaker_max_questions_per_quiz' || key === 'quizmaker_max_quizzes_per_week') {
            if (regex.test(value) === false) {
                value = oldValue;
            }
        }

        let formControlCopy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        formControlCopy.value = value;
        let formControlsCopy = { ...this.state.form_controls};
        formControlsCopy[key] = formControlCopy;
        this.setState({ form_controls: formControlsCopy }); // reference is changed, now all visible parts will be refreshed
    }

    formResetButtonClickHandler() {
        let attributes = this.state.attributes;
        let form_controls = {};
        attributes.forEach(attribute => {
            form_controls[attribute.code] = {value: attribute.default_value, is_invalid: false, message: '', instruction: attribute.description};
        });

        this.setState({ form_controls: form_controls });
    }

    render() {

        let attributes = this.state.attributes;
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }


        let disabled = true;
        this.state.attributes.forEach(attribute => {
            if (attribute.default_value !== this.state.form_controls[attribute.code].value) {
                disabled = false;
            }
        });

        return (
            <>
                <h3>Sistem Özellikleri</h3>
                <p>Sistem özellikleri ve ön tanımlı değerler burada düzenlenir.</p>
                <br />
                {attributes.length === 0 ? <div>Sistem Özellikleri Yükleniyor... Lütfen Bekleyiniz...</div> :
                attributes.map((attribute, index) => {
                    let code = attribute.code;
                    let value = attribute.default_value;
                    if (this.state.form_controls[code] !== undefined) {
                        value = this.state.form_controls[code].value;
                    }
                    return (
                        <MDBRow key={index} >
                            <MDBCol className='mb-4'>
                                <MDBInput
                                    id={attribute.code}
                                    label={attribute.name} 
                                    onChange={this.formItemOnChangeHandler}
                                    value={value} 
                                    aria-describedby={`${attribute.code}_info`}
                                />
                                <div id={`${attribute.code}_info`} className='form-text'>
                                    <small className='fw-light'>{attribute.description}</small>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                })}
                <MDBBtn color='primary' className='me-3' disabled={disabled} onClick={this.formSaveButtonClickHandler}>KAYDET</MDBBtn>
                <MDBBtn color='secondary' disabled={disabled} onClick={this.formResetButtonClickHandler}>SİL BAŞTAN</MDBBtn>

                {spinnerWaitComponent}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(SystemAttributesComponent);