import React, { useState, useRef, useEffect } from 'react';
import useMediaQuery from '../common/useMediaQuery';
import NavigationBarComponent from '../components/NavigationBarComponent';
import SideBarComponent from '../components/SideBarComponent';
import MainPageContainer from './MainPageContainer';
import { useSelector, useDispatch } from 'react-redux';


export default function RootPageStructureContainer() {

  const sidenavContent = useRef(null);
  const [container, setContainer] = useState();
  const isSmallScreen = useMediaQuery('only screen and (max-width: 768px)');
  const [isSidenavConstant] = useState(!isSmallScreen);
  const isSidenavOpened = useSelector((state) => state.isSidenavOpened);
  const dispatch = useDispatch();


  useEffect(() => {
    setContainer(sidenavContent.current);
  }, []);

  function setSidenavOpen(value) {
    dispatch({ type: 'setIsSidenavOpened', isSidenavOpened: value});
  }

  return (
    <>
      <header>
        <NavigationBarComponent setSidenavOpen={setSidenavOpen} isSidenavOpened={isSidenavOpened} />
        <SideBarComponent setSidenavOpen={setSidenavOpen} isSidenavOpened={isSidenavOpened}
                          container={container} isSmallScreen={isSmallScreen} isSidenavConstant={isSidenavConstant} />
      </header>
      <main>
        <div style={{ marginTop: '60px', padding: '5px' }} className='text-start'>
          <div ref={sidenavContent} className='d-flex text-start justify-content-start'>
            <MainPageContainer />
          </div>
        </div>
      </main>
    </>
  );
}
