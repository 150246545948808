import { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBContainer, 
    MDBNavbar, 
    MDBNavbarBrand, 
    MDBNavbarNav, 
    MDBNavbarItem, 
    MDBDropdown, 
    MDBDropdownToggle, 
    MDBDropdownMenu, 
    MDBDropdownItem,
    MDBIcon, 
    MDBNavbarLink
} from 'mdb-react-ui-kit';


import User from '../apis/User';
import { mainpages } from '../common/common';

import product_logo from '../images/teamlms_logo_monochrome.svg';

class NavigationBarComponent extends Component {
    logoutHandler() {
        this.logout();
    }

    async logout() {
        try {
            await User.logout();
        } catch (error) {
            // do nothing
        }
        this.props.logout(); // logout even if exception occurs.
    }

    changeMainPageToChangePasswordHandler() {
        this.props.setMainPageCode(mainpages.changepassword.code);
    }

    changeMainPageToEditProfileHandler() {
        this.props.setMainPageCode(mainpages.editprofile.code);
    }

    render() {

        let formattedUserNameSurname = this.props.authenticatedUser.name + ' ' + this.props.authenticatedUser.surname;
        if (formattedUserNameSurname.length > 25) {
            formattedUserNameSurname = formattedUserNameSurname.substring(0, 22) + '...';
        }
        return (
            <>
                <MDBNavbar expand='sm' fixed='top' light style={{backgroundColor: '#bcbec0'}} >
                    <MDBContainer fluid>
                        <MDBNavbarBrand onClick={() => this.props.setSidenavOpen(!this.props.isSidenavOpened)}>
                            <MDBIcon fas icon='bars'className='ms-1 me-4'/>
                            <img src={product_logo} alt='TEAM LMS Logo' height='30'/>
                        </MDBNavbarBrand>
                        <MDBNavbarNav className='d-flex justify-content-end'>
                            <MDBNavbarItem className='d-none d-sm-block'>
                            <MDBDropdown>
                                <MDBDropdownToggle tag='a' className='nav-link' style={{cursor: 'pointer', color: '#000000'}}>
                                    <span className='text-truncate' >
                                        {formattedUserNameSurname}
                                    </span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu style={{zIndex: '1050'}}>
                                <MDBDropdownItem>
                                    <MDBNavbarLink onClick={this.changeMainPageToEditProfileHandler.bind(this)}>Profili Düzenle</MDBNavbarLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <MDBNavbarLink onClick={this.changeMainPageToChangePasswordHandler.bind(this)}>Şifre Değiştir</MDBNavbarLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <hr className='dropdown-divider' />
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <MDBNavbarLink onClick={this.logoutHandler.bind(this)}>Oturumu Kapat</MDBNavbarLink>
                                </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBContainer>  
                </MDBNavbar>
            </>);
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch({ type: 'logout' }),
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode})
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(NavigationBarComponent);