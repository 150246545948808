import { 
    MDBBtn, 
    MDBCard, 
    MDBCardHeader, 
    MDBCardTitle, 
    MDBCardBody, 
    MDBCardFooter, 
    MDBRow, 
    MDBInput, 
    MDBCol 
} from 'mdb-react-ui-kit';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import Province from '../../../apis/Province';
import { formatPhoneNumber, mainpages } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';

class AdminUserViewerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            provinces: [],
            towns: []
        };

        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    }



    onProvincesComboboxOpenedHandler(event) {
        this.fetchProvinces();
    }

    async fetchProvinces() {
        if (this.state.provinces.length > 0) {
            return;
        }

        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Province.fetchAll();
            this.setState({ isSpinnerWaitShown: false });
            let provinces = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let checked = this.props.authenticatedUser.province_id === item.id ? true : false;
                let option = { text: item.province_name, 
                               value: strId,
                               icon: null,
                               disabled: false,
                               checked: checked
                               };
                provinces.push(option);
            });
            this.setState( { provinces: provinces } );
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response !== undefined && error.response.status === 401) {
                this.setState({ isSpinnerWaitShown: false });
                this.props.storeAuthenticatedUser(null); 
                return;
            }
        }

    }

    onProvinceComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }

        let form_control = { ...this.state.form_controls.province_id };
        let selectedItemNumeric;
        selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.province_id = form_control;
        this.setState({ form_controls: form_controls });

        this.fetchTowns(selectedItemNumeric);
        
    }

    async fetchTowns(province_id) {
        try {
            let request = { id: province_id };
            this.setState({ isSpinnerWaitShown: true });
            let response = await Province.fetchTownByProvinceId(request);
            this.setState({ isSpinnerWaitShown: false });
            let towns = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let checked = this.props.authenticatedUser.town_id === item.id ? true : false;
                let option = { text: item.town_name, 
                               value: strId,
                               icon: null,
                               disabled: false,
                               checked: checked
                             };
                towns.push(option);
            });
            this.setState( { towns: towns } );
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
            if (error.response !== undefined && error.response.status === 401) {
                this.setState({ isSpinnerWaitShown: false });
                this.props.storeAuthenticatedUser(null); 
                return;
            }
        }

    }

    onTownComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined) {
            return;
        }
        let selectedItemNumeric;
        selectedItemNumeric = parseInt(selectedItem.value);
        let form_control = { ...this.state.form_controls.town_id };

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.town_id = form_control;
        this.setState({ form_controls: form_controls });
    }

    cancelButtonClickHandler() {
        this.props.closeCallback();
    }

    formatPhoneNumber(phone) {
        return '0 ' + formatPhoneNumber(phone);
    }


    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }


        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        if (mainpageitem.subtype === 'admins') {
            title = 'Yönetici Hesabı İncele';
        } else if (mainpageitem.subtype === 'teachers') {
            title = 'Öğretmen Hesabı İncele';
        } else if (mainpageitem.subtype === 'students') {
            title = 'Öğrenci Hesabı İncele';
        }
        

        return (
            <>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>{title}</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='name'
                                            label='Ad'
                                            type='text'
                                            value={this.props.userBeingViewed.name}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='surname'
                                            label='Soyad'
                                            type='text'
                                            value={this.props.userBeingViewed.surname}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='email'
                                            label='E-posta'
                                            type='text'
                                            value={this.props.userBeingViewed.email}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='mobile_phone'
                                            label='Cep telefonu'
                                            type='text'
                                            value={this.formatPhoneNumber(this.props.userBeingViewed.mobile_phone)}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='email_verified_at'
                                            label='E-posta doğrulama zamanı'
                                            type='text'
                                            value={this.props.userBeingViewed.email_verified_at === null ? 'Doğrulanmadı' : moment(this.props.userBeingViewed.email_verified_at).format('YYYY-MM-DD HH:mm')}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='mobile_phone_verified_at'
                                            label='Cep telefonu doğrulama zamanı'
                                            type='text'
                                            value={this.props.userBeingViewed.mobile_phone_verified_at === null ? 'Doğrulanmadı' : moment(this.props.userBeingViewed.mobile_phone_verified_at).format('YYYY-MM-DD HH:mm')}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='username'
                                            label='Kullanıcı adı'
                                            type='text'
                                            value={this.props.userBeingViewed.username}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='gdpr_accepted_at'
                                            label='KVKK kabul zamanı'
                                            type='text'
                                            value={moment(this.props.userBeingViewed.gdpr_accepted_at).format('YYYY-MM-DD HH:mm')}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='is_verified'
                                            label='Hesap etkinleştirildi mi?'
                                            type='text'
                                            value={this.props.userBeingViewed.is_verified ? 'Etkinleştirildi' : 'Etkinleştirilmemiş'}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='school_name'
                                            label='Okul adı'
                                            type='text'
                                            value={this.props.userBeingViewed.school_name !== null ? this.props.userBeingViewed.school_name : 'Okul adı belirtilmemiş'}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='province_name'
                                            label='İl Adı'
                                            type='text'
                                            value={this.props.userBeingViewed.province_id !== null ? this.props.userBeingViewed.province.province_name : 'İl adı belirtilmemiş'}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='city_name'
                                            label='İlçe Adı'
                                            type='text'
                                            value={this.props.userBeingViewed.town_id !== null ? this.props.userBeingViewed.town.town_name : 'İlçe adı belirtilmemiş'}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='created_at'
                                            label='Hesap oluşturulma zamanı'
                                            type='text'
                                            value={moment(this.props.userBeingViewed.created_at).format('YYYY-MM-DD HH:mm')}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='updated_at'
                                            label='Hesap güncelleme zamanı'
                                            type='text'
                                            value={moment(this.props.userBeingViewed.updated_at).format('YYYY-MM-DD HH:mm')}
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='is_disabled'
                                            label='Hesap engellendi mi?'
                                            type='text'
                                            value={this.props.userBeingViewed.is_disabled ? 'Engellendi (disabled)' : 'Etkin ve kullanılabilir durumda'}
                                            disabled
                                            />
                            </MDBCol>
                            <MDBCol md='6' className='mb-3'>
                                <MDBInput id='disable_reason'
                                            label='Hesap engelleme açıklaması'
                                            type='text'
                                            value={this.props.userBeingViewed.disable_reason === undefined ? this.props.userBeingViewed.disable_reason : 'Hesap engelli değil' }
                                            disabled
                                            />
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <MDBBtn color='primary' className='me-3' onClick={() => this.props.editorCallBack(this.props.userBeingViewed)}>DÜZENLE</MDBBtn>
                        <MDBBtn color='secondary' onClick={this.cancelButtonClickHandler}>KAPAT</MDBBtn>
                    </MDBCardFooter>
                </MDBCard>
                {spinnerWaitComponent}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
    }
};

export default connect(mapStateToProps)(AdminUserViewerComponent);