import { MDBBtn, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardBody, MDBSelect, MDBInput, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent, MDBModalTitle, MDBValidation, MDBRow, MDBCol, MDBCheckbox, MDBFile, MDBValidationItem, MDBTextArea } from 'mdb-react-ui-kit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Resource from '../../../apis/Resource';
import { mainpages } from '../../../common/common';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';

class ResourceDownloadableEditorComponent extends Component {
    constructor(props) {
        super(props);


        this.selected_grade_id = null;

        this.state = {
            isSpinnerWaitShown: false,
            confirmDeleteShown: false,
            selectedFile: null,
            selectedThumbnail: null,
            grade_items: [{ 
                value: '-1',
                text: 'Kademe Seçiniz', 
                defaultSelected: false
             }],
            unit_items: [{ 
                value: '-1',
                text: 'Üniteleri Seçiniz', 
                defaultSelected: false
             }],
            all_resource_available_for: [{ 
                value: '-1',
                text: 'Kimin İçin Seçiniz', 
                defaultSelected: false
             }],
            form_controls: {
                grade_id: { value: '', is_invalid: false, message: '', instruction: '' },
                unit_ids: { value: '', is_invalid: false, message: '', instruction: '' },
                resource_available_for: { value: '', is_invalid: false, message: '', instruction: '' },
                name: { value: '', is_invalid: false, message: '', instruction: '' },
                description: { value: '', is_invalid: false, message: '', instruction: '' },
                published: { value: false, is_invalid: false, message: '', instruction: '' },
                download_name: { value: '', is_invalid: false, message: '', instruction: '' },
                original_file_name: { value: '', is_invalid: false, message: '', instruction: '' }
            },
            submit_success: false,
            submit_error: false
        };

        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            this.state.form_controls.published.value = this.props.resourceBeingEdited.published === 0 ? false : true;
            this.state.form_controls.name.value = this.props.resourceBeingEdited.name;
            this.state.form_controls.description.value = this.props.resourceBeingEdited.description;
            this.state.form_controls.download_name.value = this.props.resourceBeingEdited.download_name;
            this.state.form_controls.original_file_name.value = this.props.resourceBeingEdited.original_file_name;
            this.state.form_controls.resource_available_for.value = this.props.resourceBeingEdited.available_for;
            this.state.form_controls.grade_id.value = this.props.resourceBeingEdited.grade_id;

            let unit_ids = [];
            this.props.resourceBeingEdited.units.forEach(unit => {
                unit_ids.push(unit.id);
            });
            this.state.form_controls.unit_ids.value = unit_ids;
        }

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.onUnitsComboboxSelectHandler = this.onUnitsComboboxSelectHandler.bind(this);
        this.onGradesComboboxSelectHandler = this.onGradesComboboxSelectHandler.bind(this);
        this.onAvailableForComboboxSelectHandler = this.onAvailableForComboboxSelectHandler.bind(this);

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleThumbnailChange = this.handleThumbnailChange.bind(this);

        this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
        this.onDeleteConfirmationButtonClickHandler = this.onDeleteConfirmationButtonClickHandler.bind(this);
    }

    componentDidMount() {
        this.loadGradesFromReduxStore();
        this.loadResourceAvailableForsFromReduxStore();
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        if (key === 'published' && event.target.checked) {
            value = true;
        } else if (key === 'published') {
            value = false;
        }

        let formControlCopy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        formControlCopy.value = value;
        let formControlsCopy = { ...this.state.form_controls};
        formControlsCopy[key] = formControlCopy;
        this.setState({ form_controls: formControlsCopy }); // reference is changed, now all visible parts will be refreshed
        this.setState({ submit_error: false });
        this.setState({ submit_success: false });
    }

    loadGradesFromReduxStore() {
        if (this.state.grade_items.length > 1) {
            return;
        }

        let grade_items = [];

        let option_grade = {
            text: 'SELECT A GRADE',
            value: '-1',
            disabled: true,
            defaultSelected: true
        };

        grade_items.push(option_grade);


        let isResourceSelected = false;

        let grades = this.props.all_grades_with_units;

        grades.forEach(grade => {

            let selected_grade = false;

            if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                selected_grade = grade.id === this.props.resourceBeingEdited.grade_id;
            }   

            if (selected_grade) {
                this.selected_grade_id = grade.id;
                isResourceSelected = true;
            }

            option_grade = {
                text: grade.name,
                value: grade.id + '',
                defaultSelected: selected_grade
            };

            grade_items.push(option_grade);

        });

        if (isResourceSelected) {
            grade_items[0].defaultSelected = false;
        }

        this.setState({ grade_items: grade_items });
        if (isResourceSelected) {
            this.loadUnitsFromReduxStore();
        }
    }

    loadUnitsFromReduxStore() {

        let unit_items = [];

        let checked_unit_ids = [];
        if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
            this.props.resourceBeingEdited.units.forEach(edited_unit => {
                checked_unit_ids.push(edited_unit.id);
            });
        }

        
        let grades = this.props.all_grades_with_units;
        grades.forEach(grade => {

            if (this.selected_grade_id === null) {
                return;
            }

            if (grade.id !== this.selected_grade_id) {
                return;
            }

            let units = grade.units;

            units.forEach(unit => {
                let selected_units = false;
                
                if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                    selected_units = checked_unit_ids.includes(unit.id);
                }                   

                let option = { 
                    text: unit.name + ' - ' + unit.title, 
                    value: unit.id + '',
                    defaultSelected: selected_units
                };

                unit_items.push(option);
            });

        });

        if (unit_items.length === 0) {
            unit_items = [{
                value: '-1',
                text: 'Üniteleri Seçiniz', 
                defaultSelected: false
            }];
        }

        this.setState({ unit_items: unit_items });
    }

    loadResourceAvailableForsFromReduxStore() {
        if (this.state.all_resource_available_for.length > 1) {
            return;
        }

        let all_resource_available_for = [];

        let loaded_resource_for = this.props.all_resource_available_for;
        loaded_resource_for.forEach(available_for => {
            let selected = false;
            if (this.props.resourceBeingEdited !== undefined && this.props.resourceBeingEdited !== null) {
                selected = this.props.resourceBeingEdited.available_for === available_for.id ? true : false;
            }
            let option = { 
                text: available_for.name, 
                value: available_for.id + '',
                defaultSelected: selected
            };

            all_resource_available_for.push(option);
        });

        if (all_resource_available_for.length === 0) {
            all_resource_available_for = [{
                value: '-1',
                text: 'Kimin İçin Seçiniz', 
                defaultSelected: false
            }];
        }

        this.setState({ all_resource_available_for: all_resource_available_for });
    }



    onUnitsComboboxSelectHandler(selectedItems) {
        if (selectedItems === undefined || selectedItems === null) {
            return;
        }

        let form_control = { ...this.state.form_controls.unit_ids };

        let selectedNumericItems = [];
        
        if (selectedItems.length > 0) {
            selectedItems.forEach(item => {
                selectedNumericItems.push(parseInt(item.value));
            });
        }

        form_control.value = selectedNumericItems;
        let form_controls = { ...this.state.form_controls };
        form_controls.unit_ids = form_control;
        this.setState({ form_controls: form_controls });
    }


    onGradesComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined || selectedItem === null) {
            return;
        }

        let form_control = { ...this.state.form_controls.grade_id };

        let selectedItemNumericValue = parseInt(selectedItem.value);
        form_control.value = selectedItemNumericValue;
        let form_controls = { ...this.state.form_controls };
        form_controls.grade_id = form_control;


        this.setState({ form_controls: form_controls });
        this.selected_grade_id = selectedItemNumericValue;
        this.setState({ unit_items: [{
                value: '-1',
                text: 'Üniteleri Seçiniz', 
                defaultSelected: false
            }] });
        this.loadUnitsFromReduxStore();
    }

    onAvailableForComboboxSelectHandler(selectedItem) {
        if (selectedItem === undefined || selectedItem === null) {
            return;
        }

        let form_control = { ...this.state.form_controls.resource_available_for };
        
        let selectedItemNumeric = parseInt(selectedItem.value);

        form_control.value = selectedItemNumeric;
        let form_controls = { ...this.state.form_controls };
        form_controls.resource_available_for = form_control;
        this.setState({ form_controls: form_controls });
    }

    onSubmitHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData();
        

        if (this.state.selectedFile !== null) {
            formData.append('file', this.state.selectedFile);
        }

        if (this.state.selectedThumbnail !== null) {
            formData.append('thumbnail', this.state.selectedThumbnail);
        }
        
        let unitids = this.state.form_controls.unit_ids.value;
        for (let i = 0; i < unitids.length; i++) {
            formData.append('unit_ids[]', unitids[i]); // submit it as an array
        }


        let mainpageitem = mainpages[this.props.menucode];

        formData.append('grade_id', this.state.form_controls.grade_id.value);
        formData.append('type', mainpageitem.type);
        formData.append('subtype', mainpageitem.subtype);
        formData.append('available_for', this.state.form_controls.resource_available_for.value);
        formData.append('name', this.state.form_controls.name.value);
        formData.append('description', this.state.form_controls.description.value);
        formData.append('published', this.state.form_controls.published.value ? 1 : 0);
        formData.append('download_name', this.state.form_controls.download_name.value);
        formData.append('original_file_name', this.state.form_controls.original_file_name.value);


        for (var key of formData.entries()) {
            console.log(key[0] + ", " + key[1]);
        }



        this.postResource(formData);
        
    }

    async postResource(form_items) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            if (this.props.resourceBeingEdited !== null) {
                form_items.append('id', this.props.resourceBeingEdited.id);
                await Resource.editResource(form_items);
                this.props.showToastNotification('success', 'İndirme dosyası kaydedildi');
            } else {
                await Resource.postResource(form_items);
                this.props.showToastNotification('success', 'Yeni indirme dosyası oluşturuldu');
            }
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();

        } catch (error) {
            this.props.showToastNotification('error', 'Yeni indirme dosyası oluşturulamadı');
            this.setState({ isSpinnerWaitShown: false });
        }
       
        
    }

    cancelButtonClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.closeCallback();
    }

    async deleteResource() {
        if (this.props.resourceBeingEdited === null) {
            return;
        }

        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = { id: this.props.resourceBeingEdited.id };
            await Resource.deleteResource(request);
            this.props.showToastNotification('success', 'Kaynak silindi');
            this.setState({ confirmDeleteShown: false });
            this.setState({ isSpinnerWaitShown: false });
            this.props.closeCallback();
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }
        
    }


    onDeleteConfirmationButtonClickHandler() {
        this.deleteResource();
    }

    toggleDeleteConfirmationModal() {
        this.setState({ confirmDeleteShown: !this.state.confirmDeleteShown });
    }

    handleFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    handleThumbnailChange(event) {
        this.setState({ selectedThumbnail: event.target.files[0] });
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }


        let unitnamesstr = '';
        let resource_name = '';
        let grade_name = '';
        if (this.state.confirmDeleteShown) {
            let units = this.props.resourceBeingEdited.units;
            let unitnamesarr = [];
            units.forEach(unit => {
                unitnamesarr.push(`${unit.name} - ${unit.title}`);
            });
            unitnamesstr = unitnamesarr.join(', ');
            resource_name = this.props.resourceBeingEdited.name;
            grade_name =this.props.resourceBeingEdited.grade.name;
        }

        let confirmDeleteComponent = (
        <MDBModal size='md' open={this.state.confirmDeleteShown}>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>İndirme Dosyası Silme Onayı</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteConfirmationModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p><strong>Silinecek kaynak adı:</strong> {resource_name}</p>
                        <p><strong>Kademesi:</strong> {grade_name}</p>
                        <p><strong>Üniteleri:</strong> {unitnamesstr}</p>
                        <p>Silme işlemini onaylıyor musunuz?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={this.onDeleteConfirmationButtonClickHandler}>SİL</MDBBtn>
                        <MDBBtn color="secondary" onClick={this.toggleDeleteConfirmationModal}>İPTAL</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
        );

        let title = '';
        let mainpageitem = mainpages[this.props.menucode];
        if (this.props.resourceBeingEdited === null) {
            title = 'Yeni ' + mainpageitem.title + ' İndirme Dosyası Oluştur';
        } else {
            title = mainpageitem.title + ' İndirme Dosyası Düzenle';
        }


        return (
            <>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>{title}</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation onSubmit={event => this.onSubmitHandler(event)} noValidate>
                        <MDBCardBody className='p-4'>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.grade_id.message} invalid>
                                        <MDBSelect
                                            id='grade_id'
                                            label='Sınıf'
                                            search
                                            searchLabel='Kademe ara'
                                            onChange={this.onGradesComboboxSelectHandler}
                                            data={this.state.grade_items}
                                            className={this.state.form_controls.grade_id.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.unit_ids.message} invalid>
                                        <MDBSelect
                                            id='unit_ids'
                                            label='Üniteler'
                                            search
                                            searchLabel='Ünite ara'
                                            multiple
                                            onChange={this.onUnitsComboboxSelectHandler}
                                            data={this.state.unit_items}
                                            className={this.state.form_controls.unit_ids.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.resource_available_for.message} invalid>
                                        <MDBSelect
                                            id='resource_available_for'
                                            label='Kimin İçin'
                                            onChange={this.onAvailableForComboboxSelectHandler}
                                            data={this.state.all_resource_available_for}
                                            className={this.state.form_controls.resource_available_for.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3 d-flex align-items-center'>
                                    <MDBValidationItem feedback={this.state.form_controls.published.message} invalid>
                                        <MDBCheckbox
                                            id='published'
                                            label='Yayına alınsın'
                                            onChange={this.formItemOnChangeHandler}
                                            checked={this.state.form_controls.published.value}
                                            className={this.state.form_controls.published.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.name.message} invalid>
                                        <MDBInput
                                            type='text'
                                            id='name'
                                            label='Kaynak adı'
                                            value={this.state.form_controls.name.value}
                                            autoComplete='off'
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.name.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.description.message} invalid>
                                        <MDBTextArea
                                            rows={3}
                                            id='description'
                                            label='Açıklama'
                                            value={this.state.form_controls.description.value}
                                            autoComplete='off'
                                            onChange={this.formItemOnChangeHandler}
                                            className={this.state.form_controls.description.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            { this.props.resourceBeingEdited !== null &&
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.download_name.message} invalid>
                                        <MDBInput
                                            id='download_name'
                                            label='Dosya adı'
                                            type='text'
                                            onChange={this.formItemOnChangeHandler}
                                            value={this.state.form_controls.download_name.value}
                                            className={this.state.form_controls.download_name.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBValidationItem feedback={this.state.form_controls.original_file_name.message} invalid>
                                        <MDBInput
                                            id='original_file_name'
                                            type='text'
                                            label='Sunucudaki Dosya Adı'
                                            onChange={this.formItemOnChangeHandler}
                                            value={this.state.form_controls.original_file_name.value}
                                            className={this.state.form_controls.original_file_name.is_invalid ? 'is-invalid':''}
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                            </MDBRow>
                            }
                            <MDBRow>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBFile 
                                        id='file'
                                        name='file'
                                        label='Dosyanızı yükleyin'
                                        onChange={this.handleFileChange}
                                    />
                                </MDBCol>
                                <MDBCol md='6' className='mb-3'>
                                    <MDBFile 
                                        id='thumbnail'
                                        name='thumbnail'
                                        label='Ekran görüntüsü yükleyin'
                                        onChange={this.handleThumbnailChange}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn type='submit' color='primary' className='me-2'>KAYDET</MDBBtn>
                            {this.props.resourceBeingEdited !== null ? <MDBBtn color='danger' className='me-2' onClick={this.toggleDeleteConfirmationModal}>SİL</MDBBtn> : ''}
                            <MDBBtn color='secondary' className='me-2' onClick={this.cancelButtonClickHandler}>İPTAL</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                    {confirmDeleteComponent}
                </MDBCard>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback,
        all_grades_with_units: state.all_grades_with_units,
        all_resource_available_for: state.all_resource_available_for
    }
};

export default connect(mapStateToProps)(ResourceDownloadableEditorComponent);
