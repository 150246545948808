import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBSelect, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBCol, MDBRow, MDBValidation } from 'mdb-react-ui-kit';
import Grade from '../../../apis/Grade';
import User from '../../../apis/User';
import SpinnerWaitComponent from '../../SpinnerWaitComponent';





class TeacherGradesEditorComponent extends Component {
    constructor(props) {
        super(props);

        let grade_id = null;
        this.user_grades = []; 

        this.state = {
            isSpinnerWaitShown: false,
            form_controls: {
                grade_id: { value: grade_id, is_invalid: false, message: '', instruction: '' }
            },
            grades: [{ 
                value: '-1',
                text: 'Yükleniyor', 
                defaultSelected: true
            }]
        };

        this.closeWindowByCancel = this.closeWindowByCancel.bind(this);
        this.onSaveButtonClickHandler = this.onSaveButtonClickHandler.bind(this);

        this.onGradeComboboxSelectHandler = this.onGradeComboboxSelectHandler.bind(this);

    }

    componentDidMount() {
        this.fetchGradesOfUser();
    }

    async fetchGradesOfUser() {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let response = await Grade.fetchAttachedGradesForUser();
            let userGrades = [];
            response.data.forEach(item => {
                userGrades.push(item.id);
            });

            this.user_grades = userGrades;

            let form_control = { ...this.state.form_controls.grade_id };
            form_control.value = userGrades;
            let form_controls = { ...this.state.form_controls };
            form_controls.grade_id = form_control;
            this.setState({ form_controls: form_controls });
            await this.fetchAllGrades();
        } catch (error) {

        }
        this.setState({ isSpinnerWaitShown: false });
    }

    async fetchAllGrades() {
        
        if (this.state.grades.length > 1) {
            return;
        }

        try {
            this.setState({ isSpinnerWaitShown: true });
            let response = await Grade.fetchAll();
            this.setState({ isSpinnerWaitShown: false });
            let grades = [];
            response.data.forEach(item => {
                let strId = item.id + '';
                let selected = this.user_grades.includes(item.id) ? true : false;
                let option = { text: item.name, 
                               value: strId,
                               deafaultSelected: selected
                               };
                grades.push(option);
            });
            this.setState( { grades: grades } );
        } catch(error) {
            this.setState({ isSpinnerWaitShown: false });
        }

        
        
    }

    onGradeComboboxSelectHandler(selectedItems) {
        let form_control = { ...this.state.form_controls.grade_id };
        let selectedNumericItems = [];
        if (selectedItems.length > 0) {
            selectedItems.forEach(item => {
                selectedNumericItems.push(parseInt(item.value));
            });
        }

        form_control.value = selectedNumericItems;
        let form_controls = { ...this.state.form_controls };
        form_controls.grade_id = form_control;
        this.setState({ form_controls: form_controls });
    }

    onSaveButtonClickHandler() {
        
        this.attachGrades(this.state.form_controls.grade_id.value);

    }

    async attachGrades(grade_ids) {
        try {
            let request = {
                grade_ids: grade_ids
            };
            this.setState({ isSpinnerWaitShown: true });
            await Grade.attachGradesToUser(request);
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('success', 'Sınıf kademeleriniz kaydedilmiştir');
            let response = await User.getauthenticateduser();
            this.props.storeAuthenticatedUser(response.data.user);
            this.props.closeCallback(false); // close by cancel
        } catch (error) {
            this.setState({ isSpinnerWaitShown: false });
            this.props.showToastNotification('error', 'Sınıf kademeleriniz kaydedilirken bir hata oluştu');
        }

        
    }

    closeWindowByCancel() {
        this.props.closeCallback(true); // close by cancel
    }

    render() {
        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        return (
            <div>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBCardTitle>Sınıf Kademelerini Düzenle</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBValidation noValidate>
                        <MDBCardBody className='p-4'>
                            <MDBRow>
                                <MDBCol md='12' className='mb-3'>
                                    <p>Sadece dersine girdiğiniz sınıf kademelerini seçmeniz durumunda daha sade ve kolay bir TEAM elt publishing LMS kullanımı deneyimleyeceksiniz.</p>
                                    <p>Tüm sınıf kademelerini seçmeniz durumunda TEAM elt publishing'in tüm sınıf kademelerine yönelik sayısal içeriğe ulaşmış olacaksınız.</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBSelect 
                                        multiple
                                        id='grade_id'
                                        label='Sınıf kademeleri' 
                                        onChange={this.onGradeComboboxSelectHandler}
                                        data={this.state.grades}
                                        validation={this.state.form_controls.grade_id.message}
                                        invalid={this.state.form_controls.grade_id.is_invalid.toString()}
                                        className={this.state.form_controls.grade_id.is_invalid ? 'is-invalid':''}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <MDBBtn color='primary' className='me-2' onClick={this.onSaveButtonClickHandler}>KAYDET</MDBBtn>
                            <MDBBtn color='secondary' className='me-2' onClick={this.closeWindowByCancel}>KAPAT</MDBBtn>
                        </MDBCardFooter>
                    </MDBValidation>
                    {spinnerWaitComponent}
                </MDBCard>
            </div>
        );
    }
}

/**
<MDBSelect multiple id='grade_id'
                                        label='Sınıf kademeleri' 
                                        onChange={this.onGradeComboboxSelectHandler}
                                        data={this.state.grades}
                                        validation={this.state.form_controls.grade_id.message}
                                        invalid={this.state.form_controls.grade_id.is_invalid}
                                        className={this.state.form_controls.grade_id.is_invalid ? 'is-invalid':''}
                                    />
*/

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        showToastNotification: state.showToastNotificationCallback
    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeAuthenticatedUser: (authenticatedUser) => dispatch({ type: 'storeAuthenticatedUser', authenticatedUser: authenticatedUser })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherGradesEditorComponent);
