import Api from './Api';
import Csrf from './Csrf';


const QuizMaker = {
    async fetchAllQuizzesOfUser() {
        await Csrf.getCookie();
        return Api.get('/quizmaker/all');
    },

    async fetchQuizNewQuestions(form_items) {
        await Csrf.getCookie();
        return Api.post('/quizmaker/get_new_questions', form_items);
    },

    async changeAQuestion(form_items) {
        await Csrf.getCookie();
        return Api.post('/quizmaker/change_a_question', form_items);
    },

    async saveQuiz(form_items) {
        await Csrf.getCookie();
        return Api.post('/quizmaker/save_quiz', form_items);
    },

    async fetchQuiz(form_items) {
        await Csrf.getCookie();
        return Api.post('/quizmaker/get_quiz', form_items);
    },

    async downloadQuiz(form_items) {
        await Csrf.getCookie();
        return Api.post('/quizmaker/download', form_items);
    },
};

export default QuizMaker;