import { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBIcon,
    MDBSideNav,
    MDBSideNavCollapse,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavMenu,
    MDBTypography
} from 'mdb-react-ui-kit';

import User from '../apis/User';
import { mainpages, USER_IS_ADMIN, USER_IS_STUDENT, USER_IS_TEACHER } from '../common/common';
import SideBarAdminItemsComponent from './SideBarAdminItemsComponent';
import SideBarTeacherItemsComponent from './SideBarTeacherItemsComponent';
import SideBarStudentItemsComponent from './SideBarStudentItemsComponent';
import {ReactComponent as ProductLogoMonochromeComponent} from '../images/teamlms_logo_monochrome.svg';
import team_logo from '../images/team_logo.svg'

class SideBarComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            is_usermenu_collapsed: false
        };


        this.onToggleUserMenuNavItemClicked = this.onToggleUserMenuNavItemClicked.bind(this);
    }


    onToggleUserMenuNavItemClicked() {
        this.setState({ is_usermenu_collapsed: !this.state.is_usermenu_collapsed });
    }

    logoutHandler() {
        if (this.props.isSmallScreen) {
            this.props.setSidenavOpen(false);
        }
        this.logout();
    }

    async logout() {
        try {
            await User.logout();
        } catch (error) {
            // do nothing
        }
        this.props.logout(); // logout even if exception occurs.
    }

    changeMainPageToChangePasswordHandler() {
        if (this.props.isSmallScreen) {
            this.props.setSidenavOpen(false);
        }
        this.props.setMainPageCode(mainpages.changepassword.code);
    }

    changeMainPageToEditProfileHandler() {
        if (this.props.isSmallScreen) {
            this.props.setSidenavOpen(false);
        }
        this.props.setMainPageCode(mainpages.editprofile.code);
    }

    render() {

        let role_name = 'Öğrenci';
        let menuItemsComponent = '';

        if (this.props.authenticatedUser.role_id === USER_IS_ADMIN) {
            menuItemsComponent = <SideBarAdminItemsComponent setSidenavOpen={this.props.setSidenavOpen} isSmallScreen={this.props.isSmallScreen}  />;
            role_name = 'Yönetici';
        } else if (this.props.authenticatedUser.role_id === USER_IS_TEACHER) {
            menuItemsComponent = <SideBarTeacherItemsComponent setSidenavOpen={this.props.setSidenavOpen} isSmallScreen={this.props.isSmallScreen} />;
            role_name = 'Öğretmen';
        } else if (this.props.authenticatedUser.role_id === USER_IS_STUDENT) {
            menuItemsComponent = <SideBarStudentItemsComponent setSidenavOpen={this.props.setSidenavOpen} isSmallScreen={this.props.isSmallScreen}  />;
            role_name = 'Öğrenci';
        }

        return (
            <>
                <MDBSideNav
                    mode={this.props.isSmallScreen ? 'over' : 'side'}
                    open={this.props.isSidenavOpened}
                    contentRef={this.props.container}
                    constant={this.props.isSmallScreen ? false : true }
                    backdrop={this.props.isSmallScreen ? true : false }
                    getOpenState={(e) => this.props.setSidenavOpen(e)}
                    light={false}
                    bgColor='dark'
                    color='light'
                >
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div className='p-3 d-flex align-items-center justify-content-center'>
                                <ProductLogoMonochromeComponent fill='#bcbeb0' style={{width: '50px'}}/>
                            </div>
                            <div className='px-3 d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}}
                                onClick={() => this.props.setSidenavOpen(!this.props.isSidenavOpened)}>
                                    <MDBIcon fas icon='times' size='2x' />
                            </div>
                        </div>
                        <div className='px-5 d-flex justify-content-center'  >
                            <MDBIcon far icon='user-circle' size='3x' />
                        </div>
                        <div className='px-4 d-flex justify-content-center text-truncate'>
                            <span className='text-truncate'>
                            {this.props.authenticatedUser.name} {this.props.authenticatedUser.surname}
                            </span>
                        </div>
                        <div className='d-flex justify-content-center'  >
                            <MDBTypography tag='em'><small>{role_name}</small></MDBTypography>
                        </div>
                        <hr />
                    </div>
                    {menuItemsComponent}
                    { this.props.isSmallScreen &&
                    <div>
                        <hr />
                        <MDBSideNavMenu>
                            <MDBSideNavItem>
                                <MDBSideNavLink icon='angle-down' shouldBeExpanded={this.state.is_usermenu_collapsed} onClick={this.onToggleUserMenuNavItemClicked}>
                                    <MDBIcon far icon='user-circle' className='me-3' /> <span className='text-truncate'>{this.props.authenticatedUser.name} {this.props.authenticatedUser.surname}</span>
                                </MDBSideNavLink>
                                <MDBSideNavCollapse open={this.state.is_usermenu_collapsed}>
                                    <MDBSideNavLink onClick={this.changeMainPageToEditProfileHandler.bind(this)}>
                                        Profili Düzenle
                                    </MDBSideNavLink>
                                    <MDBSideNavLink onClick={this.changeMainPageToChangePasswordHandler.bind(this)}>
                                        Şifre Değiştir
                                    </MDBSideNavLink>
                                    <MDBSideNavLink onClick={this.logoutHandler.bind(this)}>
                                        Oturumu Kapat
                                    </MDBSideNavLink>
                                </MDBSideNavCollapse>
                            </MDBSideNavItem>
                        </MDBSideNavMenu>
                    </div>
                    }
                    <div className='text-center' style={{ minHeight: '3rem' }}>
                        <hr className='mt-3 mb-2' />
                        <div className='px-5'>
                            <img src={team_logo} alt='TEAM ELT PUBLISHING Logo' className='mx-4'/>
                        </div>
                    </div>
                </MDBSideNav>
            </>
        );

    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch({ type: 'logout' }),
        setMainPageCode: (mainPageCode) => dispatch({ type: 'setMainPageCode', mainPageCode})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarComponent);