import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTooltip } from 'mdb-react-ui-kit';
import { Component } from 'react';
import { connect } from 'react-redux';
import VirtualClass from '../../apis/VirtualClass';
import { cloneConfigForFormControl } from '../../common/common';
import SpinnerWaitComponent from '../SpinnerWaitComponent';

class AttendToAClassComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSpinnerWaitShown: false,
            confirmJoinShown: false,
            attendingCandidateVirtualClass: null,
            form_controls: {
                            shared_code: { value: '', is_invalid: false, message: '', instruction: ''},
                           },
            rows: []
        };

        this.columns = [
            {
                label: 'Sınıf Adı',
                field: 'name',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Öğretmen',
                field: 'teacher',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Durum',
                field: 'status',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Eylemler',
                field: 'action_buttons',
                sort: 'asc',
                width: 200
            }
        ];

        this.formItemOnChangeHandler = this.formItemOnChangeHandler.bind(this);
        this.toggleJoinConfirmationModal = this.toggleJoinConfirmationModal.bind(this);
        this.onRequestJoinButtonClickHandler = this.onRequestJoinButtonClickHandler.bind(this);
        this.onRequestJoinConfirmationButtonClickHandler = this.onRequestJoinConfirmationButtonClickHandler.bind(this);
    }

    componentDidMount() {
        this.loadAttendedClasses();
    }

    formItemOnChangeHandler(event) {
        let key = event.target.id;
        let value = event.target.value;

        let formControlCopy = { ...this.state.form_controls[key] }; // it is important to copy contents intead of using reference
        
        formControlCopy.value = value;
        let formControlsCopy = { ...this.state.form_controls};
        formControlsCopy[key] = formControlCopy;
        this.setState({ form_controls: formControlsCopy }); // reference is changed, now all visible parts will be refreshed
    }


    onRequestJoinButtonClickHandler() {
        this.fetchClassInformationBySharedCode(this.state.form_controls.shared_code.value);
    }

    async fetchClassInformationBySharedCode(shared_code) {
        this.setState({ isSpinnerWaitShown: true });
        try {
            let request = { shared_code: shared_code };
            let response = await VirtualClass.fetchClassInformationBySharedCode(request);
            let virtualClass = response.data.virtualClass;

            let form_controls_copy = { ...this.state.form_controls };
            form_controls_copy['shared_code'] = cloneConfigForFormControl('shared_code', [], form_controls_copy);
            this.setState({ form_controls: form_controls_copy });

            this.setState({ attendingCandidateVirtualClass: virtualClass });
            this.setState({ confirmJoinShown: true });

        } catch (error) {
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.errors !== undefined)
            {
                let errors = error.response.data.errors;
                let form_controls_copy = { ...this.state.form_controls }; // do not mutate state directly
                form_controls_copy['shared_code'] = cloneConfigForFormControl('shared_code', errors, form_controls_copy);
                this.setState({ form_controls: form_controls_copy });
                
                if (errors.error !== undefined) { // handle global algoritmical errors
                    this.props.showToastNotification('error', errors.error);
                }
            } else {
                // handle catastrophic errors
                this.props.showToastNotification('error', 'Bilinmeyen hata oluştu');
            }

        }

        this.setState({ isSpinnerWaitShown: false });
    }

    toggleJoinConfirmationModal() {
        this.setState({ confirmJoinShown: !this.state.confirmJoinShown });
    }

    onRequestJoinConfirmationButtonClickHandler() {
        this.requestJoinToClassWithSharedCode();
    }

    async requestJoinToClassWithSharedCode() {
        try {
            let request = {
                shared_code: this.state.attendingCandidateVirtualClass.shared_code,
                attendee_id: this.props.authenticatedUser.id
            };
            await VirtualClass.requestJoinToClassWithSharedCode(request);
            this.props.showToastNotification('success', request.shared_code + ' kodlu sınıfa katılım isteğiniz öğretmen onayına gönderildi');
            this.setState({ attendingCandidateVirtualClass: null });
            this.setState({ confirmJoinShown: false }); // close the modal dialog

            // clear shared code field

            this.loadAttendedClasses();
        } catch (error) {
            this.props.showToastNotification('error', 'Sınıf katılım istedği gönderilirken hata oluştu');
        }
    }

    async loadAttendedClasses() {
        this.setState({ isSpinnerWaitShown: true });

        try {
            let response = await VirtualClass.fetchVirtualClassesByAttendee();
            let rows = [];
            let virtualClasses = response.data.virtualClasses;

            virtualClasses.forEach((item, index) => {
                let name_surname = item.owner.name + ' ' + item.owner.surname;
                let newItem = {
                    name: item.name,
                    teacher: name_surname,
                    status: item.pivot.approved === 1 ? 'Katıldınız' : 'Öğretmen Onayında',
                    action_buttons: (<div key={index}>
                                        <MDBTooltip placement="top">
                                            <MDBBtn rounded outline color='primary' size='sm' className='px-2 waves-effect' ><i className='fas fa-eye mt-0'></i><span className='d-none d-lg-inline ms-1'>İNCELE</span></MDBBtn>
                                            <div>Sınıfı incele</div>
                                        </MDBTooltip>
                                        <MDBTooltip placement="top">
                                            <MDBBtn rounded outline color='danger' size='sm' className='px-2 waves-effect' ><i className='fas fa-sign-out-alt mt-0'></i><span className='d-none d-lg-inline ms-1'>AYRIL</span></MDBBtn>
                                            <div>Sınıftan ayrıl</div>
                                        </MDBTooltip>
                                    </div>)
                };

                rows.push(newItem);
            });

            this.setState({ rows: rows });


        } catch (error) {
            this.props.showToastNotification('error', 'Sınıflarınız yüklenirken hata oluştu');
        }


        this.setState({ isSpinnerWaitShown: false });
    }

    render() {


        let spinnerWaitComponent = '';
        if (this.state.isSpinnerWaitShown) {
            spinnerWaitComponent = (<SpinnerWaitComponent />);
        }

        let confirmJoinComponent = '';
        if (this.state.confirmJoinShown && this.state.attendingCandidateVirtualClass !== null) {
            let virtualClass = this.state.attendingCandidateVirtualClass;
            let owner = virtualClass.owner;
            confirmJoinComponent = (<MDBModal centered size='md' isOpen={this.state.confirmJoinShown} toggle={this.toggleJoinConfirmationModal}>
                <MDBModalHeader toggle={this.toggleJoinConfirmationModal}>Sınıfa Katılım İsteği Gönder</MDBModalHeader>
                <MDBModalBody>
                    <p><strong>Sınıf Adı:</strong> {virtualClass.name}</p>
                    <p><strong>Sınıf Kodu:</strong> {virtualClass.shared_code}</p>
                    <p><strong>Öğretmen:</strong> {owner.name} {owner.surname}</p>
                    <p>Sınıfa katılım isteğiniz öğretmen tarafından değerlendirilecek ve kabul ettiğinde sınıfa katılabileceksiniz.</p>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="primary" onClick={this.onRequestJoinConfirmationButtonClickHandler}>SINIFA KATILIM İSTEĞİ GÖNDER</MDBBtn>
                    <MDBBtn color="secondary" onClick={this.toggleJoinConfirmationModal}>İPTAL</MDBBtn>
                </MDBModalFooter>
            </MDBModal>);
        }



        return (
            <div>
                <h3>Sanal Sınıflarım</h3>
                <p>Sanal sınıflara katılabilirsiniz. Sınıfa katılabilmek için ihtiyacınız olan <strong>sınıf kodu</strong> öğretmeniniz tarafından sağlanacak. Sınıf kodunu öğretmeninizden talep edebilirsiniz.</p>
                <form>
                    <MDBRow>
                        <MDBCol className='form-outline col-auto d-flex align-items-center'>
                            <MDBInput
                                type='text'
                                className={`form-control ${this.state.form_controls.shared_code.is_invalid ? 'is-invalid invalid' : ''}`}
                                id='shared_code'
                                label='Sınıf Kodu'
                                value={this.state.form_controls.shared_code.value}
                                autoComplete='off'
                                onChange={this.formItemOnChangeHandler}
                            >
                            <div className='invalid-feedback'>{this.state.form_controls.shared_code.message}</div>
                            </MDBInput>
                        </MDBCol>
                        <MDBCol className='form-outline col-auto d-flex align-items-center'> 
                            <MDBBtn color='primary' onClick={this.onRequestJoinButtonClickHandler}>SINIFA KATILIM İSTEĞİ GÖNDER</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </form>
                {spinnerWaitComponent}
                <MDBTable btn mall>
                    <MDBTableHead columns={this.columns} />
                    <MDBTableBody rows={this.state.rows} />
                </MDBTable>
                {confirmJoinComponent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.authenticatedUser,
        mainPageTitle: state.mainPageTitle,
        showToastNotification: state.showToastNotificationCallback
    }
};

export default connect(mapStateToProps)(AttendToAClassComponent);