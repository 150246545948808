import Api from './Api';
import Csrf from './Csrf';


const Resource = {
    async fetchAllTypes() {
        await Csrf.getCookie();
        return Api.get('/resources/all_types');
    },

    async fetchAllTargets() {
        await Csrf.getCookie();
        return Api.get('/resources/all_targets');
    },

    async fetchAllResourcesFor() {
        await Csrf.getCookie();
        return Api.get('/resources/all_available_for');
    },

    async fetchAllResources(form_items) {
        await Csrf.getCookie();
        return Api.post('/resources', form_items);
    },

    async fetchAllDownloadResources(form_items) {
        await Csrf.getCookie();
        return Api.post('/resources_for_teachers', form_items);
    },

    async postResource(form_items) {
        await Csrf.getCookie();
        return Api.post('/resources/store', form_items, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },

    async editResource(form_items) {
        await Csrf.getCookie();
        return Api.post('/resources/edit', form_items, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },

    async deleteResource(form_items) {
        await Csrf.getCookie();
        return Api.post('/resources/delete', form_items);
    }

};

export default Resource;